import React from 'react'
import {
  Modal,
  message,
  Button
} from 'antd'
import PurchasedPrds from './PurchasedPrds'
import {
  gql,
  // useApolloClient,
  useLazyQuery,
  useMutation
} from '@apollo/client'
// import produce from "immer"

const LOAD_PURCHASE = gql`
  query loadPC($shop: String!, $purchase: String!) {
    loadSinglePC(shop: $shop, purchase: $purchase) {
      _id
      quantity
      buyPrice
      sellPrice
      name
      unit
      code
      total
      product
    }
  }
`
const UPDATE_PC =gql`
  mutation updatePC($shop: String!, $data: PCProduct $purchase: String!) {
    updatePC(shop: $shop, data: $data, purchase: $purchase) {
      _id
      quantity
      buyPrice
      sellPrice
      name
      unit
      code
      total
      product
    }
  }
`
const REMOVE_PRD_IN_PC = gql`
  mutation removePrdInPc($shop: String!, $purchase: String!, $product: String!) {
    removePrdInPc (shop: $shop, purchase: $purchase, product: $product) {
      _id
      quantity
      buyPrice
      sellPrice
      name
      unit
      code
      total
      product
    }
  }
`

const EditPCModal = ({
  shop,
  purchase,
  visible,
  onOk,
  onCancel,
}) => {
  // const client = useApolloClient()
  const [loadPurchase, { loading, data , error }] = useLazyQuery(LOAD_PURCHASE, { fetchPolicy: 'network-only' })
  const [updatePC] = useMutation(UPDATE_PC)
  const [removeSinglePrd, { loading: removing }] = useMutation(REMOVE_PRD_IN_PC)
  const [currentRemoving, setCurrentRemoving] = React.useState(null)
  const [currentSaving, setCurrentSaving] = React.useState(null)

  React.useEffect(() => {
    const load = async () => {
      loadPurchase({
        variables: {
          shop,
          purchase
        }
      })
    }
    if (purchase) {
      load()
      // setCart(data.loadSinglePC)
    }
  }, [purchase, shop, loadPurchase])
  
  const addToPC = async (data) => {
    // console.log(data)
    setCurrentSaving(data.product)
    if (!data.product || !data.quantity) {
      message.error('Thiếu thông tin sản phẩm hoặc số lượng')
      return
    }
    const {
      quantity,
      product,
      buyPrice
    } = data
    const updateData = {
      quantity,
      product,
      buyPrice: parseFloat(buyPrice)
    }
    await updatePC({
      variables: {
        shop,
        data: updateData,
        purchase
      },
      update: (cache, { data: { loadSinglePC }}) => {
        cache.modify({
          fields: {
            loadSinglePC(existing = []) {
              // console.log(existing)
              cache.writeQuery({
                query: LOAD_PURCHASE,
                data: loadSinglePC
              })
            }
          }
        })
        // form.resetFields()
      }
    })
    // await loadPurchase({
    //   variables: {
    //     shop,
    //     purchase
    //   }
    // })
    setCurrentSaving(null)
  }

  const remove = async (product) => {
    setCurrentRemoving(product)
    if (!product) {
      message.error('Không thể xóa sản phẩm')
      return
    }
    await removeSinglePrd({
      variables: {
        shop,
        purchase,
        product
      },
      update: (cache, { data: { removePrdInPc }}) => {
        cache.modify({
          fields: {
            loadSinglePC(existing = []) {
              // console.log(existing)
              cache.writeQuery({
                query: LOAD_PURCHASE,
                data: removePrdInPc
              })
            }
          }
        })
      }
    })
    // await loadPurchase({
    //     variables: {
    //       shop,
    //       purchase
    //     }
    //   })
    setCurrentRemoving(null)
  }
  return (
    <Modal
      width={1400}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title={`Sửa phiếu nhập`}
      footer={[
        <Button key="_cls" type="primary" onClick={() => onCancel()}>Đóng</Button>
      ]}
    >
      {(loading || removing) && (<p style={{ margin: '1em', textAlign: 'center'}}> LOADING.... </p>)}
      {!loading && error && (<span>Error</span>)}
      {!loading && data && (
        <PurchasedPrds
          currentRemoving={currentRemoving}
          currentSaving={currentSaving}
          remove={remove}
          data={data.loadSinglePC}
          shop={shop}
          addPrd={addToPC}
        />
      )}
    </Modal>
  )
}

export default EditPCModal
