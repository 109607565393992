import React from 'react';
import {
  Modal,
  Form,
  Input,
  // Button,
} from 'antd';
/* const CREATE_AGENT_MUTATION = gql`
  mutation createA($name: String!, $email: String!, $password: String!) {
    createAgent(name: $name, email: $email, password: $password) {
      name
      email
      role
    }
  }
`; */

const formLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}

const NewSaleModal = ({
  visible,
  onOk,
  onCancel,
  // shop
  creating
}) => {
  const [form] = Form.useForm();
  // const [createAgent, { loading: creating } ] = useMutation(CREATE_AGENT_MUTATION);

  const createSaleAgent = async () => {
    const data = form.getFieldsValue();
    // console.log(data);
    await onOk(data)
    // // onOk();
    // const { name, email, password } = data;
    // await createAgent({ variables: {
    //   name,
    //   email,
    //   password
    // }})
    // .then(res => {
    //   console.log(res);
    //   onOk()
    // })
    // .catch(e => console.log(e));
  }
  return (
    <Modal
      title={`Thêm nhân viên  mới`}
      visible={visible}
      onOk={createSaleAgent}
      onCancel={onCancel}
      okButtonProps={{ loading: creating }}
    >
      <Form form={form} {...formLayout}>
        <Form.Item label="Tên" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="email/SDT" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Mật khẩu" name="password">
          <Input.Password />
        </Form.Item>
        <Form.Item name="password_confirmation" label="Nhập lại mật khẩu">
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewSaleModal;
