import React from 'react'
import {
  Button,
  Card,
  Input,
  // Tag,
  Form,
  List
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const CategoriesSettings = ({
  categories,
  createCategory
}) => {
  const [form] = Form.useForm()
  const [adding, toggleAdding] = React.useState(false)
  // const [removing, toggleRemoving] = React.useState(false)

  const addCat = async () => {
    toggleAdding(true)
    const { category } = form.getFieldsValue()
    if (category) {
      await createCategory(category)
    }
    form.resetFields()
    toggleAdding(false)
    return
  }

  return (
    <Card title="Danh Mục" bordered>
      <div>
        <Form form={form}>
          <Form.Item name="category" label="Thêm danh mục">
            <Input disabled={adding} onPressEnter={() => addCat()} suffix={<Button size="small" icon={<PlusOutlined onClick={() => addCat()} />} type="primary" loading={adding} />} />
          </Form.Item>
        </Form>
        <List
          header={<strong>Danh mục đã có</strong>}
        >
          {categories && categories.map(c => (
            // <Tag closable onClose={() => removeUnit(u)}>{u}</Tag>
            <List.Item
              key={c._id}
            >
              {c.name}
            </List.Item>
          ))}
        </List>
      </div>
    </Card>
  )
}

export default CategoriesSettings
