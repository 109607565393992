import React from 'react';
// import {
//   Menu,
//   Button,
// } from 'antd';
// import {
//   PlusOutlined
// } from '@ant-design/icons';
// import AddPrdModal from './AddPrdModal';
import {
  gql,
  // useMutation,
} from '@apollo/client';
import ProductsList from './ProductsList'

const Products = ({
  shop,
  products
}) => {
  return (
      <div>
        <ProductsList
          shop={shop}
          />
      </div>
    );
}

export default Products;

export const GET_BATCHES = gql`
  query getShopBatches($shop: String!, $product: String!) {
    getBatches(shop: $shop, product: $product) {
      _id
      product {
        name
        _id
      }
      quantity
      buyPrice
      shop {
        _id
        name
      }
      note
      created
      changed
    }
  }
`