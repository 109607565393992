import React from 'react';
import {
  // Breadcrumb,
  Menu,
  // Modal,
  Typography,
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import {
  useQuery,
  gql
} from '@apollo/client';
import AddCatModal from './AddCatModal';
import CategoriesList from './CategoriesList';

const { Title } = Typography;

export const GET_CAT_QUERY = gql`
  query getCat{
    getCategories {
      _id
      name
      element
    }
  }
`;

const Categories = () => {
  const [catModal, toggleCatModal] = React.useState(false);
  const {loading, data } = useQuery(GET_CAT_QUERY);
  return (
    <div>
      <Title level={1}>Danh mục</Title>
      <Menu mode="horizontal" style={{ textAlign: 'right'}}>
        <Menu.Item icon={<PlusOutlined />} onClick={() => toggleCatModal(true)}>
          Thêm danh mục mới
        </Menu.Item>
      </Menu>
      {loading && (
        <div>
          ....Loading....
        </div>
      )}
      {!loading && data && (
        <CategoriesList cats={data.getCategories} />
      )}
      <AddCatModal
        visible={catModal}
        onOk={() => toggleCatModal(false)}
        onCancel={() => toggleCatModal(false)}
      />
    </div>
  );
}

export default Categories;
