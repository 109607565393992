import React from 'react';
import {
  PageHeader,
} from 'antd';
import {
  useHistory,
  // Switch,
  // Route,
} from 'react-router-dom';
// import Categories from './Categories';
// import Default from './Default';
import Product from './Products';

const Stock = () => {
  const history = useHistory();

  return (
    <div>
      <PageHeader
        title="Sản phẩm"
        subTitle="Thêm / bớt/ thay đổi thông tin sản phẩm"
        onBack={() => history.goBack()}
        // extra={[
        //   <SearchMed key="search" />,
        // ]}
      />
      <Product />
      {/* <Switch>
        <Route path="/meds/categories" exact component={Categories} />
        <Route path="/meds/products" component={Product} />
        <Route path="/meds" component={Default} />
      </Switch> */}
    </div>
  );
}

export default Stock;
