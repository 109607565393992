import React from 'react';
import {
  Form,
  Input,
  Button
} from 'antd'

const SearchNameDropdown = ({
  name,
  updateSearch
}) => {
  const [form] = Form.useForm()
  const update = () => {
    const values = form.getFieldsValue()
    // console.log(name)
    updateSearch(name, values[name])
  }
  return (
    <div style={{ padding: '1em'}}>
      <Form form={form} layout="vertical">
        <Form.Item name={name} label="Nhập vào tên để tìm">
          <Input />
        </Form.Item>
        <Button onClick={update}>Tìm kiếm</Button>
      </Form>
    </div>
  )
}

export default SearchNameDropdown
