import React from 'react'
import {
  Table,
  // Modal
} from 'antd'
import {
  gql,
  useLazyQuery
} from '@apollo/client'
import moment from 'moment'
import {
  numberWithCommas
} from '../../../../../utils/Match'
import LoadingScreen from '../../../../../utils/LoadingScreen'

export const LOAD_INV_RESULT = gql`
  query getResult($shop: String!, $inv: String!) {
    getInvResult(shop: $shop, inv: $inv) {
      inventories {
      _id
      product {
        name
        unit
        code
      }
      adj
      sellPrice
      created
      oSold
      tSold
      tPurchased
      oStock
      stock
      }
      total
    }
  }
`

const InvList = ({
  shop,
  inv
}) => {
  const [limit, setLimit] = React.useState(50)
  const [page, setPage] = React.useState(1)
  const [loadResult, { loading: loadingResult, data: invResult = { getInvResult: { inventories: [], total: 0 } }}] = useLazyQuery(LOAD_INV_RESULT, { fetchPolicy: 'network-only' })
  React.useEffect(() => {
    const load = async () => {
      await loadResult({
        variables: {
          shop,
          inv,
          page: page -1,
          limit
        }
      })
    }
    load ()
  }, [shop, inv, page, limit, loadResult])  

  const columns = [
    {
      key: '_prd',
      title: 'Sản phẩm',
      dataIndex: ['product', 'name'],
    },
    {
      key: '_unt',
      title: 'Đơn vị',
      dataIndex: ['product', 'unit']
    },
    {
      key: '_prc',
      title: 'Giá bán',
      dataIndex: 'sellPrice'
    },
    {
      key: '_adj',
      title: 'Điều chỉnh',
      dataIndex: 'adj'
    },
    {
      key: '_ttal',
      title: 'Giá trị điều chỉnh',
      render: record => <span>{`${numberWithCommas(record.adj * record.sellPrice)} VNĐ`}</span>
    },
    {
      key: '_crtd',
      title: 'Ngày kiểm kê',
      render: record => <span>{moment(new Date(parseFloat(record.created))).format('DD/MM/YY')}</span>
    }
  ]
  if(loadingResult && !invResult) {
    return <LoadingScreen />
  }
  if (invResult) {
    // console.log(invResult)
    return (
      <div style={{ maxHeight: '500px'}}>
        <Table
          loading={loadingResult}
          scroll={{ y: 400 }}
          columns={columns}
          dataSource={invResult.getInvResult.inventories}
          pagination={{
            current: page,
            onChange: (page, pageSize) =>{
              setPage(page)
              setLimit(pageSize)
            }
          }}
        />
      </div>
    )
  }
}

export default InvList