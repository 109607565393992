import React from 'react'
import {
  PageHeader,
  Typography
} from 'antd'
import DailyReport from './DailyReport'
import MonthlyReport from './MonthlyReport'
import {
  gql,
  useQuery
} from '@apollo/client'
import {
  GET_MY_PROFILE
} from '../'
import moment from 'moment'
import LoadingScreen from '../../utils/LoadingScreen'

const LOAD_D_REPORT = gql`
  query byShop($start:String, $end:String){
  saleValueByShop(start:$start, end: $end) {
    byShops {
      total
      shop {
        name
      }
    }
    byCats {
      total
      category {
        name
      }
    }
    byAgents {
      total
      agent {
        name
        email
      }
    }
    byMonth {
      shop
      thisMonth
      lastMonth
    }
    mostIP {
      product
      sellPrice
      avgPrice
      tSellPrice
      tAvgPrice
      intPercent
      tInt
    }
    agByMonth {
      agent
      thisMonth
      lastMonth
    }
  }
}
`

const { Title } = Typography

const Report = () => {
  const { data: profileData } = useQuery(GET_MY_PROFILE)
  const {loading: loadingReport, data: reportData, error: reportError } = useQuery(LOAD_D_REPORT)
  if (loadingReport) {
    return <LoadingScreen />
  }
  if (reportError) {
    return <span>Error</span>
  }
  const {
    byShops,
    byAgents,
    byCats,
    byMonth,
    mostIP,
    agByMonth
  } = reportData.saleValueByShop
  if (profileData && profileData.me.role === 'shopowner') {
    const month = moment().month() + 1
    return (
      <div>
        <PageHeader
          title={<Title level={3}>Báo cáo</Title>}
          subTitle={`Tháng ${month}`}
        />
        <DailyReport
          byShops={byShops}
          byAgents={byAgents}
          byCats={byCats}
        />
        <MonthlyReport
          byMonth={byMonth}
          mostIP={mostIP}
          agByMonth={agByMonth}
        />
      </div>
    )
  } else {
    return null
  }
}

export default Report