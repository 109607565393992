import React from 'react';
import {
  Menu,
  Button,
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import ProductsList from './ProductsList';

const Products = () => {
  const [addProductModal, toggleAddModal] = React.useState(false);

  return (
    <div>
      <Menu mode="horizontal" style={{ textAlign: 'right' }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleAddModal(true)}>
          Thêm sản phẩm
        </Button>
      </Menu>
      <ProductsList
        addProductModal={addProductModal}
        toggleAddModal={toggleAddModal}
      />
    </div>
  );
}

export default Products;
