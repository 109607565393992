import React from 'react'
import './BestIntPrds.css'
import {
  numberWithCommas
} from '../../../utils/Match'

const CustomBestPrdTT = ({
  active,
  payload,
  label
}) => {
  if (active && payload.length > 0) {
    return (
      <div className="tooltipBgr">
        <p>
          <strong>
            {label}
          </strong>
        </p>
        <p>{`Tổng bán được: ${numberWithCommas(payload[0].value)}`}</p>
        <p>{`Tổng Lãi: ${numberWithCommas(payload[1].value)}`}</p>
      </div>
    )
  }
  return null
}

export default CustomBestPrdTT
