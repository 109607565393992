import React from 'react'
import {
  Form,
  Row,
  Col,
  Select,
  // Alert,
  Button,
  InputNumber
} from 'antd'
import {
  DownloadOutlined
} from '@ant-design/icons'
import {
  gql,
  useLazyQuery
} from '@apollo/client'
// import CurrentProduct from './CurrentProduct'
import { useHotkeys } from 'react-hotkeys-hook';
import Highlighter from "react-highlight-words";
import './SaleForm.css'

const { Option } = Select

const AGENT_SEARCH_PRDS = gql`query agentSearchsProducts($shop:String!, $name:String!) {
  agentSearchsProducts(shop:$shop, name:$name) {
    _id
    product {
      _id
      name
      unit
      manufacture
      country
      position
      element
    }
    stock
    sellPrice
  }
}`


const SaleForm = ({
  addPrdToCart,
  shop,
  currentProduct,
  setCurrentProduct
}) => {
  const [form] = Form.useForm()
  const productInput = React.useRef(null)
  const [keyword, setKeywork] = React.useState('')
  // const [currentProduct, setCurrentProduct] = React.useState(null)
  const [currentTotal, setCurrentTotal] = React.useState(0)
  const [searchPrd, { loading: searching, data: searchData = { agentSearchsProducts: [] }}] = useLazyQuery(AGENT_SEARCH_PRDS, { fetchPolicy: 'network-only'})
  let searchTimeout = null
  const onSelect = (value, option) => {
    let selectedPrd;
    searchData.agentSearchsProducts.forEach(prd => {
      if(prd._id === value) {
        selectedPrd = prd
      }
    })
    if(selectedPrd) {
      setCurrentProduct(selectedPrd)
      if (form.getFieldValue('quantity')) {
        setCurrentTotal(form.getFieldValue('quantity') * selectedPrd.sellPrice)
      }
    }
  }

  const onQuantityChange = evt => {
    if(currentProduct) {
      setCurrentTotal(evt * currentProduct.sellPrice)
    }
  }

  const addPrd = () => {
    const { quantity } = form.getFieldsValue()
    if (currentProduct && parseInt(quantity) > 0) {
      addPrdToCart({ ...currentProduct, quantity })
      form.resetFields()
      setCurrentProduct(null)
      setCurrentTotal(0)
    }
  }

  const onSearch = async name => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(() => {
      if (name.length > 1) {
        setKeywork(name)
        searchPrd({ variables: { shop, name }})
      }
      // console.log(searchData)
    }, 200)
  }

  useHotkeys('alt+x', () => { productInput.current.focus() })

  return (
    <Form form={form} layout="vertical">
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            name="product"
            label="Sản phẩm(Alt+X)"
            rules={[
              {
                required: true,
                message: 'Không được bỏ trống'
              }
            ]}
            >
            <Select
              ref={productInput}
              showSearch
              onSearch={onSearch}
              optionFilterProp="children"
              filterOption={(input, option) => {
                // option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                // console.log(option.toLocaleString())
                return true
              }}
              onSelect={onSelect}
              loading={searching}
              >
            {searchData.agentSearchsProducts.map(prd => (
              <Option key={prd._id} value={prd._id}>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[keyword]}
                    textToHighlight={prd.product.name}
                  />
                  {/* {prd.product.name} */}
                </Option>
              ))}
          </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Số lượng phải > 0'
              },
              {
                type: 'number',
                message: 'Phải là số'
              }
            ]}
            name="quantity"
            label="Số lượng"
            // shouldUpdate
            >
              <InputNumber min={0} onChange={onQuantityChange} max={currentProduct ? currentProduct.stock : 100000} />
            </Form.Item>
        </Col>
        <Col span={4}>
          <p>Giá bán</p>
          <span>{currentProduct ? currentProduct.sellPrice : 0}</span>
        </Col>
        <Col span={4}>
          <p>Tổng</p>
          <strong>{currentTotal}</strong>
        </Col>
        <Col span={2}>
          <p>Thêm</p>
          <Button disabled={currentProduct && currentProduct.stock <= 0} onClick={addPrd} icon={<DownloadOutlined />} type="primary" />
        </Col>
      </Row>
      
    </Form>
  )
}

export default SaleForm
