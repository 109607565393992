import React from 'react';
import {
  Card,
  Button,
} from 'antd';
import {
  // DeleteOutlined,
  ReconciliationOutlined,
  DollarCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const ShopCard = ({ shop, user }) => {
  const actions = [
    // <DeleteOutlined />,
    // <span key="bl1" />,
    // <span key="bl2" />,
    // <span key="bl3" />,
    
  ];
  let to ="/"
  if (user.role === 'shopowner') {
    to = `/shops/${shop._id}`
    actions.push(
      <Link to={`/shops/${shop._id}`} key="edit">
        <Button block type="primary" icon={<ReconciliationOutlined />} />
      </Link>
    );
  }
  if (user.role === 'agent') {
    to =`/sale/${shop._id}`
    actions.push(
      <Link to={`/sale/${shop._id}`} key="sale">
        <Button type="primary">
          <DollarCircleOutlined />
          Bán hàng
        </Button>
      </Link>
    )
  }
  return (
    <Link to={to} key="edit">
      <Card
        title={shop.name}
        /* actions={actions} */
        >
        <p>Địa chỉ: {shop.address}</p>
        <p>Giờ mở cửa: {`${shop.time.open} - ${shop.time.close}`}</p>
        <p>Nhân viên bán hàng: {shop.sales && shop.sales.length > 0 ? shop.sales.length : 0}</p>
      </Card>
    </Link>
  );
}

export default ShopCard;