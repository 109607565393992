import React from 'react'
import {
  Form,
  Input,
  Collapse,
  Button,
} from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import moment from 'moment'
import { numberWithCommas } from '../../../../../../utils/Match'

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}

const PurchasetHeader = ({
  total,
  form,
  purchase,
  update,
  loading
}) => {
  // const [form] = Form.useForm()
  if (!purchase) {
    return <p style={{ textAlign: 'center' }}>Loading....</p>
  }
  
  const updateInfo = async () => {
    const {
      vendor,
      invoice,
      note
    } = form.getFieldsValue()
    await update(
      purchase._id,
      {
        invoice,
        vendor,
        note
      }
    )
    form.setFieldsValue({
      vendor,
      invoice,
      note
    })
  }

  // console.log(purchase)
  form.setFieldsValue({ ...purchase })
  
  return (
    <div>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel key="1" header="Thông tin đơn hàng">
          <Form { ...layout } form={form} layout="horizol">
            <Form.Item label="Nhà Cung cấp" name="vendor">
              <Input />
            </Form.Item>
            <Form.Item label="Số hóa đơn" name="invoice">
              <Input />
            </Form.Item>
            <Form.Item
              label="Số CT"
              name="trace"
              rules={[
                {
                  required: true,
                  message: 'Bắt buộc phải có để sau còn theo dõi'
                }
              ]}
              >
              <Input disabled />
            </Form.Item>
            <Form.Item name="note" label="Ghi chú">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Tổng">
              <strong>{`${numberWithCommas(total)} VNĐ`}</strong>
            </Form.Item>
            <Form.Item label="Ngày Tạo">
              <span>{moment(parseFloat(purchase.created)).format('DD/MM/YYYY HH:mm:ss')}</span>
            </Form.Item>
          </Form>
          <p style={{ textAlign: 'right' }}>
            <Button loading={loading} onClick={() => updateInfo()} size="small" type="link" icon={<CheckOutlined />}> Cập nhật</Button>
          </p>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default PurchasetHeader