import React from 'react';
import {
  useParams,
} from 'react-router-dom'
import {
  Avatar,
  Alert,
  Descriptions
} from 'antd'
import {
  gql,
  useLazyQuery
} from '@apollo/client'
import moment from 'moment'
import SaleRecords from './SaleRecords'

const LOAD_AGENT = gql`
  query loadSingleAgent($agent:String!) {
    loadSingleAgent(agent:$agent){
      name
      email
      _id
      created
      profile {
        phones
        avatar
      }
    }
}
`
const SingleAgent = () => {
  const { agent } = useParams()
  const [loadAgent, { data: agentData }] = useLazyQuery(LOAD_AGENT, { fetchPolicy: 'cache-and-network'})
  React.useEffect(() => {
    const loadA = async () => {
       await loadAgent({
        variables: {
          agent
        }
      })
    }
    loadA()
  }, [loadAgent, agent])
  if (!agentData || !agentData.loadSingleAgent) return <span>loading.....</span>
  return (
    <div>
      {agentData.loadSingleAgent && (
        <Alert
          type="info"
          icon={<Avatar src={agentData.loadSingleAgent.profile.avatar} size={40} />}
          showIcon
          closable
          // message="Agent basic information"
          description={(
            <Descriptions title={agentData.loadSingleAgent.name}>
              <Descriptions.Item label="Tên">{agentData.loadSingleAgent.name}</Descriptions.Item>
              <Descriptions.Item label="phone/email">{agentData.loadSingleAgent.email}</Descriptions.Item>
              <Descriptions.Item label="Ngày tạo">{moment(parseFloat(agentData.loadSingleAgent.created)).format('DD/MM/YYYY')}</Descriptions.Item>
              <Descriptions.Item label="Địa chỉ">
                NA
              </Descriptions.Item>
            </Descriptions>
          )}
        />
      )}
      <SaleRecords agent={agent} />
    </div>
  );
}
export default SingleAgent;
