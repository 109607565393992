import React from 'react'
import {
  PageHeader,
  Button
} from 'antd'
import {
  useLazyQuery
} from '@apollo/client'
import {
  LOAD_INV_RESULT
} from './InvList'
import moment from 'moment'
import {
  toXLSX
} from '../../../../../utils/Match'

export const Title = ({
  inv,
  shop
}) => {
  const [loadFull, { loading }] = useLazyQuery(
    LOAD_INV_RESULT, 
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        if (!data) return
        const { getInvResult } = data
        const wsData = getInvResult.inventories.map((ivt, index) => ({
          stt: index + 1,
          'Mã SP': ivt.product.code,
          'Tên SP': ivt.product.name,
          'Đơn Vị': ivt.product.unit,
          'Giá bán': ivt.sellPrice,
          'Số đầu kỳ': ivt.oStock || 0,
          'Đã Nhập': ivt.tPurchased || 0,
          'Đã bán': ivt.tSold || 0,
          'Bán Khác': ivt.oSold || 0,
          'Số cuối kỳ': ivt.stock || 0,
          'Số Thực tế':ivt.stock + ivt.adj ,
          'Điều Chỉnh': ivt.adj,
          'Tổng tiền điều chỉnh': ivt.adj * ivt.sellPrice,
          'Kiểm lúc': moment(new Date(parseFloat(ivt.created))).format('DD/MM/YYYY - h:m:s')
        }))
        toXLSX(wsData, 'Inventory Report', 'Inventory.xlsx')
      }
    }
  )
  const loadData = async () => {
    const limit = 100000
    const page = 0
    await loadFull({
      variables: {
        shop,
        inv,
        limit,
        page
      }
    })
    //
  }
  return (
    <PageHeader
      title="Kết quả kiểm kê"
      extra={[
        <Button loading={loading} onClick={loadData} type="primary" key="_expt">Download file excel</Button>
      ]}
    />
  )
}

export default Title