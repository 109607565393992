import React from 'react';
import {
  useHistory,
} from 'react-router-dom';
import {
  PageHeader,
  Button,
  message
} from 'antd';
import {
  UserAddOutlined
} from '@ant-design/icons';
import { gql, useQuery, useMutation } from '@apollo/client';

// self made components import
import NewAgentModal from './NewSaleModal';
import AgentsList from './AgentsList';
import { GET_MY_SHOPS } from '../../default/ShopsOverview';
import LoadingScreen from '../../../utils/LoadingScreen';

export const LOAD_AGENTS = gql`
  query loadAgents{
    loadMyAgents{
      name
      email
      _id
      profile {
        avatar
        address {
          city
        }
      }
    }
  }
`;
export const ASSIGN_AGENT = gql`
  mutation assignAgent($agent: String!, $shop: String!) {
    assignAgentToShop(agent: $agent, shop: $shop) {
      name
      _id
    }
  }
`;
const CREATE_AGENT_MUTATION = gql`
  mutation createA($name: String!, $email: String!, $password: String!) {
    createAgent(name: $name, email: $email, password: $password) {
      name
      email
      role
    }
  }
`;

const SaleAgent = () => {
  const history = useHistory();
  const [addModal, toggleAddModal] = React.useState(false);
  const agentQuery = useQuery(LOAD_AGENTS);
  const [assignAgent] = useMutation(ASSIGN_AGENT);
  const shopsQuery = useQuery(GET_MY_SHOPS);
  const [createAgent, { loading: creating } ] = useMutation(CREATE_AGENT_MUTATION);
  
  const assignAgentToShop = async (agent, shop) => {
    await assignAgent({variables: {agent, shop}})
      .then(res => {
        message.success('Agent is assigned to shop')
      })
      .catch(e => {
        // console.log(e);
        message.error("Something went wrong")
      })
  }

  const createA = async (data) => {
    await createAgent({
      query: CREATE_AGENT_MUTATION,
      variables: {
        ...data
      },
      update: (cache, {data}) => {
        const oData = cache.readQuery({
          query: LOAD_AGENTS
        })
        console.log(oData)
        console.log(data)
        cache.writeQuery({
          query: LOAD_AGENTS,
          data: {
            ...oData,
            loadMyAgents: [data.createAgent, ...oData.loadMyAgents],
          }
        })
      }
    })
    toggleAddModal(false)
  }

  return (
    <div>
      <PageHeader
        title="Nhân viên"
        onBack={() => history.goBack()}
        subTitle="Quản lý nhân viên bán hàng"
        extra={[
          <Button onClick={() => toggleAddModal(true)} key="add" type="primary">
            <UserAddOutlined />
            Thêm nhân viên
          </Button>
        ]}
      />
      <div>
        {agentQuery.loading && (
          <LoadingScreen text="Đang tải dữ liệu về nhân viên" />
        )}
        {!agentQuery.loading && agentQuery.data && !shopsQuery.loading && shopsQuery.data && (
          <div>
            <AgentsList
              shops={shopsQuery.data.loadMyShops || []}
              agents={agentQuery.data.loadMyAgents}
              assignAgent={assignAgentToShop}
            />
          </div>
        )}
      </div>
      <NewAgentModal
        creating={creating}
        visible={addModal}
        onOk={createA}
        onCancel={() => toggleAddModal(false)}
      />
    </div>
  );
}

export default SaleAgent;
