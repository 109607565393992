import React from 'react'
import {
  Table,
  // shop,
  // Input,
  Button,
  // Badge,
  // Space,
  message,
  Popconfirm,
  Tooltip
} from 'antd'
import moment from 'moment'
import {
  DeleteOutlined,
  EditOutlined,
  // FilterOutlined,
  SearchOutlined
} from '@ant-design/icons'
import PurchaseProduct from './PurchasedProducts'
import './style.css'
import { SearchByName } from './FilterDropdown'
import {
  useHotkeys
} from 'react-hotkeys-hook'

import { numberWithCommas } from '../../../../../utils/Match'

const PurchasesList = ({
  openPurchase,
  updateSearch,
  clearSearch,
  dataSource,
  loading,
  search,
  removePC,
  current,
  shop
}) => {
  // const [productFilter, toggleProductFilter] = React.useState(false)
  const [filters, setFilters] = React.useState({
    trace: false,
    vendor: false,
    product: false
  })

  const updateFilter = (key, value) => {
    const newF = { ...filters }
    newF[key] = value
    setFilters(newF)
  }
  const prdRef = React.useRef(null)
  const vRef = React.useRef(null)
  const tRef = React.useRef(null)
  const columns = [
    {
      key: '_crtd',
      title: 'Ngày',
      render: record => <span>{moment(parseFloat(record.created)).format('DD/MM/YYYY')}</span>
    },
    {
      key: '_trace',
      dataIndex: 'trace',
      filterIcon: <SearchOutlined />,
      title: filters.trace || search.trace
        ?
        <SearchByName
          search={search}
          updateSearch={updateSearch}
          nameRef={tRef}
          close={() => {
            updateSearch('trace', '')
            updateFilter('trace',false)
            }
          }
          name="trace"
        />
        :
      <span>SCT (Ctrl+Q)</span>,
    },
    {
      key: '_vendr',
      dataIndex: 'vendor',
      // filterDropdownVisible: search.vendor === '',
      filterIcon: <SearchOutlined />,
      title: filters.vendor || search.vendor
        ?
        <SearchByName
          search={search}
          updateSearch={updateSearch}
          nameRef={vRef}
          close={() => {
            updateSearch('vendor', '')
            updateFilter('vendor',false)
            }
          }
          name="vendor"
        />
        :
      <span>Nhà cung cấp(Ctrl + E)</span>,
      render: (_, record) => (
        <Tooltip color="pink" title={record.note}>
          <strong>{record.vendor}</strong>
          <br />
          <small><i>{record.note}</i></small>
        </Tooltip>
      )
    },
    {
      key: '_prds',
      filterIcon: <SearchOutlined />,
      title: filters.product || search.product
        ?
        <SearchByName
          search={search}
          updateSearch={updateSearch}
          nameRef={prdRef}
          close={() => {
            updateSearch('product', '')
            updateFilter('product', false)}
          }
          name="product"
        />
        :
        <span>Sản phẩm (Ctrl + P)</span>,
      render: record => <span>{record.products.length}</span>,
      filterDropdown: null
    },
    {
      key: '_tTal',
      title: 'Tổng tiền',
      render: record => {
        let total = 0
        record.products.forEach(p => {
          total += p.buyPrice * p.quantity
        })
        return <span>{numberWithCommas(total)}</span>
      }
    },
    {
      key: '_acts',
      title: 'Chức năng',
      render: (record) => (
        <span>
          <Button type="link" icon={<EditOutlined />} onClick={() => openPurchase(record._id)}> Sửa</Button>
          <Popconfirm
            title="Có chắc xóa không?"
            onCancel={() => message.info('OK, hủy lệnh xóa')}
            onConfirm={() => removePC(record._id)}
            >
            <Button loading={current.removing && current.currentRemove ===record._id}  type="link" danger icon={<DeleteOutlined />}>Xóa</Button>
          </Popconfirm>
        </span>
      )
    }
  ]

  useHotkeys('ctrl+p', e => {
    e.preventDefault()
    // toggleProductFilter(true)
    const newFilter = { ...filters }
    newFilter['product'] = true
    setFilters(newFilter)
    prdRef.current.focus()
  })

  useHotkeys('ctrl+e', e => {
    e.preventDefault()
    const newFilter = { ...filters }
    newFilter['vendor'] = true
    setFilters(newFilter)
    vRef.current.focus()
  })
  useHotkeys('ctrl+q', e => {
    e.preventDefault()
    const newFilter = { ...filters }
    newFilter['trace'] = true
    setFilters(newFilter)
    tRef.current.focus()
  })

  return (
    <div>
      <Table
        loading={loading}
        rowClassName={() => 'boldRow'}
        rowKey={record => record._id}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        expandable={{
          expandedRowRender: record => <PurchaseProduct shop={shop} purchase={record} />
        }}

        summary={pageData => {
          let total = 0
          pageData.forEach((d, i) => {
            d.products.forEach(prd => {
              total += prd.quantity * prd.buyPrice
            })
          })

          return (
            <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={6}>
                <strong>
                Tổng nhập
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}>
                <strong>
                  {`${numberWithCommas(total)} VND`}
                </strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
          )
        }}
      />
    </div>
  )
}
export default PurchasesList
