import React from 'react'
import {
  BarChart,
  Bar,
  // Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import CustomBestPrdTT from './BestPrdsLabel'

const BestIntPrds = ({
  mostIP
}) => {
  return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={mostIP}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="product" />
          <YAxis />
          <Tooltip content={<CustomBestPrdTT />} />
          <Legend />
          <Bar dataKey="tSellPrice" stackId="a" fill="#00C49F" />
          <Bar dataKey="tInt" stackId="a" fill="#FFBB28" />
        </BarChart>
      </ResponsiveContainer>
    );
}
export default BestIntPrds
