import React from 'react';
import {
    Route,
    Redirect,
} from 'react-router-dom';
import JWT from 'jsonwebtoken';


const PrivateRoute = ({ component: Component, ...rest }) => {
    // const auth = useAuth();
    const decoded = JWT.decode(localStorage.getItem('ACCESS_TOKEN'));
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                decoded ? (
                    <Component {...routeProps} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: routeProps.location }
                            }}
                        />
                    )
            }
        />
    );
};

export default PrivateRoute;