import React from 'react'
import {
  Table,
  // Typography,
  Select,
  Button,
  Form,
  InputNumber,
  Row,
  Col,
  Popconfirm,
  // Input,
} from 'antd'
import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import {
  gql,
  useLazyQuery
} from '@apollo/client'
import { useHotkeys } from 'react-hotkeys-hook';
import {
  numberWithCommas
} from '../../../../../utils/Match'

const { Option } = Select
// const { Title } = Typography

export const SEARCH_PRODUCTS = gql`
  query oSearchPrdByName($shop: String!, $keyword: String, $limit: Int) {
    oSearchPrdByName(shop: $shop, keyword: $keyword, limit: $limit) {
        _id
        name
        unit
        code
        sellPrice
        avgPrice
        stock
    }
  }
`
const ProductsList = ({
  selectedPrd,
  setSelectedPrd,
  cart,
  setCart,
  shop
}) => {
  const addBtn = React.useRef(null)
  const [keyword, setKeyword] = React.useState(null)
  const [currentRec, setCurrentRec] = React.useState({ _id: null, field: null })
  // const [selectedPrd, setSelectedPrd] = React.useState({})
  // const [cart, setCart] = React.useState([])
  const [form] = Form.useForm()
  const [editForm] = Form.useForm()
  const limit = 50
  const [searchPrd, { loading: searching, data: searchResult = { oSearchPrdByName: [] }}] = useLazyQuery(SEARCH_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      shop,
      limit,
      keyword
    }
  })
  const searchRef = React.useRef(null)
  // const [getSellPrice, { data: priceData = { oGetSingleProduct: { } } }] = useLazyQuery(LOAD_SINGLE_SP, { fetchPolicy: 'cache-and-network'})
  let searchTimeout = null
  React.useEffect(() => {
    const search = async () => {
      await searchPrd({
        query: SEARCH_PRODUCTS,
        variables: {
          keyword,
          limit
        }
      })
    }
    if (keyword && keyword.length >=2) search()
  }, [searchPrd, keyword])

  const onProductSelect = (option, listedProducts) => {
    const prd = listedProducts.filter(p => option === p._id )
    setSelectedPrd(prd[0])
    console.log(prd)
  }

  const changeKeyword = async value => {
    if (searchTimeout) clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      if (value.length >= 2) setKeyword(value)
    }, 200)
  }
  const addProd = async () => {
    const { product, quantity, buyPrice } = form.getFieldsValue()
    // console.log(product, quantity)
    if (!product || !quantity || quantity <= 0) {
      // console.log('no product found')
      return null
    }
    let found = false
    const newCart = cart.map(prd => {
      if (prd._id.toString() === product.toString()) {
        found = true
        return {
          ...prd,
          quantity: prd.quantity + quantity,
          buyPrice,
        }
      }
      return prd
    })
    if (!found && searchResult.oSearchPrdByName) {
      const thePrd = searchResult.oSearchPrdByName.filter(p => p._id.toString() === product.toString())
      newCart.push({
        ...thePrd[0],
        quantity,
        buyPrice,
        // sellPrice: 'update'
      })
    }
    setCart(newCart)
    form.resetFields()
    setTimeout(() => searchRef.current.focus(), 10)
  }

  const removePrd = (rec) => {
    const newCard = cart.filter(p => p._id !== rec._id)
    setCart(newCard)
  }

  const saveEdit = (id, field) => {
    const data = editForm.getFieldsValue()
      setCart(cart.map(p => {
        if (p._id.toString() === id.toString() && data[field]) {
          p[field] = data[field]
          if(field === 'total') {
            p.buyPrice = Math.round(data.total / p.quantity)
          }
          if(field === 'buyPrice') {
            p.total = p.quantity * data.buyPrice
          }
          return p
        }
        return p
      }))
    // }
    setCurrentRec({ _id: null, field: null })
  }

  const columns = [
    {
      key: '_no',
      title: 'STT',
      render: (_,record, index) => <span>
        {index + 1} -
        <Popconfirm
          title="Xóa SP khỏi danh mục ?"
          okText="Xóa"
          cancelText="Thôi"
          onConfirm={() => removePrd(record)}
        >
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </span>
    },
    {
      key: '_prd_code',
      title: 'Mã',
      dataIndex: 'code'
    },
    {
      key: '_name',
      title: 'Tên',
      dataIndex: 'name'
    },
    {
      key: '_unit',
      title: 'Đơn vị',
      dataIndex: 'unit'
    },
    {
      key: '_bPrice',
      title: 'Giá nhập',
      dataIndex: 'buyPrice',
      editable: true,
      render: (text, record) => <span>{numberWithCommas(text)}</span>
    },
    {
      key: '_sPrice',
      title: 'Giá bán (auto)',
      editable: 'true',
      dataIndex: 'sellPrice',
      render: (text, record) => <span>{text ? numberWithCommas(text) : 0}</span>
      // render: record => <span>{record.sellPrice ? numberWithCommas(record.sellPrice): 'new'}</span> 
    },
    {
      editable: true,
      key: '_quan',
      title: 'Số lượng',
      dataIndex: 'quantity',
    },
    {
      dataIndex: 'total',
      editable: true,
      key: '_subTol',
      title: 'Tổng',
      render: (text, record) => <span>{numberWithCommas(text)}</span>
    }
  ].map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          editing: currentRec._id === record._id && currentRec.field === col.dataIndex,
          editable: col.editable,
          onClick: () => {
            setCurrentRec({_id: record._id, field: col.dataIndex })
            const data = editForm.getFieldsValue()
            data[col.dataIndex] = record[col.dataIndex]
            // console.log(record)
            editForm.setFieldsValue({
              buyPrice: record.buyPrice = record.total,
              quantity: record.quantity,
              sellPrice: record.sellPrice
            })
          },
          onUpdate: () => saveEdit(record._id, col.dataIndex)
        })
      }
    }
    return col
  })

  useHotkeys('shift+enter', () => addBtn.current.click())
  
  return (
    <div>
      {/* <PageHeader
        title={
          <Title level={3}>PList</Title>
        }
        extra={[
          ]
        }
      /> */}
      <Form layout="vertical" key="_f" form={form}>
        <Row gutter={15} style={{ marginBottom: '1em'}}>
          <Col span={12}>
            <Form.Item label="Tên SP" name="product" labelCol={4} wrapperCol={8}>
              <Select
                showAction={["focus"]}
                autoFocus={true}
                ref={searchRef}
                showSearch
                onSearch={changeKeyword}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                  // console.log(option.toLocaleString())
                  return true
                }}
                loading={searching}
                onSelect={(option) => onProductSelect(option, searchResult.oSearchPrdByName)}
              >
                {searchResult.oSearchPrdByName.map(prd => (
                  <Option value={prd._id} key={prd._id}>{prd.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            {selectedPrd && selectedPrd.unit && (
              <Form.Item label="Đơn vị">
                <strong>{selectedPrd.unit}</strong>
              </Form.Item>
            )}
          </Col>
          <Col span={4}>
            <Form.Item name="buyPrice" label="Giá nhập">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Số lượng" name="quantity">
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label={` `}>
              <Button ref={addBtn} type="primary" icon={<PlusOutlined />} onClick={() => addProd()} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form form={editForm}>
        <Table
          pagination={false}
          rowKey={rec => rec._id}
          bordered
          dataSource={cart.map(p => ({
            ...p,
            total: p.total? p.total : p.buyPrice * p.quantity
          }))}
          columns={columns}
          summary={(data) => {
            let grandTotal = 0
            data.forEach(prd => {
              grandTotal += prd.total
            })
            return (
              <Table.Summary.Row key="_sum">
                <Table.Summary.Cell index={0} colSpan={7} style={{ textAlign: 'right'}}>Tổng hóa đơn</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  <strong>
                    {`${numberWithCommas(grandTotal)} VND`}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          />
      </Form>
    </div>
  )
}

export default ProductsList

const EditableCell = ({
  record,
  editing,
  children,
  dataIndex,
  editable,
  onClick,
  onUpdate,
  loading,
  ...restProps
}) => {

  return (
    <td {...restProps}>
      {editable && editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            { type: 'number', message: 'Chỗ này phải nhập số không là báo lỗi ngay' },
            { required: true, message: 'phải nhập chỗ này, không được bỏ trống'}
          ]}
        >
          <InputNumber
            disabled={loading}
            style={{ width: '120px'}}
            onPressEnter={onUpdate}
          />
          {/* 
          <InputNumber
            min={0}
            disabled={loading}
            style={{ width: '120px'}}
            onPressEnter={onUpdate}
          />
           */}
        </Form.Item>
        )
      : (
        editable ? <span style={{minHeight: '10px', minWidth: '20px'}} className={editable ? 'ediablePointer' : ''}  onClick={() => onClick(record._id)}>
          {children}
        </span>
        :
        children
      )
      }
    </td>
  )
}