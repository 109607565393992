import React from 'react'
import {
  Table,
  PageHeader
} from 'antd'
import {
  numberWithCommas
} from '../../../../../utils/Match'
import EditPCModal from './EditPCModal/EditPCModal'

const PurchaseProduct = ({
  shop,
  // removePIPC,
  purchase,
  // range
}) => {
  // const [productsList, setProductsList] = React.useState(null)
  const [currentPC, setCurrentPC] = React.useState(null)
  const [editModal, toggleEditModal] = React.useState(false)
  const columns = [
    {
      key: '_idx',
      title: 'STT',
      render: (_, __, index) => (<span>{index + 1}</span>)
    },
    {
      key: '_name',
      title: 'Tên SP',
      dataIndex: ['product', 'name']
    },
    {
      key: '_unit',
      title: 'Đơn vị',
      dataIndex: ['product', 'unit']
    },
    {
      key: '_bPrice',
      title: 'Giá nhập',
      dataIndex: 'buyPrice'
    },
    {
      key: '_quan',
      title: 'Số lượng',
      dataIndex: 'quantity'
    },
    {
      key: '_subTtl',
      title: 'Tổng',
      render: record => <span>{numberWithCommas(record.buyPrice * record.quantity)}</span>
    },
    // {
    //   key: '_acts',
    //   title: 'Xóa',
    //   render: (record) => {
    //     // console.log(record.product._id)
    //     // console.log(purchase)
    //     return (
    //       <span>
    //         <Button key="_del" type="link" onClick={() => removePIPC(purchase._id, record.product._id)} danger icon={<DeleteOutlined />} />
    //       </span>
    //     )
    //   }
    // }
  ]


  const closeEditModal = () => {
    setCurrentPC(null)
    toggleEditModal(false)
  }
 
  return (
    <div className="expandedTableWrapper">
      <PageHeader
        title={`Đơn hàng ${purchase.trace} - ${purchase.vendor || ''}`}
      />
      <Table
        bordered
        dataSource={purchase.products}
        columns={columns}
        pagination={false}
        summary={pageData => {
          let total = 0
          pageData.forEach(pd => {
            total += pd.quantity * pd.buyPrice
          })
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5}>
                <strong>Tổng</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}>
                <strong>{numberWithCommas(total)}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }}
      />
      <EditPCModal
        purchase={currentPC}
        shop={shop}
        visible={editModal}
        onCancel={() => closeEditModal()}
        onOk={() => closeEditModal()}
      />
    </div>
  )
}
export default PurchaseProduct
