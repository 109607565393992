import React from 'react';
import {
  Descriptions
} from 'antd';

const ShopBasicInfo = ({ shop }) => (
  <Descriptions>
    <Descriptions.Item label="Địa chỉ">{shop.address}</Descriptions.Item>
    <Descriptions.Item label="Giờ mở cửa">{shop.time.open}</Descriptions.Item>
    <Descriptions.Item label="Giờ đóng cửa">{shop.time.close}</Descriptions.Item>
  </Descriptions>
);

export default ShopBasicInfo;
