import React, {
  useContext,
  createContext
} from 'react'

import {
  gql,
  useQuery
} from '@apollo/client'

export const GET_CATS = gql`
  query getCategories {
    getCategories {
      _id
      name
      element
    }
  }
`
export const LOAD_SETTINGS = gql`
  query loadSettings {
    loadSettings {
      units
      categories
      productsPerPage
      created
      changed
    }
  }
`

const initState = {
  pageTitle: 'Here is the page title',
  settings: {
    units: [],
    categories: [],
    productsPerPage: 100
  },
  categories: []
}

const nameContext = createContext(initState)

const NameProvider = ({ children }) => {
  const {data = { getCategories: [] }} = useQuery(GET_CATS)
  const {data: settings = {loadSettings: { units: [], productsPerPage: 100 } } } = useQuery(LOAD_SETTINGS)
  initState.categories = data.getCategories
  initState.settings = settings.loadSettings
  // const 
  const [state, dispatch] = React.useReducer((state, action) => {
    // const currentState = { ...state }
    switch (action.type) {
      case 'CHANGE_PAGE_TITLE':
        return {
          ...state,
          pageTitle: action.payload
        }
      case 'UPDATE_SETTINGS':
        return {
          ...state,
          settings: action.payload
        }
      case 'SET_CATS':
        return {
          ...state,
          categories: action.payload
        }
      default:
        return state
    }
  }, initState)
  return (
    <nameContext.Provider value={{state, dispatch}}>
      {children}
    </nameContext.Provider>
  )
}
export default NameProvider
export const usePageTitle = () => useContext(nameContext)