import React from 'react'
import {
  Modal,
  Form,
  Input,
  message
} from 'antd'
import {
  gql,
  useMutation
} from '@apollo/client'

const formLayout = {
  wrapperCol: { span: 16 },
  labelCol: { span: 8 }
}

const CHANGE_PWD_MUT = gql`
  mutation changePwd($currentPwd: String!, $newPwd: String!, $confirmNewPwd: String!) {
    changePwd(currentPwd: $currentPwd, newPwd: $newPwd, confirmNewPwd: $confirmNewPwd)
  }
`

const PasswordModal = ({
  visible,
  onCancel,
  onOk
}) => {
  const [form] = Form.useForm()
  const [changePwd, { loading: changing }] = useMutation(CHANGE_PWD_MUT)

  const changePass = async () => {
    const {
      currentPwd,
      newPwd,
      confirmNewPwd
    } = form.getFieldsValue()
    try {
      await changePwd({
        variables: {
          currentPwd,
          newPwd,
          confirmNewPwd
        }
      })
      onOk()
    } catch (e) {
      message.error('Đổi mật khẩu KHÔNG thành công')
    }
  }

  return (
    <Modal
      title="Đổi mật khẩu"
      visible={visible}
      onCancel={onCancel}
      onOk={() => changePass()}
      okButtonProps={{ loading: changing }}
    >
      <Form { ...formLayout } form={form} layout="horizol">
        <Form.Item label="Mật khẩu Hiện tại" name="currentPwd">
          <Input type="password" />
        </Form.Item>
        <Form.Item label="Mật khẩu Mới" name="newPwd">
          <Input type="password" />
        </Form.Item>
        <Form.Item label="Nhập lại Mật khẩu mới" name="confirmNewPwd">
          <Input type="password" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PasswordModal
