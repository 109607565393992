import React from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend
} from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PieReport = ({
  data
}) => (
  <ResponsiveContainer width="100%" height="90%">
    <PieChart width={400} height={400}>
      <Legend />
      <Tooltip />
      <Pie
        label
        cx="50%"
        cy="50%"
        innerRadius={60}
        paddingAngle={3}
        outerRadius={80}
        fill="#8884d8"
        data={data}
        dataKey="value"
        nameKey="name"
        >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
)
export default PieReport
