import React from 'react'
import {
  Drawer,
  Space,
  Button,
  Typography,
  PageHeader,
  Modal,
  Form,
  Input,
  Tooltip,
  Badge,
  Row,
  Col,
  Alert
} from 'antd'
import SaleForm from './SaleForm'
import Cart from './Cart'
import CurrentProduct from './CurrentProduct'

const { Title } = Typography


const SaleDrawer = ({
  visible,
  onCancel,
  onOk,
  // products,
  shop,
  addSale,
  addingSale,
}) => {
  const [form] = Form.useForm()
  const [shoppingCart, setShoppingCart] = React.useState([])
  const [note, setNote] = React.useState('')
  const [currentProduct, setCurrentProduct] = React.useState(null)
  // const [addSale, { loading }] = useMutation(ADD_SALE_MUT)
  const addPrdToCart = prd => {
    let found = false
    const newPrds = shoppingCart.map(pro => {
      if(pro._id === prd._id) {
        // product already in the cart, add new number
        found = true
        return {
          ...pro,
          quantity: parseInt(pro.quantity) + parseInt(prd.quantity)
        }
      }
      return pro
    })
    if (found) {
      setShoppingCart(newPrds)
    } else {
      setShoppingCart(newPrds.concat(prd))
    }
  }

  const removeFromCart = record => {
    const newPrds = shoppingCart.filter(prd => {
      return prd._id !== record._id
    })
    setShoppingCart(newPrds)
  }

  const addNote = () => {
    Modal.confirm({
      title: 'Ghi chú cho đơn hàng',
      content: (
        <Form form={form} layout="vertical">
          <Form.Item label="Thông tin ghi chú" name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      ),
      onOk: () => {
        setNote(form.getFieldValue('note'))
      },
      onCancel: close => close()
    })
  }
  const submitSale = async () => {
    // console.log(shoppingCart)
    const allProducts = shoppingCart.map(prd => ({
      product: prd.product._id,
      quantity: prd.quantity,
      sellPrice: prd.sellPrice,
    }))
    // console.log(allProducts)
    await addSale({
      shop,
      products: allProducts,
      note,
      saleType: 'agentSale'
    })
    setNote('')
    setShoppingCart([])
    onOk()
  }

  const confirmExit = () => {
    if (shoppingCart.length > 0) {
      Modal.confirm({
        title: 'Hủy đơn hàng ?',
        content: (
          <p>Các sản phẩn sẽ bị loại khỏi giỏ hàng</p>
        ),
        onOk: () => {
          setShoppingCart([])
          onCancel()
        },
        onCancel: close => close()
      })
    } else {
      onCancel()
    }
  }

  return (
    <Drawer
      keyboard={false}
      onClose={() => confirmExit()}
      title="Bán hàng"
      visible={visible}
      width={'100%'}
      footer={(
        <Space style={{ textAlign: 'right' }}>
          <Button onClick={() => confirmExit()}>Hủy đơn</Button>
          <Button loading={addingSale} type="primary" onClick={() => submitSale()}>Xác nhận</Button>
        </Space>
      )}
      >
        <Row gutter={10}>
          <Col xs={24} md={18}>
            <SaleForm
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
              addPrdToCart={addPrdToCart}
              shop={shop}
            />
            {shoppingCart && shoppingCart.length > 0 && (
              <div style={{paddingTop: '3em'}}>
                <PageHeader
                  title={<Title level={3}>Giỏ hàng</Title>}
                  extra={(
                    <Tooltip title={note ? note : 'Thêm ghi chú cho đơn hàng'}>
                      {note ? (
                        <Badge dot>
                          <Button onClick={addNote}>Thông tin khách & Ghi chú</Button>
                        </Badge>
                      ) : (
                        <Button onClick={addNote}>Thông tin khách & Ghi chú</Button>
                      )}
                    </Tooltip>
                  )}
                />
                <Cart products={shoppingCart} removeFromCart={removeFromCart} />
              </div>
            )}
          </Col>
          <Col xs={24} md={6}>
            {currentProduct && (
              <Alert
                message="Thông tin sản phẩm"
                description={<CurrentProduct currentPrd={currentProduct} />}
                type={currentProduct.stock <= 0 ? 'error' : 'success'}
                showIcon
              />
              )}
              {!currentProduct && (
                <Alert
                  type="info"
                  message={<strong>Chọn sản phẩm để xem thông tin</strong>}
                  showIcon
                  description={
                    <p style={{textAlign: 'justify'}}>
                      Nhấn tổ hợp phím Ctrl+X để bắt đầu tìm kiếm sản phẩm, Chọn sản phẩm từ danh sách gợi ý để xem thông tin về sản phảm.
                      Chỉ những sản phẩm còn tồn kho mới được cho vào giỏ hàng
                    </p>
                  }
                />
              )

              }
          </Col>
        </Row>
    </Drawer>
  )
}

export default SaleDrawer