import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'


const uri = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API
const httpLink = new createUploadLink({ uri });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({headers = {} }) => ({
    headers: {
      ...headers,
      'x-token': localStorage.getItem('ACCESS_TOKEN') || null
    }
  }));
  return forward(operation);
});

const client = new ApolloClient({
  // uri: 'https://countries-274616.ew.r.appspot.com/',
  // uri: 'http://localhost:3000',
  // headers: {
  //   'x-token': localStorage.getItem('ACCESS_TOKEN') || ''
  cache: new InMemoryCache(),
  link: from([
    authMiddleware,
    httpLink
  ])
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
