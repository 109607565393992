import React from 'react'
import {
  Table,
  Button
} from 'antd'
import moment from 'moment'
import {
  numberWithCommas
} from '../../../../../utils/Match'

const InventoriesList = ({
  shop,
  openActiveInv,
  data,
  page,
  setPage,
  loading,
  onClick
}) => {
  const columns = [
    {
      key: '_idx',
      title: 'STT',
      render: (_, __, index) => <span>{index + 1}</span>
    },
    {
      key: '_start',
      title: 'Đầu kỳ',
      render: record => <span>{moment(new Date(parseFloat(record.start))).format('DD/MM/YYYY')}</span>
    },
    {
      key: '_end',
      title: 'Cuối kỳ',
      render: record => <span>{moment(new Date(parseFloat(record.end))).format('DD/MM/YYYY')}</span>
    },
    {
      key: '_tProduct',
      title: 'Sản phẩm',
      dataIndex: 'tProducts'
    },
    {
      key: '_tValue',
      title: 'Điều chỉnh',
      render: record => <span>{record.tValue ? numberWithCommas(record.tValue): '--'}</span>
    },
    {
      key: '_action',
      title: 'Xem KQKK',
      render: (record) => (
        <span>
          {
            record.isActive
            ?
            <Button type="primary" size="small" onClick={openActiveInv}>Tiếp tục</Button>
            :
            <Button type="link" onClick={() => onClick(record._id)}>Xem kết quả</Button>
          }
        </span>
      )
    }
  ]
  return (
    <Table
      loading={loading}
      dataSource={data.getInv}
      columns={columns}
      pagination={{
        pageSize: 20,
        current: page,
        onChange: (page) => setPage(page)
      }}
    />
  )
}

export default InventoriesList
