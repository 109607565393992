import React from 'react'
import {
  Table,
  Form,
  InputNumber,
  Space,
  Button,
  Input
} from 'antd'
import {
  useQuery
} from '@apollo/client'
import {
  CheckSquareOutlined, CloseOutlined
} from '@ant-design/icons'
import {
  useHotkeys
} from 'react-hotkeys-hook'
import {
  GET_CAT_QUERY
} from '../../../../Med/Categories'
import {
  numberWithCommas
} from '../../../../../utils/Match'
import {
  CatSearchDropdown,
} from '../../ProductsList'
import './Plist.css'
const InvPrdsList = ({
  loadingInv,
  inventory,
  updateActualRec,
  updateFilter,
  InvData,
  filter
}) => {
  // const limit = 50
  const searchInputBox = React.useRef(null)
  const [form] = Form.useForm()
  // const [page, setPage] = React.useState(1)
  const [searchNameBox, toggleSearchNameBox] = React.useState(false)
  const [updating, toggleupdating] = React.useState(false)
  const {data: catData = { getCategories: [] } } = useQuery(GET_CAT_QUERY)
  const [currentRec, setCurrentRec]= React.useState(null)
  const update = async () => {
    toggleupdating(true)
    const { actual } = form.getFieldsValue()
    const { _id, tSold, oSold, oStock, stock, tPurchased } = currentRec
    const data = {
      actual,
      product: _id,
      inventory,
      oStock,
      tSold,
      oSold,
      stock,
      tPurchased: tPurchased
    }
    await updateActualRec(data)
    setCurrentRec(null)
    form.resetFields()
    toggleupdating(false)
  }
  const columns = [
    {
      key: '_code',
      dataIndex: ['product', 'code'],
      title: 'Mã SP',
      fixed: 'left',
      width: 120
    },
    {
      key: '_name',
      dataIndex: ['product', 'name'],
      title: searchNameBox ? <Input ref={searchInputBox} suffix={<CloseOutlined onClick={() => { updateFilter('name', ''); toggleSearchNameBox(false)}} />} onPressEnter={(e) => updateFilter('name', e.target.value)} />: 'Tên SP (Ctrl+F)',
      fixed: 'left',
      width: 300,
      //title: filter.name ? (<Space><span>Product</span><Button type="link" onClick={() => updateFilter('name', null)}>Xóa</Button></Space>) : <span>Sản phẩm</span>,
      // filterDropdown: <SearchFilter name="name" updateSearch={updateFilter} />
    },
    {
      key: '_unt',
      dataIndex: ['product', 'unit'],
      title: 'Đơn vị'
    },
    {
      key: '_cat',
      dataIndex: ['product', 'category', 'name'],
      // title: 'Category',
      width: 200,
      title: filter.category ? (<Space><span>Category</span><Button type="link" onClick={() => updateFilter('category', null)}>Xóa</Button></Space>) : <span>Danh mục</span>,
      filterDropdown: <CatSearchDropdown allowClear={true} name="category" updateSearch={updateFilter} categories={catData.getCategories} />
    },
    {
      key: '_sPrice',
      title: 'Giá bán',
      dataIndex: 'sellPrice',
      render: text => {
        if (text){
          return (
            <span>{numberWithCommas(text)}</span>
          )
        }
        return <span>NA</span>
      }
    },
    {
      key: '_oStk',
      dataIndex: 'oStock',
      title: 'Số đầu ky',
      width: 120
    },
    {
      key: '_tPur',
      dataIndex: 'tPurchased',
      title: 'Đã nhập',
      width: 120
    },
    {
      key: '_tSold',
      dataIndex: 'tSold',
      title: '(NV)Đã bán',
      width: 120
    },
    {
      key: '_oSold',
      dataIndex: 'oSold',
      title: 'Bán Khác',
      width: 120
    },
    {
      key: '_stk',
      dataIndex: 'stock',
      title: 'Cuối kỳ (hiện tại)',
      width: 120
    },
    {
      key: '_act',
      title: 'Thực tế',
      width: 120,
      editable: true,
      dataIndex: 'actual'
    },
    {
      key: '_adj',
      width: 120,
      title: 'Điều chỉnh',
      render: record => <span className={(record.actual - record.stock) !== 0 ? 'uncounted' : ''}>{record.actual - record.stock}</span>
    },
    {
      width: 120,
      key: '_ttal',
      title: 'Tổng(VND)',
      render: (record) => <span>{numberWithCommas(record.sellPrice * (record.actual - record.stock))}</span>
    },
    {
      key: '_count',
      title: 'Đã đếm?',
      fixed: 'right',
      render: (record) => (
        <spam>
          {record.isCount ? <CheckSquareOutlined style={{ color: 'green', fontSize: '24px'}} /> : null}
        </spam>
      )
    }
  ].map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: (record, idx) => ({
          // setCurrentRow,
          rowNo: idx,
          loading: updating,
          record,
          editable: true,
          dataIndex: col.dataIndex,
          isEditing: currentRec && record._id === currentRec._id,
          onClick: () => {
            form.setFieldsValue({ ...record})
            // console.log(idx)
            // setCurrentRow(idx)
            setCurrentRec(record)
            // console.log(currentRow)
          },
          update: () => update(record)
        })
      }
    }
    return col
  })
  
  useHotkeys('ctrl+f', e => {
    e.preventDefault()
    toggleSearchNameBox(true)
    searchInputBox.current.focus()
  })

  return (
    <Form form={form}>
      <Table
        loading={loadingInv}
        scroll={{ x: 1800, y: 500 }}
        columns={columns}
        dataSource={InvData.oInvPrds.products}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        pagination={false}
        // pagination={{
        //   position:"bottomCenter",
        //   current: page,
        //   pageSize: limit,
        //   onChange: page => setPage(page),
        //   total: InvData.oInvPrds.total
        // }}
      />
    </Form>
  )
}
export default InvPrdsList

const EditableCell = ({
  rowNo,
  loading,
  record,
  dataIndex,
  editable,
  isEditing,
  children,
  onClick,
  update,
  // setCurrentRow,
  ...rest
}) => {
  return (
    <td {...rest}>
      {editable && isEditing ? (
          <Form.Item name="actual">
            <InputNumber
              onPressEnter={update}
              disabled={loading && isEditing}
            />
          </Form.Item>
        )
        :
        (editable ? (
          <span className='ediablePointer' onClick={() => { onClick(); }}>
            <strong className={record.isCount ? 'counted' : 'uncounted'}>
              {children}
            </strong>
          </span>
        )
        :
        children
        )
      }
    </td>
  )
}