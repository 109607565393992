import React, { useContext, createContext, useState, useEffect } from 'react';

const authContext = createContext();

const useProvideAuth = () => {
  const [token, setToken] = useState()
  const [user, setUser] = useState({});
  
  const logOut = () => {
    setUser(null)
    console.log('set the token to null')
    localStorage.setItem('ACCESS_TOKEN',null);

    setToken('notoken');
  }
  const logIn = (token) => {
    localStorage.setItem('ACCESS_TOKEN', token);
  }
  const getToken = () => localStorage.getItem('ACCESS_TOKEN');
  const getUser = () => user
  return {
    setToken,
    logIn,
    logOut,
    getToken,
    token,
    user,
    setUser,
    getUser
  };
}

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  const token = localStorage.getItem('ACCESS_TOKEN') || null;
  useEffect(() => {
    if(token) {
      auth.setToken(token)
    }
  }, [auth, token])
  return (
    <authContext.Provider value={{...auth, token}}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => useContext(authContext);