import React from 'react'
import {
  Form,
  Input,
  Button
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
// import { useHotkeys } from 'react-hotkeys-hook';


const FilterDropdown = ({
  fieldName,
  updateSearch
}) => {
  const [form] = Form.useForm()
  const update = () => {
    const data = form.getFieldsValue()
    updateSearch(fieldName, data[fieldName])
    form.resetFields()
  }
  return (
    <div style={{ padding: '1em', background: '#ccc' }}>
      <Form layout="inline" form={form}>
        <Form.Item name={fieldName} label={fieldName}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={update}>OK</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export const SearchByName = ({
  updateSearch,
  close,
  name,
  nameRef,
  search,
}) => {
  const [form] = Form.useForm()
  const setSearch = () => {
    updateSearch(name, form.getFieldValue(name))
  }
  form.setFieldsValue({ ...search })
  return (
    <div style={{ padding: '1em', background: '#ffe7ba' }}>
      <Form form={form} layout="vertical">
        <Form.Item style={{ marginBottom: 0 }}  name={name}>
          <Input ref={nameRef} onPressEnter={() => setSearch()} suffix={<CloseOutlined onClick={() => close()} />} />
        </Form.Item>
      </Form>
    </div>
  )
}

export default FilterDropdown
