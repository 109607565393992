import React from 'react'
import {
  Row,
  Col
} from 'antd'
import MonthlyChart from './MonthChart'
import BestIntPrds from './BestIntPrds'

const MonthlyReport = ({
  byMonth,
  mostIP,
  agByMonth
}) => {
  return (
    <Row gutter={12}>
      <Col xs={24} lg={8} style={{ height: '400px' }}>
        <h3>So với tháng trước</h3>
        <MonthlyChart byMonth={byMonth} xKey="shop" />
      </Col>
      <Col xs={24} lg={8} style={{ height: '400px' }}>
        <h3>Top 5 lãi nhất</h3>
        <BestIntPrds mostIP={mostIP} />
      </Col>
      <Col xs={24} lg={8} style={{ height: '400px' }}>
        <h3>So sánh nhân viên</h3>
        <MonthlyChart byMonth={agByMonth} xKey="agent" />
      </Col>
    </Row>
  )
}

export default MonthlyReport
