import React from 'react';
import {
  Row,
  Col,
} from 'antd';
// import ChooseType from './ChooseType';
import RegisterForm from './RegisterForm';

const Register = () => {
  return (
    <Row gutter={[5,5]} justify="center" style={{ marginTop: '5em'}}>
      <Col lg={12} sm={12} xs={24}>
        <div style={{ marginTop: '3em', padding: '1em' }}>
          <RegisterForm />
        </div>
      </Col>
    </Row>
  );
}

export default Register;