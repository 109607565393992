import React from 'react'
import './LoadingScreen.css'
import BarLoader from "react-spinners/BarLoader"
import { css } from "@emotion/react"
import { InfoCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'

const override = css`
  display: block;
  margin: 0 auto;
  /* border-color: red; */
`
const LoadingScreen = ({ text }) => (
  <div className="loadingContainer">
    {/* <p>
      <InfoCircleTwoTone style={{ fontSize: 40}} />
    </p> */}
    <p>
      <BarLoader css={override} loading size={10} color="#13C2C2" />
    </p>
    <p>
      <Space>

      <InfoCircleOutlined />
      {text || 'Đang tải dữ liệu, xin vui lòng đợi'}
      </Space>
    </p>
  </div>
)

export default LoadingScreen