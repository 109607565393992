import React from 'react'
import {
  Alert,
  Divider,
  // Space
} from 'antd'

const ProductDetail = ({ product }) => {
  if (!product) {
    return (
      <Alert
        message="Thông tin sản phẩm"
        type="info"
        showIcon
        description={`Chọn sản phẩm muốn bán để xem thông tin ở đây`}
      />
    )
  }
  return (
    <Alert
      message={product.product.name}
      type="success"
      showIcon
      description={(
        <div>
          <Divider />
          <p>Tồn kho: {product.stock}</p>
          <p>Gía vốn: {product.avgPrice}</p>
          <p>Gía bán: {product.sellPrice}</p>
        </div>
      )}
    />
  )
}
export default ProductDetail
