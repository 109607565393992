import React from 'react'
import {
  Descriptions
} from 'antd'

const CurrentProduct = ({ currentPrd }) => {
  if (currentPrd) {
    const { product } = currentPrd
    return (
      <div>
        <Descriptions
          layout="horizol"
          column={1}
        >
          <Descriptions.Item label="Tên">
            <strong>
              {product.name}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Đơn vị">
            {product.unit}
          </Descriptions.Item>
          <Descriptions.Item label="Tồn kho">
            <strong>{currentPrd.stock}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Nhà sản xuất">
            {product.manufacture}
          </Descriptions.Item>
          <Descriptions.Item label="Xuất xứ">
            {product.country}
          </Descriptions.Item>
          <Descriptions.Item label="Vị trí">
            {product.position}
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }
  return null;
}
export default CurrentProduct