import React from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  message,
} from 'antd';
import { gql, useMutation } from '@apollo/client';

const CREATE_CAT_MUT = gql`
  mutation createCat($name: String!, $element: [String]) {
    createCategory(name: $name, element: $element) {
      _id
      name
      element
    }
  }
`;

const AddCatModal = ({
  visible,
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [createCat, { loading }] = useMutation(CREATE_CAT_MUT);
  // const element = [];
  const createCategory = async () => {
    const data = form.getFieldsValue();
    // console.log(data)
    await createCat({ variables: { ...data }})
      .then(res => {
        console.log(res);
        onOk();
      })
      .catch(e => {
        message.error('cannot create new category');
      });
  }
  return (
    <Modal
      title="Thêm danh mục mới"
      visible={visible}
      onOk={createCategory}
      onCancel={onCancel}
      okButtonProps={{ loading }}
    >
      <Form form={form} {...{wrapperCol: { span: 18 }, labelCol: { span: 6 }}}>
        <Form.Item name="name" label="Tên danh mục">
          <Input />
        </Form.Item>
        <Form.Item label="Thành phần" name="element">
          <Select mode="tags" onChange={value => console.log(value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default AddCatModal;
