import React from 'react'
import {
  // Button,
  Row,
  Col,
  PageHeader
} from 'antd'
import {
  gql,
  // useQuery,
  useMutation
} from '@apollo/client'
import _ from 'lodash'
// import LoadingScreen from '../../utils/LoadingScreen'
import UnitSettings from './UnitSettings'
import CategoriesSettings from './CatSettings'
import PageSettings from './PageSettings'
import {
  usePageTitle,
  GET_CATS,
  LOAD_SETTINGS
} from '../../utils/NameContext'

const UPDATE_SETTINGS = gql`
  mutation updateSettings($settings: SettingsInput) {
    updateSettings(settings: $settings) {
      units
      categories
      productsPerPage
      owner
      created
      changed
    }
  }
`
const CREATE_CAT = gql`
  mutation createCategory($name: String!, $element: [String]) {
    createCategory(name: $name, element: $element) {
      _id
      name
      element
    }
  }
`


const Settings = () => {
  const { state } = usePageTitle()
  // const {loading, data: settings = { loadSettings:{ units: [], categories: [], productsPerPage: 100 } }, error } = useQuery(LOAD_SETTINGS)
  const [updateSettings] = useMutation(UPDATE_SETTINGS)
  const [createCat] = useMutation(CREATE_CAT)

  const createCategory = async (name) => {
    await createCat({
      variables: {
        name
      },
      update: (cache, { data: { createCategory }}) => {
        const oldData = cache.readQuery({
          query: GET_CATS,
        })
        cache.writeQuery({
          query: GET_CATS,
          data: {
            ...oldData,
            getCategories: [createCategory].concat(oldData.getCategories)
          }
        })
      }
    })
  }

  const update = async (key, value) => {
    const oldSettings = _.pick(state.settings, ['units', 'categories', 'productsPerPage'])
    oldSettings[key] = value
    await updateSettings({
      variables: {
        settings: oldSettings
      },
      update: (cache, { data: { updateSettings } }) => {
        const od = cache.readQuery({
          query: LOAD_SETTINGS
        })
        console.log(od)
        cache.writeQuery({
          query: LOAD_SETTINGS,
          data: {
              ...od,
              loadSettings: updateSettings
          }
        })
      }
    })
  }
  const updateUnits = async units => {
    await update('units', units)
  }
  const updatePage = async ppp => {
    await update('productsPerPage', ppp)
  }
  return (
    <div>
      <PageHeader title="Cài đặt" />
      <Row justify="center" gutter={24}>
        <Col xs={12} sm={8}>
          <UnitSettings
            units={state.settings.units}
            updateUnits={updateUnits}
          />
        </Col>
        <Col xs={12} sm={8}>
          <CategoriesSettings
            categories={state.categories}
            createCategory={createCategory}
          />
        </Col>
        <Col xs={12} sm={8}>
          <PageSettings
            productsPerPage={state.settings.productsPerPage}
            updatePage={updatePage}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Settings
