import React from 'react';
import {
  List,
  Menu,
  Dropdown,
  Avatar
} from 'antd';
import {
  Link
} from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons';

const AgentsList = ({
  agents,
  shops,
  assignAgent
}) => {
  return (
    <List>
      {agents.map(agent => (
        <List.Item
          key={agent._id}
          extra={
            <Dropdown.Button overlay={<ShopsDropdown shops={shops} assignAgent={assignAgent} agent={agent._id} />}>
              Phân quyền bán hàng
            </Dropdown.Button>
          }
        >
          <List.Item.Meta
            title={<Link to={`/agents/${agent._id}`}>{agent.name}</Link>}
            avatar={<Avatar src={agent.profile.avatar} />}
            description={agent.email}
          />
        </List.Item>
      ))}
    </List>
  )
}
export default AgentsList

const ShopsDropdown = ({ shops, assignAgent, agent }) => {
  const handleClick = e => {
    // console.log('menu was click ', e.key, 'agent', agent);
    assignAgent(agent, e.key)
  }
  return (
    <Menu onClick={handleClick}>
      {shops.map(shop => (
        <Menu.Item key={shop._id}>{shop.name}</Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item icon={<CloseOutlined />}>
        Vô hiệu tài khoản
      </Menu.Item>
    </Menu>
  );
}