import React from 'react'
import {
  Table,
  Button,
  Form,
  InputNumber,
  // Select,
  Input,
  Popconfirm,
  // Form
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined, SaveOutlined
} from '@ant-design/icons'
import './tableStyle.css'
import {
  Parser
} from 'hot-formula-parser'
// import {
//   // gql,
//   useLazyQuery,
//   // useQuery
// } from '@apollo/client'
// import {
//   SEARCH_PRODUCTS
// } from '../../PurchaseDrawer/ProductsList'
import {
  numberWithCommas
} from '../../../../../../utils/Match'


// const { Option } = Select

const ShoppingCart = ({
  data,
  shop,
  addProd,
  removePrd
}) => {
  // basic stuffs
  const parser = new Parser()
  const [form] = Form.useForm()
  const [currentRecID, setCurrentRecID] = React.useState('first')
  const [selectedPrd, setSelectedPrd] = React.useState(null)
  const [currentRemoving, setCurrentRemoving] = React.useState(null)
  const [currentSaving, setCurrentSaving] = React.useState(null)

  // user click on edit button
  const startEdit = (record) => {
    console.log(record)
    form.setFieldsValue({ ...record })
    setCurrentRecID(record._id)
    setSelectedPrd(record.product)
  }


  // on Save button clicked
  const add = async (data) => {
    setCurrentSaving(data.product)
    await addProd(data)
    form.resetFields()
    setCurrentSaving(null)
  }

  const remove = async (product) => {
    setCurrentRemoving(product)
    await removePrd(product)
    setCurrentRemoving(null)
  }
  // columns of the table.
  const columns = [
    {
      key: '_idx',
      render: (_, __, idx) => <span>{idx + 1}</span>,
      title: 'STT'
    },
    {
      key: '_name',
      dataIndex: 'name',
      title: 'Tên',
      editable: true,
      editableInput: 'SELECT'
    },
    {
      key: '_bPrice',
      title: 'Giá Nhập',
      dataIndex: 'buyPrice',
      editable: true,
      // editableInput: 'INPUTNUMBER'
    },
    {
      key: '_qty',
      title: 'Số lượng',
      dataIndex: 'quantity',
      editable: true,
      editableInput: 'INPUTNUMBER',
      onChange: (val) => {
        console.log(val)
        const { buyPrice } = form.getFieldsValue()
        form.setFields([
          {
            name: 'total',
            value: buyPrice * val
          }
        ])
      }
    },
    {
      key: '_unt',
      title: 'Đơn vị',
      dataIndex: 'unit',
      editable: true,
      disabled: true
    },
    {
      key: '_sPrc',
      title: 'Giá bán',
      dataIndex: 'sellPrice',
      editableInput: 'INPUTNUMBER',
      editable: true,
      disabled: true,
    },
    {
      key: '_ttal',
      title: 'Tổng',
      dataIndex: 'total',
      blank: true,
      editable: true,
      editableInput: 'INPUTNUMBER',
      onChange: val => {
        console.log(val)
        const { quantity } = form.getFieldsValue()
        form.setFields([
          {
            name: 'buyPrice',
            value: Math.round(val/quantity)
          }
        ])
      }
    },
    {
      key: '_acts',
      title: 'F',
      render: (record) => {
        if (record._id === currentRecID) {
          return <Button loading={selectedPrd !==null && selectedPrd  === currentSaving && currentSaving !== null} onClick={() => add({ ...form.getFieldsValue(), product: selectedPrd })} type="link" icon={<SaveOutlined />} />
        }
        return (
          <span>
            <Button onClick={() => startEdit(record)} type="link" icon={<EditOutlined />} />
            <Popconfirm
              title="Có chắc xóa không?"
              onCancel={() => null}
              // onConfirm={() => remove(record.product)}
              onConfirm={() => remove(record.product)}
            >
              <Button loading={record.product !== null && record.product === currentRemoving} icon={<DeleteOutlined />} danger type="link" />
            </Popconfirm>
          </span>
        )
      }
    }
    // {
    //   key: '_lck',
    //   title: 'Check',
    //   render: () => (<LockOutlined />)
    // }
  ]

  // mixed columns, with some new values added to the column for editing purpose.
  const newCols = columns.map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: record => ({
          ...record,
          editableInput: col.editableInput,
          editing: currentRecID === record._id,
          editable: record.product && col.editableInput === 'SELECT' ? false: true,
          dataIndex: col.dataIndex,
          // form,
          comp: () => {
            if (col.editableInput === 'INPUTNUMBER') {
              return <InputNumber onChange={col.onChange} disabled={col.disabled} /> 
            }
            return (
              <Input
                onPressEnter={e => {
                  const { result, error } = parser.parse(e.target.value)
                  // console.log(result)
                  // console.log(col.dataIndex)

                  if (error) return
                  form.setFields([
                    {
                      name: col.dataIndex,
                      value: Math.round(result)
                    },
                    {
                      name: 'total',
                      value: Math.round(result * form.getFieldValue('quantity'))
                    }
                  ])
                }}
                style={{ maxWidth: '100px'}}
                disabled={col.disabled}
              />
            )
          }
        })
      }
    }
    return col
  })
  // console.log(data)
  return (
    <Form form={form}>
      <Table
        pagination={false}
        scroll={{ y: 450 }}
        rowKey={rec => rec._id}
        rowClassName={(record) => { return record._id === currentRecID ? 'activeRow' : 'inactiveRow' }}
        columns={newCols}
        components={{
          body: {
            cell: EditableCell
          }
        }} 
        dataSource={data}
      />
    </Form>
  )
}
const EditableCell = ({
  // disabled,
  dataIndex,
  editable,
  editing,
  children,
  editableInput,
  // form,
  comp,
  ...restProps
}) => {
  const content = parseFloat(children) ? numberWithCommas(parseFloat(children)) : children
  return (
    <td {...restProps} className={editing ? 'activeCLS' : 'inactiveCLS'}> 
      {editing && editable ? (
        <Form.Item name={dataIndex}>
          {comp()}
        </Form.Item>
      ) : (
        <span>{content}</span>
      )}
    </td>
  )
}
export default ShoppingCart
