import React from 'react'
import {
  Button,
  Card,
  // Input,
  // Tag,
  Form,
  InputNumber,
  // List
} from 'antd'
import { CheckOutlined, PlusOutlined } from '@ant-design/icons'

const PageSettings = ({
  productsPerPage,
  updatePage
}) => {
  const [form] = Form.useForm()
  form.setFieldsValue({ productsPerPage })
  const [adding, toggleAdding] = React.useState(false)

  const updateSetting = async () => {
    toggleAdding(true)
    const { productsPerPage } = form.getFieldsValue()
    if (productsPerPage && productsPerPage > 0) {
      // const newUnits = [category].concat(categories || [])
      await updatePage(productsPerPage)
    }
    form.resetFields()
    toggleAdding(false)
    return
  }

  return (
    <Card title="Phân trang" bordered>
      <div>
        <Form form={form}>
          <Form.Item name="productsPerPage" label="Số sản phẩm trên một trang">
            <InputNumber
              step={10}
              disabled={adding}
              onPressEnter={() => updateSetting()}
              suffix={<Button size="small" icon={<PlusOutlined onClick={() => updateSetting()} />}
              type="primary"
              loading={adding} />}
            />
          </Form.Item>
          <Form.Item label="Thông số hiện tại">
            <strong>{productsPerPage}</strong>
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" icon={<CheckOutlined />} onClick={() => updateSetting()} loading={adding}>Cập nhật thay đổi</Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  )
}

export default PageSettings
