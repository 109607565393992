import React from 'react';
import {
  Modal,
  Form,
  Select,
  message
} from 'antd';

const formLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}

const { Option } = Select;

const AssignModal = ({
  visible,
  onOk,
  onCancel,
  shop,
  agents,
  makeAssignment,
  loading,
}) => {
  const [form] = Form.useForm();

  const assignAgent = async () => {
    await makeAssignment(form.getFieldValue('agent'))
      .then(res => {
        message.info('Successfully assigned agent')
          .then(() => onOk())
          .catch(e => {
            console.log(e);
          })
      })
  }
  return (
    <Modal
      title={`Add new sale agent to: ${shop.name}`}
      visible={visible}
      onOk={assignAgent}
      onCancel={onCancel}
      okButtonProps={{
        loading
      }}
    >
      <Form form={form} {...formLayout}>
        {/* <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="email/phone" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="password" name="password">
          <Input.Password />
        </Form.Item>
        <Form.Item name="password_confirmation" label="Confirm Password">
          <Input.Password />
        </Form.Item> */}
        <Form.Item name="agent" label="Select Agent">
          <Select>
            {agents.map(agent => (
              <Option value={agent._id} key={agent._id}>{agent.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AssignModal;
