import React from 'react'
import {
  Select,
  Button,
  Table,
  Badge,
  Form,
  Input,
  DatePicker,
  PageHeader,
  Space,
  Row,
  Col
} from 'antd'
import {
  FilterOutlined
} from '@ant-design/icons'
import {
  gql,
  useLazyQuery
} from '@apollo/client'
import moment from 'moment'
import { showDate } from '../../Sale/SaleRecords/TodaySaleRecords'
import { numberWithCommas } from '../../../utils/Match'

const { RangePicker } = DatePicker;
const { Option } = Select

const LOAD_AGENT_SALE_RECS = gql`
  query loadAgentSaleRecords($filter: OwnerSaleQueryInput) {
  loadAgentSaleRecords(filter:$filter) {
    sales {
      _id
      shop {
        name
      }
      products {
        product {
          name
          unit
        }
        avgPrice
        quantity
        sellPrice
      }
    	note
    	created
    }
    total {
      totalProducts
      totalValue
      totalOrders
    }
    shops {
      _id
      name
    }
  }
}
`
const AgentSaleRecords = ({
  agent
}) => {
  const [searchFilter, setFilter] = React.useState({
    product: null,
    shop: null
  })
  const [limit, setLimit] = React.useState(50)
  // const limit =1
  const [currentPage, setCurrentPage] = React.useState(1)
  const [range, setRange] = React.useState([moment(), moment()])
  const [loadSaleRecords, { loading: loadingRecords, data: salesResult }] = useLazyQuery(
    LOAD_AGENT_SALE_RECS,
    {
      variables: {
        filter: {
          agent,
          startDate: range[0].format('DD/MM/YYYY'),
          endDate: range[1].format('DD/MM/YYYY'),
          limit,
          currentPage: currentPage - 1,
          ...searchFilter
        }
      },
      fetchPolicy: 'network-and-cache'
    }
  )
  React.useEffect(() => {
    const load = async () => {
      loadSaleRecords(
        {
          query: LOAD_AGENT_SALE_RECS,
          variables: {
            filter: {
              agent,
              startDate: range[0].format('DD/MM/YYYY'),
              endDate: range[1].format('DD/MM/YYYY'),
              limit,
              currentPage: currentPage - 1,
              ...searchFilter
            }
          }
        }
      )
    }
    load()
  }, [currentPage, limit, agent, range, searchFilter, loadSaleRecords])
  const changePage = (page, pageSize) => {
    console.log(page)
    setLimit(pageSize)
    setCurrentPage(page)
  }
  const changeRange = val => {
    if (val) {
      setRange([...val])
    }
  }
  const updateFilter = (name, value) => {
    const newFilt = {
      ...searchFilter
    }
    newFilt[name] = value
    setFilter(newFilt)
  }
  
  if (loadingRecords || !salesResult) {
    return <span>loading.....</span>
  }
  if( salesResult && salesResult.loadAgentSaleRecords) {
    const { sales, total, shops } = salesResult.loadAgentSaleRecords
    // console.log(total)
    const columns = [
    {
      key: 'time',
      title: 'Thời gian',
      render: record => <span>{ showDate(parseFloat(record.created)) }</span>
    },
    {
      key: '_prd',
      title: searchFilter.product ?
        (<Badge dot>
          <Space size={5}>
            <span>
              Sản phẩm
            </span>
            <Button danger type="text" size="small" onClick={() => updateFilter('product', null)}>Xóa</Button>
          </Space>
        </Badge>)
        :
        <span>Sản phẩm</span>,
      filterIcon: <FilterOutlined />,
      filterDropdown: (<ProductFilterDrop updateFilter={updateFilter} />),
      render: record => {
        const { products } = record
        return (
          <>
            {products.map(p => (
              <span key={p._id}>
                {`${p.product.name}(${p.quantity})`}
              </span>
            ))}
          </>
        )
      }
    },
    {
      key: '_shop',
      title: searchFilter.shop ?(<Badge dot>
          <Space size={5}>
            <span>
              Cửa hàng
            </span>
            <Button danger type="text" size="small" onClick={() => updateFilter('shop', null)}>Clear</Button>
          </Space>
        </Badge>)
        :
        <span>Cửa hàng</span>,
      dataIndex: ['shop', 'name'],
      filterIcon: <FilterOutlined />,
      filterDropdown: (<ShopFilterDrop shops={shops} updateFilter={updateFilter} />),
      // render: record => <span>record.shop.name</span>
    },
    {
      key: '_note',
      title: 'Ghi chú',
      dataIndex: 'note'
    },
    {
      key:'_total',
      title: 'Tổng',
      render: record => {
        let total = 0
        record.products.forEach(p => {
          total += p.quantity * p.sellPrice
        })
        return (<strong>{`${numberWithCommas(total)} VND`}</strong>)
      }
    },
  ]
    return (
      <div style={{ marginTop: '2em'}}>
        <PageHeader
          title="Lịch sử bán hàng"
          subTitle={(
            <Space size={12}>
              <span>Chọn khoảng thời gian</span>
              <RangePicker allowEmpty={false} value={range} onChange={changeRange} format="DD/MM/YYYY" />
              <Button onClick={() => setRange([moment(), moment()])}>Hôm nay</Button>
            </Space>
          )}
        />
        <Table
          pagination={{
            pageSize: limit,
            current: currentPage,
            total: total.totalOrders,
            showSizeChanger: true,
            onChange: changePage,
            // pageSizeOptions: [1, 2, 3, 4, 5]
          }}
          rowKey={(record) => record._id}
          columns={columns}
          dataSource={sales}
          expandable={{
            expandedRowRender: record => {
              const { products } = record
              const columns = [
                {
                  key: '_idx',
                  title: 'STT',
                  render: (_, __, index) => <span>{index + 1}</span>
                },
                {
                  key: '_prd',
                  title: 'Sản phẩm',
                  dataIndex: ['product','name'],
                  
                },
                {
                  key: '_quan',
                  title: 'Số lượng',
                  dataIndex: ['quantity']
                },
                {
                  key: '_unit',
                  title: 'Đơn vị',
                  dataIndex: ['product', 'unit']
                },
                {
                  key: '_avgPrc',
                  // dataIndex: 'avgPrice',
                  title: 'Giá TBGQ',
                  render: record => <span>{numberWithCommas(record.avgPrice)}</span>
                },
                {
                  key: '_prc',
                  title: 'Giá bán',
                  // dataIndex: 'sellPrice'
                  render: record => <span>{numberWithCommas(record.sellPrice)}</span>
                },
                {
                  key: '_total',
                  title: 'Tổng(VND)',
                  render: record => numberWithCommas(record.quantity * record.sellPrice)
                },
              ]
              return (
                <div className="expandedTableWrapper">
                  <Table
                    rowKey={record => record._id}
                    pagination={false}
                    dataSource={products}
                    columns={columns}
                  />
                </div>
              )
            }
          }}
          summary={pageData => {
            let pageTotal = 0
            pageData.forEach(sr => {
              sr.products.forEach(pr => {
                pageTotal += pr.quantity * pr.sellPrice
              })
              // total += sr.total
            })
            return (
            <Table.Summary.Row key="_sumar">
              <Table.Summary.Cell colSpan={3} key="_ttal">
                {`Trong khoảng thời gian này: ${total.totalProducts ? total.totalProducts : 0 } sản phẩm đã bán và thu về ${numberWithCommas(total.totalValue ? total.totalValue: 0)} VND`}
              </Table.Summary.Cell>
              <Table.Summary.Cell key="_grnd_title" colSpan={1}>Tổng tiền</Table.Summary.Cell>
              <Table.Summary.Cell key="_dt_gross"><strong>{`${numberWithCommas(pageTotal)} VNĐ`}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
            )
          }}
        />
      </div>
      )
  }
}
// @TODO: adding pagination and re-calculate total money of the period
export default AgentSaleRecords

const ProductFilterDrop = ({
  updateFilter
}) => {
  const name = 'product'
  const [form] = Form.useForm()
  const update = () => {
    const values = form.getFieldsValue()
    updateFilter(name, values[name])
  }
  return (
    <Form style={{ padding: '1em', minWidth: '500px'}} form={form}>
      <Row gutter={10}>
        <Col span={20}>
          <Form.Item name={name}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button onClick={update} size="small">Search</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

const ShopFilterDrop = ({
  updateFilter,
  shops
}) => {
  const name = 'shop';
  const [form] = Form.useForm()
  const update = () => {
    const values = form.getFieldsValue()
    updateFilter(name, values[name])
  }
  return (
    <div style={{minWidth: '500px'}}>
      <Form style={{ padding: '1em'}} form={form}>
        <Row gutter={10}>
          <Col span={20}>
            <Form.Item name={name}>
              <Select mode="multiple" allowClear>
                {shops.map(shop => (
                  <Option key={shop._id} value={shop._id}>{shop.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button onClick={update}>Tìm</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}