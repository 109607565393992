import React from 'react';
import {
  // useHistory,
  Switch,
  Route,
} from 'react-router-dom';
import SingleAgent from './SingleAgent'
import Agents from './Agents'

const AgentMgt = () => {
  return (
    <Switch>
      <Route path="/agents/:agent" exact component={SingleAgent} />
      <Route path="/agents" component={Agents} />
    </Switch>
  );
}
export default AgentMgt
