import React from 'react'
import ProductForm from './ProductForm'
import ShoppingCard from './ShoppingCard'

const RightCol = ({
  addProd,
  products,
  shop,
  purchase,
  selectedPrd,
  setSelectedPrd,
  removePrd,
}) => {
  return (
    <>
      <ProductForm
        addProd={addProd}
        shop={shop}
        purchase={purchase}
        selectedPrd={selectedPrd}
        setSelectedPrd={setSelectedPrd}
        />
      <ShoppingCard
      removePrd={removePrd}
        addProd={addProd}
        shop={shop}
        data={products}
      />
    </>
  )
}

export default RightCol
