import React from 'react'
import {
  Drawer,
  Button,
  Space,
  Modal,
  Form,
  Row,
  Col,
  message,
  // Alert
} from 'antd'
import Header from './LeftCol/PurchasetHeader'
import SelectedPrd from './LeftCol/SelectedPrd'
// import ProductsList from './ProductsList'
import {
  gql,
  useLazyQuery,
  useMutation,
  // useQuery,
  // useLazyQuery
} from '@apollo/client'
import LoadingScreen from '../../../../../utils/LoadingScreen'
import RightCol from './RightCol/RightCol'

export const GET_OR_CREATE_PC = gql`
  query findOrCreatePC($shop: String!, $purchase: String) {
    findOrCreatePC (shop: $shop, purchase: $purchase) {
      purchase {
        _id
        vendor
        trace
        createdBy
        invoice
        created
      }
      products {
        _id
        quantity
        buyPrice
        sellPrice
        product
        name
        unit
        code
        total
      }
    }
  }
`
const UPDATE_BASIC = gql`
  mutation updateB ($shop: String!, $purchase: String!, $data: BasicPcInput) {
    updatePCBasicInfo (shop: $shop, purchase: $purchase, data: $data)
  }
`
const LOAD_SINGLE_PC = gql`
  query loadSinglePC($shop: String!, $purchase: String!) {
    loadSinglePC(shop: $shop, purchase: $purchase) {
      purchase {
        _id
        vendor
        trace
        createdBy
        invoice
        created
        note
      }
      products {
        _id
        quantity
        buyPrice
        sellPrice
        product
        name
        unit
        code
        total
      }
    }
  }
`

const REMOVE_TEMP_PC = gql`
  mutation removePurchase($shop: String!, $purchase: String!) {
    removePurchase(shop: $shop, purchase: $purchase)
  }
`
const REMOVE_SINGLE_PRD = gql`
  mutation removePrdInPc($shop: String!, $purchase: String!, $product: String!) {
    removePrdInPc(shop: $shop, purchase: $purchase, product: $product) {
      purchase {
        _id
        vendor
        trace
        createdBy
        invoice
        created
      }
      products {
        _id
        quantity
        buyPrice
        sellPrice
        product
        name
        unit
        code
        total
      }
    }
  }
`
const UPDATE_PC =gql`
  mutation updatePC($shop: String!, $data: PCProduct $purchase: String!) {
    updatePC(shop: $shop, data: $data, purchase: $purchase) {
      purchase {
        _id
        vendor
        trace
        createdBy
        invoice
        created
      }
      products {
        _id
        quantity
        buyPrice
        sellPrice
        product
        name
        unit
        code
        total
      }
    }
  }
`

const calculateTotal = dataSet => {
  let total = 0;
  dataSet.forEach(data => {
    total += data.quantity * data.buyPrice
  })
  return total
}

const PurchaseDrawer = ({
  purchase,
  visible,
  onClose,
  shop,
}) => {
  /* Declaration */
  const [purchaseHeaderForm] = Form.useForm()
  const [loadPCData, { loading: loadingPCData, data: pcDataDetail = {} }] = useLazyQuery(LOAD_SINGLE_PC, { fetchPolicy: 'network-only'}, { variables: { shop, purchase }})
  const [updatePcBasic, { loading: updating }] = useMutation(UPDATE_BASIC)
  const [remove] = useMutation(REMOVE_SINGLE_PRD)
  const [updatePrd] = useMutation(UPDATE_PC)
  const [removePC] = useMutation(REMOVE_TEMP_PC)
  const [selectedPrd, setSelectedPrd] = React.useState({})  
  React.useEffect(() => {
    const lpc = async () => {
      console.log('This run from the drawer itself')
      await loadPCData({
        variables: {
          shop,
          purchase
        }
      })
    }
    if (purchase) {
      lpc()
    }
  }, [shop, purchase, loadPCData])

  // const load = async (visible) => {
  //   console.log('visible:', visible)
  //   console.log('purchase:', purchase)
  //   if (visible && purchase) {
  //     await loadPCData({
  //       variables: {
  //         shop,
  //         purchase
  //       }
  //     })
  //   } else {
  //     return
  //   }
  // }

  const updateBasicInfo = async (purchase, data) => {
    if (!purchase) {
      message.error('Không thể cập nhật thông tin đơn hàng')
      return
    }
    try {
      await updatePcBasic({
        variables: {
          shop,
          purchase,
          data,
        },
      })
    } catch(e) {
      message.error('Không thể cập nhật thông tin')
    }
    return
    // purchaseHeaderForm.setFieldsValue(pcDataDetail.loadSinglePC.purchase)
  } 

  const confirmExit = () => {
    Modal.confirm({
      title: 'Xóa đơn nhập hàng',
      content: 'Toàn bộ danh sách hàng hóa trong đơn sẽ bị xóa, bạn có chắc muốn xóa không',
      onCancel: close => close(),
      onOk: async () => {
        try {
          await removePC({
            variables: {
              shop,
              purchase
            }
          })
          onClose()
        } catch(e) {
          message.error('Có lỗi trong quá trình xử lý, xin vui lòng thử lại sau')
        }
      }
    })
  }

   const addProd = async (prdData) => {
    const { product, buyPrice, quantity } = prdData
    if (!product || !quantity) {
      message.error('Không thêm được sản phẩm, thiếu thông tin')
      return;
    }
    const data = {
      quantity: parseInt(quantity),
      product,
      buyPrice: parseFloat(buyPrice)
    }
    try {
      await updatePrd({
        variables: {
          shop,
          purchase,
          data
        },
        update: (cache, { data: { updatePC }}) => {
          cache.writeQuery({
            query: LOAD_SINGLE_PC,
            variables: {
              shop,
              purchase
            },
            data: {
              loadSinglePC: {
                purchase: updatePC.purchase,
                products: updatePC.products
              }
            }
          })
        }
      })
    } catch(e) {
      message.error('Không thể thêm sản phẩm vào đơn hàng')
    }
  }

  const removePrd = async (product) => {
    if (!product) {
      return
    }
    await remove({
      variables: {
        shop,
        purchase,
        product
      },
      update: (cache, { data: { removePrdInPc }}) => {
        cache.writeQuery({
          query: LOAD_SINGLE_PC,
          variables: {
            shop,
            purchase
          },
          data: {
            loadSinglePC: {
              purchase: removePrdInPc.purchase,
              products: removePrdInPc.products
            }
          }
        })
      }
    })
  }

  // const 
  if (loadingPCData) {
    return <LoadingScreen />
  }
  // console.log(pcDataDetail)
  if (pcDataDetail) {
    return (
      <Drawer
        // afterVisibleChange={visible => load(visible)}
        keyboard={false}
        visible={visible}
        forceRender
        // destroyOnClose={true}
        width={'100%'}
        title="Nhập hàng"
        closeIcon={null}
        // destroyOnClose
        footer={[
          <Space key="_footer" style={{ float: 'right' }}>
            <Button danger type="dashed" onClick={() => confirmExit()} disabled={!pcDataDetail}>Hủy đơn</Button>
            <Button onClick={() => onClose()} type="primary">Đóng</Button>
          </Space>
        ]}
      >
        {pcDataDetail && pcDataDetail.loadSinglePC && 
          (<Row gutter={12}>
            <Col xs={24} sm={24} md={6}>
              <Space direction="vertical">
                  <Header
                    total={calculateTotal(pcDataDetail.loadSinglePC.products)}
                    loading={updating}
                    update={updateBasicInfo}
                    form={purchaseHeaderForm}
                    purchase={pcDataDetail.loadSinglePC.purchase}
                  />
                <SelectedPrd product={selectedPrd} />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <RightCol
                addProd={addProd}
                removePrd={removePrd}
                products={pcDataDetail.loadSinglePC.products}
                shop={shop}
                purchase={purchase}
                selectedPrd={selectedPrd}
                setSelectedPrd={setSelectedPrd}
              />
              {/* 
              <ProductsList
              selectedPrd={selectedPrd}
              setSelectedPrd={setSelectedPrd}
              shop={shop}
                cart={[]}
                setCart={() => ([])}
              />
               */}
            </Col>
          </Row>)
        }
      </Drawer>
    )
  }
}

export default PurchaseDrawer