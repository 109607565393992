import React from 'react';
import {
  Form,
  Button,
  Input,
  Typography,
  message,
  Alert,
} from 'antd';
import { useMutation, gql } from '@apollo/client';
// import GetMe from './GetMe';
import {
  useHistory,
  useLocation,
  Link,
} from 'react-router-dom';
import {
  useAuth,
} from '../utils/Context';

const { Title } = Typography;

const LOGIN_MUTATION = gql`
  mutation auth($email:String!, $password:String!) {
    login(email:$email, password:$password) {
      token
      user {
        name
        email
        role
        # profile {
        #   phones
        #   address {
        #     city
        #     dist
        #     detail
        #   }
        # }
      }
    }
  }
`;

// const GET_MY_INFO_QUERY = gql`
//   query getMe {
//     me {
//       name
//       email
//       profile {
//         phones
//         address {
//           city
//           dist
//           detail
//         }
//       }
//     }
//   }
// `;

const LoginForm = () => {
  const auth = useAuth()
  const [form] = Form.useForm();
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION);
  const history = useHistory();
  const location = useLocation();
  const localLogin = async () => {
    const { email, password } = form.getFieldsValue();
    await login({ variables: { email, password }})
      .then(res => {
        const { from } = location.state || { from : { pathname: '/' } };
        const { data: { login: {token} } } = res;
        // localStorage.setItem('ACCESS_TOKEN', token);
        auth.logIn(token)
        history.replace(from);
       })
       .catch(e => {
         console.log(e);
        message.error('Wrong user name and password')
       })
  }
  // if (error) {
  //   return <h1> there was error</h1>
  // }
  const itemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 22 }};
  return (
    <div>
      <Title level={1}>Đăng nhập</Title>
      {error && (
        <Alert message="Something went wrong, please check your email/phone or password" type="error" />
      )}
      <Form layout="vertical" form={form} {...itemLayout}>
        <Form.Item name="email" label="Email/SDT" rules={[{ required: true, message: 'Email/sdt bắt buộc phải có'}]}>
          <Input type="text" />
        </Form.Item>
        <Form.Item required label="Mật khẩu" name="password">
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            onClick={() => localLogin()}
            >
            Đăng nhập
          </Button>
        </Form.Item>
        <Form.Item>
          <Link to="/register">Đăng ký tài khoản</Link>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginForm;