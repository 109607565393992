import React from 'react'
import {
  Button,
  Collapse,
  Form,
  Input
} from 'antd'
import { CheckOutlined } from '@ant-design/icons'

const BatchSaleNote = ({
  sale,
  updateNote
}) => {
  const [form] = Form.useForm()
  const [updating, toggleUpdating] = React.useState(false)
  form.setFieldsValue({ ...sale })

  const update = async () => {
    toggleUpdating(true)
    const { note } = form.getFieldsValue()
    if (!note) return
    await updateNote(note)
    toggleUpdating(false)
  }
  return (
    <Collapse
      defaultActiveKey={['1']}
    >
      <Collapse.Panel key="1" header="Ghi chú">
        <Form form={form}>
          <Form.Item name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
        <Button loading={updating} icon={<CheckOutlined />} type="link" onClick={() => update()}>Cập nhật</Button>
      </Collapse.Panel>
    </Collapse>
  )
}
export default BatchSaleNote
