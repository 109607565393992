import React from 'react'
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  message,
  Input,
} from 'antd'

import {
  PlusOutlined
} from '@ant-design/icons'
import {
  gql,
  useLazyQuery,
  // useMutation
} from '@apollo/client'
import {
  Parser
} from 'hot-formula-parser'

const SEARCH_PRODUCTS = gql`
  query oSearchPrdByName($shop: String!, $keyword: String, $limit: Int) {
    oSearchPrdByName(shop: $shop, keyword: $keyword, limit: $limit) {
        _id
        name
        unit
        code
        sellPrice
        avgPrice
        stock
    }
  }
`
const { Option } = Select

const ProductForm = ({
  addProd,
  shop,
  purchase,
  selectedPrd,
  setSelectedPrd
}) => {
  const parser = new Parser()
  const limit = 30
  const searchRef = React.useRef(null)
  const addBtn = React.useRef(null)
  const [keyword, changeKeyword] = React.useState('')
  const [searchPrd, { loading: searching, data: searchResult = { oSearchPrdByName: [] }}] = useLazyQuery(SEARCH_PRODUCTS, {
    // fetchPolicy: 'network-only',
    variables: {
      shop,
      limit,
      keyword
    }
  })
  const [updating, toggleUpdating] = React.useState(false)
  // const [updatePrd, { loading: updating}] = useMutation(UPDATE_PC)
  const [form] = Form.useForm()
  React.useEffect(() => {
    const search = async () => {
      await searchPrd({
        query: SEARCH_PRODUCTS,
        variables: {
          keyword,
          limit
        }
      })
    }
    if (keyword && keyword.length >=2) search()
  }, [searchPrd, keyword])

  // user select a product from searched list
  const onProductSelect = (option, listedProducts) => {
    listedProducts.forEach(p => {
      if(option === p._id) {
        setSelectedPrd(p)
        return
      }
    })
    // console.log(prd)
  }

  const add = async () => {
    toggleUpdating(true)
    const { product, buyPrice, quantity } = form.getFieldsValue()
    if (!product || !quantity) {
      toggleUpdating(false)
      form.resetFields()
      message.error('Không thêm được sản phẩm, thiếu thông tin')
      return;
    }
    const data = {
      quantity: parseInt(quantity),
      product,
      buyPrice: parseFloat(buyPrice)
    }
    await addProd(data)
    searchRef.current.focus()
    form.resetFields()
    toggleUpdating(false)
  }

  const parseValue = (name, val) => {
    const { result, error } = parser.parse(val)
    if (error) {
      message.error('Công thức không hợp lệ')
    }
    const update = {}
    update[name] = result
    form.setFields([{
      name,
      value: result
    }])
  }
  // console.log(newPCData)
  return (
    <Form layout="vertical" key="_f" form={form}>
        <Row gutter={15} style={{ marginBottom: '1em'}}>
          <Col span={12}>
            <Form.Item label="Tên SP" name="product" labelCol={4} wrapperCol={8}>
              <Select
                showAction={["focus"]}
                autoFocus={true}
                ref={searchRef}
                showSearch
                onSearch={changeKeyword}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                  // console.log(option.toLocaleString())
                  return true
                }}
                loading={searching}
                onSelect={(option) => onProductSelect(option, searchResult.oSearchPrdByName)}
              >
                {searchResult.oSearchPrdByName.map(prd => (
                  <Option value={prd._id} key={prd._id}>{prd.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            {selectedPrd && selectedPrd.unit && (
              <Form.Item label="Đơn vị">
                <strong>{selectedPrd.unit}</strong>
              </Form.Item>
            )}
          </Col>
          <Col span={4}>
            <Form.Item name="buyPrice" label="Giá nhập">
              <Input
                onPressEnter={e => parseValue('buyPrice', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Số lượng" name="quantity">
              <Input
                onPressEnter={e => parseValue('quantity', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label={` `}>
              <Button loading={updating} ref={addBtn} type="primary" icon={<PlusOutlined />} onClick={() => add()} />
            </Form.Item>
          </Col>
        </Row>
    </Form>
  )
}
export default ProductForm
