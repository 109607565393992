import React from 'react';
import LoginForm from './LoginForm';
import {
  Row,
  Col,
} from 'antd';

const Login = () => {
  return (
    <Row gutter={[5,5]} justify="center" style={{ marginTop: '5em'}}>
      <Col xs={24} md={8} lg={6}>
        <LoginForm />
      </Col>
    </Row>
  );
}

export default Login;