import React from 'react';
import {
  Input,
  Button,
  Table,
  Space,
  Form,
  Select,
  Tooltip,
  message
} from 'antd';
import {
  gql,
  useQuery,
  useMutation,
  useLazyQuery
} from '@apollo/client';
import {
  GET_CAT_QUERY
} from '../Categories'
import {
  SearchOutlined,
  CloseSquareOutlined,
  SaveOutlined,
  CloseOutlined,
  EditOutlined
} from '@ant-design/icons'
import SearchNameDropdown from './SearchNameDropdown'
import SelectCatDropdown from './SelectCatDropdown'
import {
  usePageTitle
} from '../../../utils/NameContext'
import AddProductModal from './AddProductModal';
// import { GET_CAT_QUERY } from '../Categories';


const { Option } = Select

const PRODUCTS_QUERY = gql`
  query getPrds($limit: Int, $page: Int, $name: String, $unit: String, $category: [String], $code: String) {
    getProducts (page: $page, limit: $limit, name: $name, unit: $unit, category: $category, code: $code) {
      products {
        _id
        category {
          name
          _id
        }
        name
        unit
        code
        createdBy
      }
      total
    }
  }
`
const UPDATE_PRODUCT = gql`
  mutation updateSingle($product: String!, $code: String, $name: String, $unit: String, $category: String) {
    updateProduct(product: $product, code: $code, name: $name, unit: $unit, category: $category) {
      _id
      name
      unit
      category {
        name
        _id
      }
      code
    }
  }
`
const ADD_PRODUCT = gql`
  mutation addProd($args: ProductInput) {
    addProduct(args: $args) {
      category {
        name
      }
      name
      element
      vendor
      position
      barcode
      storageCondition
      unit
      code
      registerNumber
      doctorComission
      manufacture
      country
    }
  }
`

const ProductsList = ({
  addProductModal,
  toggleAddModal
}) => {
  const { state } = usePageTitle()
  const { settings: {productsPerPage: limit}} = state
  const [currentPage, setCurrentPage] = React.useState(1)
  const [searchParams, setSearchParams] = React.useState({
    name: '',
    unit: '',
    code: '',
    category: null
  })
  // const [addPrdModal, toggleAddPrdModal] = React.useState(false)
  const [form] = Form.useForm()

  const { data: catData = { getCategories: [] } } = useQuery(GET_CAT_QUERY)
  const [loadPrds, { loading, data, fetchMore }] = useLazyQuery(PRODUCTS_QUERY, { variables: { page: currentPage - 1, limit, ...searchParams }, fetchPolicy:"network-only" });
  const [
    updatePrd,
    {
      loading: upadating,
    }
  ] = useMutation(UPDATE_PRODUCT)
  const [addPrd, { loading: adding}] = useMutation(ADD_PRODUCT)
  const [editingKey, setEditingKey] = React.useState('')

  React.useEffect(() => {
    const load = async () => loadPrds()
    load()
  }, [currentPage, limit, searchParams, loadPrds])

  const isEditing = record => record.code === editingKey

  const edit = record => {
    // form.setFieldsValue({code: '', name: '', unit: '', ...record })
    // console.log(record)
    form.setFieldsValue({...record})
    setEditingKey(record.code)
  }

  const cancel = () => {
    form.resetFields()
    setEditingKey('')
  }
  
  const save = async (record) => {
    const formData = form.getFieldsValue()
    const data = {
      product: record._id,
      ...formData,
      category: formData.category.name
    }
    await updatePrd({ variables: {...data}})
    setEditingKey('')
  }

  const loadMore = async (page, pageSize) => {
    // setPageLimit(pageSize)
    await fetchMore({
      variables: { page: page - 1, limit: pageSize },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          getProducts: {
            products: fetchMoreResult.getProducts.products,
            total: prev.getProducts.total
          }
        }
      }
    })
    setCurrentPage(page)
  }

  const updateSearch = async (k, value) => {
    for (var key in searchParams) {
      if (key === k) searchParams[key] = value
    }
    // console.log(searchParams)
    setSearchParams(searchParams)
    await loadPrds({
      variables: {
        page: currentPage -1,
        limit,
        ...searchParams
      }
    })
  }

  const asyncAddProduct = async args => {
   try {
     await addPrd({ variables: {
          args: {
            ...args,
          }
        },
        update: (cache, result) => {
          const oldPrds = cache.readQuery(
            {
              query: PRODUCTS_QUERY,
              variables: {
                page: currentPage - 1,
                limit, ...searchParams
              }
            }
          )
          const newData = {
            ...oldPrds,
            getProducts: {
              ...oldPrds.getProducts,
              products: [result.data.addProduct, ...oldPrds.getProducts.products]
            }
          }
          cache.writeQuery({
            query: PRODUCTS_QUERY,
            data: newData
          })
        }
      })
    //  await loadPrds()
     toggleAddModal(false)
   } catch (e) {
    console.log(e)
     message.error('Không thể thêm sản phẩm mới')
   }
  }

  const columns = [
    {
      key: '_code',
      dataIndex: 'code',
      title: 'Mã SP',
      editable: true,
      inputType: 'text',
      name: 'code',
      filterIcon: searchParams.code ? <CloseSquareOutlined style={{ color: 'red' }} onClick={() => updateSearch('code', null)} /> : <SearchOutlined />,
      filterDropdown: <SearchNameDropdown updateSearch={updateSearch} name="code" />
    },
    {
      inputType: 'text',
      key: 'name',
      dataIndex: 'name',
      title: 'Tên SP',
      editable: true,
      name: 'name',
      filterIcon: searchParams.name ? <CloseSquareOutlined style={{ color: 'red' }} onClick={() => updateSearch('name', null)} /> : <SearchOutlined />,
      filterDropdown: <SearchNameDropdown updateSearch={updateSearch} name="name" />
    },
    {
      // inputType: 'text',
      title: 'Đơn vị',
      key: 'unit',
      dataIndex: 'unit',
      editable: true,
      inputType: 'select',
      name: 'unit',
      filterIcon: searchParams.unit ? <CloseSquareOutlined style={{ color: 'red' }} onClick={() => updateSearch('unit', null)} /> : <SearchOutlined />,
      filterDropdown: <SearchNameDropdown updateSearch={updateSearch} name="unit" />,
      options: state.settings.units.map(u => ({ _id: u, name: u }))
    },
    {
      title: 'Danh mục',
      key: '_cat',
      dataIndex: ['category', 'name'],
      inputType: 'select',
      editable: true,
      filterIcon: searchParams.category ? <CloseSquareOutlined style={{ color: 'red' }} onClick={() => updateSearch('category', null)} /> : <SearchOutlined />,
      filterDropdown: <SelectCatDropdown categories={catData.getCategories} updateSearch={updateSearch} name="category" />,
      name: 'category',
      options: catData.getCategories
      // render: record => {
      //   // console.log(record.category)
      //   return (<span>{record.category.name}</span>)
      // }
    },
    {
      key: '_actions',
      title: 'Chức năng',
      dataIndex: 'operation',
      name: 'action',
      render: (_, record) => {
        return isEditing(record) ? (
          <Space>
            <Tooltip title="Lưu thông tin đã sửa">
              <Button loading={upadating} type="link" onClick={() => save(record)} icon={<SaveOutlined />} />
            </Tooltip>
            <Tooltip title="Hủy thay đổi">
              <Button disabled={upadating} type="link" onClick={() => cancel()} icon={<CloseOutlined />} />
            </Tooltip>
          </Space>
        ) : (
          <Tooltip title="Sửa thông tin sản phẩm">
            <Button disabled={editingKey !== ''} type="link" onClick={() => edit(record)} icon={<EditOutlined />} />
          </Tooltip>
        )
      }
    }
  ];

  const mergeCols = columns.map(col => {
    if (!col.editable) return col
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: col.options,
        // categories: catData.getCategories,
        inputType: col.inputType,
      })

    }
  })

  
  if(!data) {
    return <h1>loading...</h1>
  }
  return (
    <div>
      <Form form={form}>
        <Table
          loading={loading}
          columns={mergeCols}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          dataSource={data.getProducts.products || []}
          pagination={{
            current: currentPage,
            total: data.getProducts.total,
            pageSize: limit,
            onChange: (page, pageSize) => loadMore(page, pageSize)
          }}
        />
      </Form>
      <AddProductModal
        visible={addProductModal}
        onCancel={() => toggleAddModal(false)}
        onOk={asyncAddProduct}
        categories={catData.getCategories || []}
        adding={adding}
        countUnits={state.settings.units}
      />
    </div>
  );
}
export default ProductsList;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  editable,
  children,
  options,
  ...restProps
}) => {
  // console.log(record[dataIndex])
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex}>
          {inputType === 'select' ?
            (<Select defaultValue={record.category._id}>
              {options.map(cat => (
                <Option key={cat._id} value={cat._id}>{cat.name}</Option>
              ))}
            </Select>)
            :
            <Input />
          }
        </Form.Item>
      ) : 
        children
    }
    </td>
  )
}