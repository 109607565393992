import React from 'react';
// import {
//   useMutation,
//   gql,
// } from '@apollo/client';
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  // ect...
} from 'antd';

// const countUnits = [
//   "Tuýp",
//   "Gói",
//   "Viên",
//   "Vỉ",
//   "Hộp",
//   "Lọ",
//   "Thùng",
//   "kiện",
//   "Chai",
//   "Ống",
//   "Túi",
//   "Cái",
//   "Miếng"
// ];

const AddProductModal = ({
  visible,
  onCancel,
  onOk,
  categories,
  adding,
  countUnits
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      width={1000}
      visible={visible}
      onOk={() => onOk(form.getFieldsValue())}
      onCancel={onCancel}
      title="Add new product"
      okButtonProps={{ loading: adding }}
    >
      <Form form={form} {...{labelCol: { span: 6 }, wrapperCol: { span: 18 }}}>
        <Row gutter={25}>
          <Col span={12}>
            <Form.Item name="name" label="Tên SP" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="category" label="Danh Mục (*)">
              <Select>
                {categories.map(cat => (
                  <Select.Option key={cat._id} value={cat._id}>{cat.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="unit" label="Đơn vị (*)">
              <Select>
                {countUnits.map(cu => (
                  <Select.Option key={cu} value={cu}>{cu}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="code" label="Mã SP (*)">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default AddProductModal;
