import React from 'react'
import {
  Table,
} from 'antd'
import {
  gql,
  useQuery
} from '@apollo/client'
import moment from 'moment'
import {
  numberWithCommas
} from '../../../utils/Match'

const PRD_PURCHASE_HIS = gql`
  query getHis($shop: String!, $product: String!) {
    oGetSingleProductPurchase(shop: $shop, product: $product) {
      _id
      vendor
      invoice
      trace
      created
      product {
        _id
        name
        unit
      }
      quantity
      buyPrice
    }
  }
`

const ProductBatches = ({ product, shop, productName }) => {
  // console.log(product)
  const { loading, data = { oGetSingleProductPurchase: [] } } = useQuery(PRD_PURCHASE_HIS, { variables: { shop: shop._id, product }})
  const batchColumns = [
    {
      key: 'index',
      render: (_, __, index) => <span>{index + 1}</span>,
      title: 'STT'
    },
    {
      key: 'date',
      // dataIndex: 'created',
      render: record => <span>{moment(record.created).format('DD/MM/YYYY')}</span>,
      title: 'Ngày',
    },
    {
      key: '_trace',
      dataIndex: 'trace',
      title: 'Số chứng từ'
    },
    {
      key: '_vendr',
      dataIndex: 'vendor',
      title: 'Nhà Cung cấp'
    },
    // {
    //   key: 'Prd',
    //   dataIndex: ['product','name'],
    //   title: 'Product'
    // },
    {
      key: '_unt',
      title: 'Đơn vị',
      dataIndex: ['product', 'unit']
    },
    {
      key: 'buyPrc',
      dataIndex: 'buyPrice',
      title: 'Giá Nhập'
    },
    {
      key: '_quant',
      dataIndex: 'quantity',
      title: 'Số lượng'
    },
    {
      key: '_totl',
      title: 'Tổng',
      render: rec => <span>{numberWithCommas(rec.buyPrice * rec.quantity)}</span>
    },
  ]
  if (loading) {
    return (
      <span>Đang tải dữ liệu...</span>
    )
  }
  if (data) {

    return (
      <div style={{ paddingLeft: '2em' }}>
        <p>Lịch sử nhập hàng: {productName}</p>
        <Table
          locale={{
            Table: {
              emptyText: 'Không có dữ liệu'
            }
          }}
          size="small"
          dataSource={data.oGetSingleProductPurchase}
          columns={batchColumns}
        />
      </div>
    );
  }
  return null;
}

export default ProductBatches