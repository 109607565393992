import React from 'react'
import {
  Row,
  Col,
  Typography,
  // PageHeader
} from 'antd'
import PieReport from './Pie'
import './style.css'

const { Title } = Typography

const DailyReport = ({
  byShops,
  byAgents,
  byCats
}) => {
    const shopPiesData = byShops.map(bs => ({
      name: bs.shop.name,
      value: bs.total,
      key: bs.shop._id
    }))
    const catPiesData = byCats.map(bc => ({
      name: bc.category.name,
      value: bc.total,
      key: bc.category._id
    }))
    const agentPiesData = byAgents.map(ba => ({
      name: ba.agent.name,
      value: ba.total,
      key: ba.agent._id
    }))
    
    return (
      <Row gutter={20}>
        <Col xs={24} span={3}>
          <Title level={4}>Doanh Thu</Title>
        </Col>
        <Col className="chartBox" xs={24} lg={8} style={{ minHeight: '500px'}}>
          <Title level={5}>Theo cửa hàng</Title>
          <PieReport data={shopPiesData} />
        </Col>
        <Col className="chartBox" xs={24} lg={8}>
          <Title level={5}>Theo danh mục</Title>
          <PieReport data={catPiesData} />
        </Col>
        <Col className="chartBox" xs={24} lg={8}>
          <Title level={5}>Theo nhân viên</Title>
          <PieReport data={agentPiesData} />
        </Col>
      </Row>
    )
}
export default DailyReport
