import React from 'react';
import {
  // Empty,
  // Button,
  // Typography,
  Spin,
  PageHeader,
} from 'antd';
// import {
//   // Link
// } from 'react-router-dom';
import {
  LoadingOutlined
} from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import ShopsList from '../Shops/ShopsList'

export const GET_MY_SHOPS = gql`
  query loadMyShops {
    loadMyShops {
      _id
      name
      address
      time {
        open
        close
      }
      roles {
        user {
          _id
          name
        }
        role
      }
    }
  }
`

// const { Title } = Typography;

const ShopOverview = () => {
  const {
    loading,
    // error,
    data
  } = useQuery(GET_MY_SHOPS)
  return (
    <div>
      <PageHeader
        title="Your shops"
      />
      {loading && (
        <Spin indicator={<LoadingOutlined style={{ fontSize: '24px'}} />} />
      )}
      {!loading && data && (
        <ShopsList shops={data.loadMyShops} />
      )}
      {/* <Empty
        description="You have no Shop(s) now. go and create one"
      >
        <Link to="/shops">
          <Button type="primary">Create a shop</Button>
        </Link>
      </Empty> */}
    </div>
  );
}

export default ShopOverview;