import React from 'react';
import ShopsList from './ShopsList';
import {
  GET_MY_PROFILE
} from '../';
import { useQuery } from '@apollo/client';
import {
  Route,
  Switch,
} from 'react-router-dom';
import  SingleShop from './SingleShop';
import LoadingScreen from '../../utils/LoadingScreen';
// import {
//   usePageTitle
// } from '../../utils/NameContext'

const ShopsMgt = () => {
  const user = useQuery(GET_MY_PROFILE);
  // const { loading, data, error } = useQuery(GET_MY_SHOPS);
  // const addShop = (data) => null;
  // console.log(user);
  if (user.loading) {
    return <LoadingScreen text ="Đang tải dữ liệu các cửa hàng" />
  }
  if (user.data && user.data.me) {
    return (
    <div>
      <div>
        <Switch>
          <Route path="/shops/:id" exact render={() => <SingleShop user={user.data.me || {role: null}} />} />
          <Route path="/shops" render={() => <ShopsList user={user.data.me || null} />} />
        </Switch>
      </div>
    </div>
  );
}
}

export default ShopsMgt;