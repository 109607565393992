import React from 'react'
import {
  Alert,
  Descriptions,
  Divider
} from 'antd'

const SelectedPrd = ({ product }) => {
  if (!product || !product._id) {
    return (
      <Alert
        showIcon
        type="info"
        message="Chọn sản phẩm cần nhập để xem thông tin sản phẩm"
      />
    )
  }
  return (
    <Alert
      showIcon
      type="success"
      message={<p>Thông tin sản phẩm</p>}
      description={(
        <Descriptions layout="horizol" column={1}>
          <Divider />
          <Descriptions.Item label="Tên">
            {product.name}
          </Descriptions.Item>
          <Descriptions.Item label="Đơn vị">
            {product.unit}
          </Descriptions.Item>
          <Descriptions.Item label="Tồn kho">
            {product.stock}
          </Descriptions.Item>
          <Descriptions.Item label="Giá bán">
            {product.sellPrice}
          </Descriptions.Item>
          <Descriptions.Item label="Giá TBGQ">
            {product.avgPrice}
          </Descriptions.Item>
        </Descriptions>
      )}
    />
  )
}

export default SelectedPrd
