import React from 'react'
import {
  Button,
  Popconfirm,
  Table
} from 'antd'
import { numberWithCommas } from '../../../../../../utils/Match'
import LoadingScreen from '../../../../../../utils/LoadingScreen'
import { DeleteOutlined } from '@ant-design/icons'

const ShoppingCart = ({
  products,
  removeProduct
}) => {
  const [currentRemove, setCurrentRemove] = React.useState(null)

  const remove = async(product) => {
    setCurrentRemove(product)
    await removeProduct(product)
    setCurrentRemove(null)
  }
  const columns = [
    {
      title: 'STT',
      render: (_, __, idx) => <span>{idx + 1}</span>
    },
    {
      title: 'Sản Phẩm',
      dataIndex: 'name'
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit'
    },
    {
      title: 'Đơn giá',
      dataIndex: 'sellPrice',
      render: t => <span>{numberWithCommas(t)}</span>
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity'
    },
    {
      title: 'Thành Tiền',
      render: (_, record) => <span>{numberWithCommas(record.quantity * record.sellPrice)}</span> 
    },
    {
      title: <DeleteOutlined />,
      render:(_, record) => (
        <Popconfirm
          title="Xóa sản phẩm này khỏi đơn hàng?"
          onConfirm={() => remove(record._id)}
        >
          <Button
            loading={record._id === currentRemove}
            type="link"
            icon={<DeleteOutlined />}
            danger
          />
        </Popconfirm>
        )
    }
  ]
  if (!products) {
    return <LoadingScreen />
  }
  const filteredData = products.filter(p => p._id !== null)
  return (
    <Table
      pagination={false}
      dataSource={filteredData}
      columns={columns}
    />
  )
}
export default ShoppingCart
