import React from 'react'
import {
  Table,
  Popover,
  Modal,
  Typography
} from 'antd'
// import Title from 'antd/lib/skeleton/Title'

const { Title } = Typography

const Cart = ({
  products,
  removeFromCart
}) => {
  const columns = [
    {
      key: 'no',
      render: (_, __, index) => <span>{index + 1}</span>,
      title: 'STT'
    },
    {
      title: 'Tên',
      key: 'prd',
      render: (record) => (
        <Popover
          title={record.product.name}
          trigger="hover"
          content="Nothing to show so far"
        >
          {record.product.name}
        </Popover>
        )
    },
    {
      key: 'unit',
      title: 'Đơn vị',
      render: record => <span>{record.product.unit}</span>
    },
    {
      key: 'quan',
      title: 'Số lượng',
      dataIndex: 'quantity'
    },
    {
      key: 'pr',
      title: 'Giá',
      dataIndex: 'sellPrice',
      render: text => <span>{numberWithCommas(parseFloat(text))}</span>
    },
    {
      key: 'total',
      title: 'Tổng',
      render: (_, record) => <span>{numberWithCommas(record.sellPrice * record.quantity)}</span>
    }
  ]
  return (
    <Table
      dataSource={products}
      columns={columns}
      pagination={false}
      summary={pageData => {
        let total = 0
        pageData.forEach(({ sellPrice, quantity }) => {
          total += sellPrice * quantity
        })
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5}>Tổng</Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>
                  {`${numberWithCommas(total)} Đ`}
                </strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: () => {
            // removeFromCart(record)
            Modal.confirm({
              title: `Xóa khỏi giỏ hàng`,
              content: <div>
                <Title level={3}>
                  {record.product.name}
                </Title>
                <p>
                  Sản phẩm sẽ bị loại khỏi giỏ hàng
                </p>
              </div>,
              onOk: () => removeFromCart(record)
            })
          },
        };
      }}
    />
  )
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default Cart