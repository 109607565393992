import React from 'react'
import {
  Button,
  // Space,
  Pagination,
  PageHeader
} from 'antd'

const ModalFooter = ({
  changePage,
  currentpage,
  pageSize,
  total,
  onClose,
  onDiscard,
  onApply
}) => {
  return (
    <div style={{ textAlign: 'right', width: '100%', display: 'block' }}>
      <PageHeader
        title={(
          <Pagination
          style={{float: 'left'}}
          current={currentpage}
          total={total}
          pageSize={pageSize}
          size="small"
          onChange={(page) => changePage(page)}
          showSizeChanger={false}
        /> 
        )}
        extra={[
        <Button key="_1" onClick={onClose}>Tạm đóng</Button>,
        <Button key="_2" onClick={onDiscard} danger type="primary">Hủy bỏ kiểm kê</Button>,
        <Button key="_3" onClick={onApply} type="primary">Áp dụng kiểm kê</Button>,
        ]}
      />
    </div>
  )
}

export default ModalFooter
