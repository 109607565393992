import React from 'react'
import {
  Table,
  Button,
  Form,
  InputNumber,
  Select,
  Input,
  Popconfirm,
  // Form
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined, SaveOutlined
} from '@ant-design/icons'
import './tableStyle.css'
import {
  Parser
} from 'hot-formula-parser'
import {
  // gql,
  useLazyQuery,
  // useQuery
} from '@apollo/client'
import {
  SEARCH_PRODUCTS
} from '../../PurchaseDrawer/ProductsList'
import {
  numberWithCommas
} from '../../../../../../utils/Match'


const { Option } = Select

const PurchasedPrds = ({
  data,
  addPrd,
  shop,
  remove,
  currentRemoving,
  currentSaving
}) => {
  // basic stuffs
  const parser = new Parser()
  const [form] = Form.useForm()
  const [currentRecID, setCurrentRecID] = React.useState('first')
  const [loadPrds, { loading: searching, data: prdData }] = useLazyQuery(SEARCH_PRODUCTS, { fetchPolicy: 'network-only'})
  const [selectedPrd, setSelectedPrd] = React.useState(null)
  let searchTimeout = null

  // user click on edit button
  const startEdit = (record) => {
    form.setFieldsValue({ ...record })
    setCurrentRecID(record._id)
    setSelectedPrd(record.product)
  }

  // user search for product to add | edit
  const searchPrd = keyword => {
    if (keyword.length <= 2) {
      return
    }
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(() => {
      loadPrds({
        variables: {
          shop,
          keyword,
          limit: 10
        }
      })
    },100)
  }

  // user click on a product from dropdown list.
  const onProductSelected = (val, _, record) => {
    setSelectedPrd(val)
    prdData.oSearchPrdByName.forEach(p => {
      if (p._id === val) {
        // console.log(p)
        // console.log(record)
        form.setFieldsValue({
          ...record,
          name: p.name,
          unit: p.unit,
          sellPrice: p.sellPrice
        })
        // form.resetFields()
      }
    })
  }

  // on Save button clicked
  const add = async (data) => {
    await addPrd(data)
    form.resetFields()
  }
  // columns of the table.
  const columns = [
    {
      key: '_idx',
      render: (_, __, idx) => <span>{idx}</span>,
      title: 'STT'
    },
    {
      key: '_name',
      dataIndex: 'name',
      title: 'Tên',
      editable: true,
      editableInput: 'SELECT'
    },
    {
      key: '_bPrice',
      title: 'Giá Nhập',
      dataIndex: 'buyPrice',
      editable: true,
      // editableInput: 'INPUTNUMBER'
    },
    {
      key: '_qty',
      title: 'Số lượng',
      dataIndex: 'quantity',
      editable: true,
      editableInput: 'INPUTNUMBER',
      onChange: (val) => {
        console.log(val)
        const { buyPrice } = form.getFieldsValue()
        form.setFields([
          {
            name: 'total',
            value: buyPrice * val
          }
        ])
      }
    },
    {
      key: '_unt',
      title: 'Đơn vị',
      dataIndex: 'unit',
      editable: true,
      disabled: true
    },
    {
      key: '_sPrc',
      title: 'Giá bán',
      dataIndex: 'sellPrice',
      editableInput: 'INPUTNUMBER',
      editable: true,
      disabled: true,
    },
    {
      key: '_ttal',
      title: 'Tổng',
      dataIndex: 'total',
      blank: true,
      editable: true,
      editableInput: 'INPUTNUMBER',
      onChange: val => {
        console.log(val)
        const { quantity } = form.getFieldsValue()
        form.setFields([
          {
            name: 'buyPrice',
            value: Math.round(val/quantity)
          }
        ])
      }
    },
    {
      key: '_acts',
      title: 'F',
      render: (record) => {
        if (record._id === currentRecID) {
          return <Button loading={selectedPrd !==null && selectedPrd  === currentSaving && currentSaving !== null} onClick={() => add({ ...form.getFieldsValue(), product: selectedPrd })} type="link" icon={<SaveOutlined />} />
        }
        return (
          <span>
            <Button onClick={() => startEdit(record)} type="link" icon={<EditOutlined />} />
            <Popconfirm
              title="Có chắc xóa không?"
              onCancel={() => null}
              onConfirm={() => remove(record.product)}
            >
              <Button loading={record.product !== null && record.product === currentRemoving} icon={<DeleteOutlined />} danger type="link" />
            </Popconfirm>
          </span>
        )
      }
    }
    // {
    //   key: '_lck',
    //   title: 'Check',
    //   render: () => (<LockOutlined />)
    // }
  ]

  // Data of the table
  const tdata = [{
    _id: 'first',
    product: null,
    name: 'Tìm kiếm theo tên',
    sellPrice: 0,
    buyPrice: 0,
    quantity: 0,
  }].concat(data)
  
  // mixed columns, with some new values added to the column for editing purpose.
  const newCols = columns.map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: record => ({
          ...record,
          editableInput: col.editableInput,
          editing: currentRecID === record._id,
          editable: record.product && col.editableInput === 'SELECT' ? false: true,
          dataIndex: col.dataIndex,
          // form,
          comp: () => {
            if (col.editableInput === 'INPUTNUMBER') {
              return <InputNumber onChange={col.onChange} disabled={col.disabled} /> 
            }
            if (col.editableInput === 'SELECT') {
              return (
                <Select
                  onSelect={(val, option) => onProductSelected(val, option, record)}
                  showAction={["focus"]}
                  optionFilterProp="children"
                  loading={searching}
                  showSearch
                  onSearch={searchPrd}
                > 
                  {prdData && prdData.oSearchPrdByName.map(p => 
                    <Option key={p._id} value={p._id}>{p.name}</Option>
                  )}
                </Select>
              )
            }
            return (
              <Input
                onPressEnter={e => {
                  const { result, error } = parser.parse(e.target.value)
                  console.log(result)
                  console.log(col.dataIndex)

                  if (error) return
                  form.setFields([
                    {
                      name: col.dataIndex,
                      value: Math.round(result)
                    },
                    {
                      name: 'total',
                      value: Math.round(result * form.getFieldValue('quantity'))
                    }
                  ])
                }}
                style={{ maxWidth: '100px'}}
                disabled={col.disabled}
              />
            )
          }
        })
      }
    }
    return col
  })
  // setCart(tdata)
  return (
    <Form form={form}>
      <Table
        pagination={false}
        scroll={{ y: 450 }}
        rowKey={rec => rec._id}
        rowClassName={(record) => { return record._id === currentRecID ? 'activeRow' : 'inactiveRow' }}
        columns={newCols}
        components={{
          body: {
            cell: EditableCell
          }
        }} 
        dataSource={tdata}
      />
    </Form>
  )
}
const EditableCell = ({
  // disabled,
  dataIndex,
  editable,
  editing,
  children,
  editableInput,
  // form,
  comp,
  ...restProps
}) => {
  const content = parseFloat(children) ? numberWithCommas(parseFloat(children)) : children
  return (
    <td {...restProps} className={editing ? 'activeCLS' : 'inactiveCLS'}> 
      {editing && editable ? (
        <Form.Item name={dataIndex}>
          {comp()}
        </Form.Item>
      ) : (
        <span>{content}</span>
      )}
    </td>
  )
}
export default PurchasedPrds
