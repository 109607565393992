import React from 'react'
import {
  PageHeader,
  Button,
  DatePicker,
  Space,
  message,
  Tooltip,
  // Popconfirm
} from 'antd'
import {
  gql,
  useLazyQuery,
  useMutation,
  // useApolloClient
} from '@apollo/client'
import PurchasesList from './PurchasesList'
import moment from 'moment'
import {
  useParams
} from 'react-router-dom'

import PurchaseDrawer from './PurchaseDrawer'
// import LoadingScreen from '../../../../utils/LoadingScreen'
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons'

const LOAD_SHOP_PURCHASES = gql`
  query loadPurchases($shop: String!, $search: SearchPCInput) {
    getPurchases (shop: $shop, search: $search) {
      _id
      products {
        product {
          _id
          name
          unit
        }
        buyPrice
        quantity
      }
      note
      trace
      vendor
      created
    }
  }
`
const REMOVE_PURCHASE = gql`
  mutation removePurchase($shop: String!, $purchase: String!) {
    removePurchase(shop: $shop, purchase: $purchase) 
  }
`
const GET_OR_CREATE_PC = gql`
  mutation findOrCreatePC($shop: String!, $purchase: String) {
    findOrCreatePC (shop: $shop, purchase: $purchase) {
      _id
      vendor
      trace
      createdBy
      invoice
      created
    }
  }
`

const { RangePicker } = DatePicker;


const BatchesMgt = () => {
  const { id: shop } = useParams()
  // const aClient = useApolloClient()
  const [importDrw, toggleImportDrw] = React.useState(false)
  const [search, setSearch] = React.useState({
    trace: '',
    vendor: '',
    product: ''
  })
  const [currentRemove, setCurrentRemove] = React.useState(null)
  // const [currentPC, setCurrentPC] = React.useState()
  const [range, setRange ] = React.useState([moment().subtract(90, 'days'), moment()])
  const [loadPCs, { loading: loadingPurchases, data: purchaseData = { getPurchases: [] } }] = useLazyQuery(
    LOAD_SHOP_PURCHASES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        shop,
        search: {
          ...search,
          startDate: range[0].format('DD/MM/YYYY'),
          endDate: range[1].format('DD/MM/YYYY')
        }
      }
    })
  const [createPC, { loading: creatingPC, data: pcData = { findOrCreatePC: null } }] = useMutation(GET_OR_CREATE_PC)
  // const [submitPurchase, { loading: submitting }] = useMutation(SUBMIT_PURCHASE_MUTATION)
  const [rmPurchase, { loading: removing }] = useMutation(REMOVE_PURCHASE)

  const onDateChange = value => {
    if (value) setRange(value)
  }

  const removeOnePC = async (purchase) => {
    setCurrentRemove(purchase)
    try {
      await rmPurchase({
      variables: { shop, purchase },
      update: async (cache, result) => {
        const oData = await cache.readQuery({
          query: LOAD_SHOP_PURCHASES,
          variables: {
            shop,
            search: {
              ...search,
              startDate: range[0].format('DD/MM/YYYY'),
              endDate: range[1].format('DD/MM/YYYY')
            }
          }
        })
          // console.log(result)
          // console.log(oData)
        cache.writeQuery({
          query: LOAD_SHOP_PURCHASES,
          variables: {
            shop,
            search: {
              ...search,
              startDate: range[0].format('DD/MM/YYYY'),
              endDate: range[1].format('DD/MM/YYYY')
            }
          },
          data: {
            ...oData,
            getPurchases: oData.getPurchases.filter(p => p._id !== purchase)
          }
        })
      }
      })
      setCurrentRemove(null)
      message.success('Xóa đơn nhập hàng thành công')
    } catch(e) {
      message.error('Có lỗi khi xóa đơn nhập hàng')
    }
    
  }

  React.useEffect(() => {
    const load = async () => {
      const startDate = moment(range[0]).format('DD/MM/YYYY')
      const endDate = moment(range[1]).format('DD/MM/YYYY')
      // const { vendor, invoice, product } = search
      const searchFilter = {
        ...search,
        startDate,
        endDate
      }
      await loadPCs({
        variables: {
          shop,
          search: searchFilter
        }
      })
    }
    load()
  }, [loadPCs, shop, range, search])
  
  const updateSearch = (key, value) => {
    const newSearch = {
      ...search
    }
    newSearch[key] = value
    setSearch(newSearch)
  }
  
  const clearSearch = (key) => {
    const newSearch = { ...search }
    newSearch[key] = ''
    setSearch(newSearch)
  }

  const openPurchase = async (purchase) => {
    try {

      await createPC({
        variables: {
          shop,
          purchase
        }
      })
      // setCurrentPC(pc)
      toggleImportDrw(true)
      if (pcData && pcData.findOrCreatePC) {
        console.log('here run in parent - have pc data')
        // setCurrentPC(pcData.findOrCreatePC._id)
      }
    } catch (e) {
      message.error('Không thể tải dữ liệu')
    }
  }

  const reload = async () => {
    loadPCs()
  }

  const closePurchaseDrw = async () => {
    const startDate = moment(range[0]).format('DD/MM/YYYY')
      const endDate = moment(range[1]).format('DD/MM/YYYY')
      // const { vendor, invoice, product } = search
      const searchFilter = {
        ...search,
        startDate,
        endDate
      }
      await loadPCs({
        variables: {
          shop,
          search: searchFilter
        }
      })
    // }
    toggleImportDrw(false)
  }

  return (
    <div>
      <PageHeader
        title={(
          <Space size={12}>
            <RangePicker onChange={onDateChange} format="DD/MM/YYYY" />
            <Button onClick={() => setRange([moment(), moment()])}>Hôm nay</Button>
          </Space>
        )}
        extra={[
          <Space>
            <Tooltip title="Thêm phiếu nhập hàng mới">
              <Button icon={<PlusOutlined />} loading={creatingPC} key="_drw" type="primary" onClick={() => openPurchase(null)}>Thêm đơn</Button>,
            </Tooltip>
            <Tooltip title="Tải lại danh sách đã nhập">
              <Button onClick={() => reload()} icon={<ReloadOutlined />} />
            </Tooltip>
          </Space>
        ]}
      />
      <PurchasesList
        openPurchase={openPurchase}
        removePC={removeOnePC}
        search={search}
        loading={loadingPurchases}
        updateSearch={updateSearch}
        clearSearch={clearSearch}
        dataSource={purchaseData.getPurchases}
        shop={shop}
        current={{
          currentRemove,
          removing
        }}
      />
      {pcData && pcData.findOrCreatePC && (
        <PurchaseDrawer
          purchase={pcData.findOrCreatePC._id}
          shop={shop}
          visible={importDrw && pcData.findOrCreatePC._id}
          onClose={() => closePurchaseDrw()}
        />
      )}
    </div>
  )
}
export default  BatchesMgt