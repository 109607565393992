import React from 'react';
import {
  PageHeader,
  Row,
  Col
} from 'antd'
import BasicInfo from './Basic'

const Profile = ({
  me
}) => {
  return (
    <div>
      <PageHeader title="Thông tin cá nhân" subTitle="cập nhật, thay đổi..." />
      <Row gutter={16}>
        <Col xs={24} md={12} lg={{ span: 8, offset: 8 }}>
          <BasicInfo me={me} />
        </Col>
      </Row>
    </div>
  )
}
export default Profile