import React from 'react'
import {
  Drawer,
  PageHeader,
  Row,
  Col,
  Divider,
  Alert,
  Button
} from 'antd'
import BatchSaleNote from './RightColumn/Note'
import ProductDetail from './RightColumn/ProductDetail'
import SaleForm from './LeftColumn/SaleForm'
import ShoppingCart from './LeftColumn/ShoppingCard'
import LoadingScreen from '../../../../../utils/LoadingScreen'

const BatchSaleDrw = ({
  visible,
  shop,
  onClose,
  singleOS,
  addProduct,
  removeProduct,
  updateNote
}) => {
  const [selectedPrd, setSelectedPrd] = React.useState(null)
  const {
    loading,
    data,
    error
  } = singleOS

  const add = async (sdata) => {
    const { _id } = data.ownerLoadSingleSale.sale
    if (!_id) return
    await addProduct(_id, sdata)
  }
  
  const remove = async(product) => {
    const { _id } = data.ownerLoadSingleSale.sale
    if (!product || !_id) return
    await removeProduct(_id, product)
  }

  const updateN = async(note) => {
    const { _id } = data.ownerLoadSingleSale.sale
    if (!_id) return
    await updateNote(_id, note)
  }

  if (loading && !error) {
    return <LoadingScreen text="Đang tải dữ liệu" />
  }
  if (error) {
    return <Alert type="error" showIcon message="Đã có lỗi xẩy ra" />
  }
  if (data && data.ownerLoadSingleSale) {
    return (
      <Drawer
        onClose={onClose}
        closable
        width="100%"
        visible={visible}
        footer={
          <Row justify="end" style={{ textAlign: 'right'}}>
            <Button onClick={onClose} type="primary">Đóng</Button>
          </Row>
        }
      >
        <PageHeader
          title={`Xuất bán`}
          />
        <Row gutter={12}>
          <Col sm={24} md={18}>
            <SaleForm
              add={add}
              shop={shop}
              setCurrentPrd={setSelectedPrd}
              currentPrd={selectedPrd}
            />
            <ShoppingCart
              removeProduct={remove}
              products={data.ownerLoadSingleSale.products}
            />
          </Col>
          <Col sm={24} md={6}>
              <BatchSaleNote
                sale={data.ownerLoadSingleSale.sale}
                updateNote={updateN}
                />
              <Divider />
              <ProductDetail
                product={selectedPrd}
                />
          </Col>
        </Row>
      </Drawer>
    )
  }
  return null
}

export default BatchSaleDrw
