import React from 'react';
import {
  Modal,
  Form,
  Input,
} from 'antd';
import {
  gql,
  useMutation,
  useApolloClient,
} from '@apollo/client';
import { GET_MY_SHOPS } from '../default/ShopsOverview';


const CREATE_SHOP_MUT = gql`
  mutation createShop($name: String!, $address: String!, $time: TimeInput) {
    createShop(name: $name, address: $address, time: $time) {
      name
      address
      owner {
        name
      }
    }
  }
`;


const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};

export const AddShopModal = ({
  visible,
  onOk,
  onCancel
}) => {
  const client = useApolloClient()
  const [form] = Form.useForm();
  const [
    createShop,
    { loading }
  ] = useMutation(CREATE_SHOP_MUT);

  const onSubmit = async () => {
    const {
      name,
      address,
    } = form.getFieldsValue();
    const data = {
      name,
      address,
      time: {
        open: form.getFieldValue('open'),
        close: form.getFieldValue('close')
      }
    }
    const oldData = client.readQuery({ query: GET_MY_SHOPS })
    console.log(oldData);
    await createShop({
      variables: {
        ...data
      }
    })
    .then(res => {
      console.log(res);
      client.writeQuery({
        query: GET_MY_SHOPS,
        data: {
          ...oldData,
          loadMyShops: oldData.loadMyShops.concat([res.data.createShop])
        }
      });
      onCancel()
    })
    .catch(e => console.log(e))
  }
  return (
    <Modal
      title="Thêm cửa hàng mới"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{ loading }}
    >
      <Form form={form} {...formLayout}>
        <Form.Item label="Tên" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Địachỉ" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Thời gian">
          <Form.Item name="open" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
            <Input />
          </Form.Item>
          <Form.Item name="close" style={{ display: 'inline-block', marginLeft: '10px', width: 'calc(50% - 8px)' }}>
            <Input />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddShopModal;