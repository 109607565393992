import React from 'react'
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Form,
} from 'antd'
import moment from 'moment'
import { numberWithCommas } from '../../../../utils/Match'
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
  useHotkeys
} from 'react-hotkeys-hook'

const OwerSaleList = ({
  sales,
  removeSale,
  currentRemoving,
  openDrw,
  setSearch,
  search
}) => {
  const [form] = Form.useForm()
  form.setFieldsValue({ ...search })
  const noteRef = React.useRef(null)
  const pRef = React.useRef(null)
  const [filters, setFilters] = React.useState({
    note: false,
    product: false
  })
  
  // const updateSearch = (key, val) => {

  // }

  const toggleFilter = (key, val) => {
    const newFilters = { ...filters }
    newFilters[key] = val
    setFilters(newFilters)
  }
  const columns = [
    {
      title: 'STT',
      render: (_, __, idx) => <span>{idx + 1}</span>
    },
    {
      title: 'Ngày bán',
      dataIndex: 'created',
      render: text => <span>{moment(parseFloat(text)).format('DD/MM/YYYY')}</span>
    },
    {
      title: filters.note || search.note
        ?
        (
          <Form.Item name="note">
            <Input ref={noteRef} suffix={<CloseOutlined onClick={() => setSearch('note', '')} />} onPressEnter={e => setSearch('note', e.target.value)} />
          </Form.Item>
        )
        :
        <Tooltip color="magenta" title="Bấm Ctrl+F để tìm kiếm đơn theo ghi chú">Ghi chú (Ctrl+U)</Tooltip>,
      dataIndex: 'note',
    },
    {
       title: filters.product || search.product
        ?
        (
          <Form.Item name="product">
            <Input ref={pRef} suffix={<CloseOutlined onClick={() => setSearch('product', '')} />} onPressEnter={e => setSearch('product', e.target.value)} />
          </Form.Item>
        )
        :
        <Tooltip color="magenta" title="Bấm Ctrl+P để tìm kiếm đơn theo sản phẩm">Sản phẩm (Ctrl+R)</Tooltip>,
      render: (_, record) => <span>{record.products.length}</span>
    },
    {
      title: 'Tổng Tiền',
      render: (_, record) => {
        let total = 0
        record.products.forEach(p => {
          total += p.quantity * p.sellPrice
        })
        return <span>{`${numberWithCommas(total)} VNĐ`}</span>
      }
    },
    {
      title: 'FN',
      render: (_, record) => (
        <Space>
          <Tooltip title="Sửa đơn">
            <Button onClick={() => openDrw(record._id)} disabled={currentRemoving} type="link" icon={<EditOutlined />}>Sửa</Button>
          </Tooltip>
          <Popconfirm
            title="Bạn có chắc muốn xóa đơn này?"
            onConfirm={() => removeSale(record._id)}
          >
            <Tooltip title="Xóa đơn">
                <Button loading={currentRemoving === record._id } danger type="link" icon={<DeleteOutlined />}>Xóa</Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      )
    }
  ]

  useHotkeys('ctrl+u', async e => {
    e.preventDefault()
    await toggleFilter('note', true)
    noteRef.current.focus()
  })
  useHotkeys('ctrl+r', async e => {
    e.preventDefault()
    await toggleFilter('product', true)
    pRef.current.focus()
  })
  return (
    <Form form={form}>
      <Table
        rowKey={r => r._id}
        dataSource={sales}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <SinglePurchaseDetail products={record.products} />,
          rowExpandable: () => true
        }}
      />
    </Form>
  )
}
export default OwerSaleList

const SinglePurchaseDetail = ({ products }) => {
  const columns = [
    {
      title: 'STT',
      render: (_, __, idx) => <span>{ idx + 1 }</span>
    },
    {
      title: 'Sản phẩm',
      dataIndex: ['product','name']
    },
    {
      title: 'Đơn vị',
      dataIndex: ['product', 'unit']
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity'
    },
    {
      title: 'Giá bán',
      dataIndex: 'sellPrice',
      render: t => <span>{numberWithCommas(t)}</span>
    },
    {
      title: 'Thành tiền',
      render: record => <span>{`${numberWithCommas(record.quantity * record.sellPrice)} VNĐ`}</span>
    }
  ]
  return (
    <Table
      size="small"
      dataSource={products}
      columns={columns}
      pagination={false}
    />
  )
} 
