import React from 'react';
import {
  useParams
} from 'react-router-dom';
import {
  gql,
  useQuery,
} from '@apollo/client';
import {
  Result,
  Tabs,
  Tooltip,
} from 'antd';
import {
  InfoCircleOutlined,
  UserOutlined,
  StockOutlined,
  ImportOutlined,
  FileSyncOutlined,
  TransactionOutlined
} from '@ant-design/icons'
import AgentsList from './AgentsList';
import ShopBasicInfo from './BasicInfo';
import Stock from './Stock';
import BatchesMgt from './Batches'
import AssetMgt from './Assetment'
// import BatchSale from './BatchSale'
import OwnerSale from './OwnerSale'

import LoadingScreen from '../../../utils/LoadingScreen';
import { usePageTitle } from '../../../utils/NameContext'

const { TabPane } = Tabs;

const LOAD_AGENTS = gql`
  query loadAgents{
    loadMyAgents{
      name
      email
      _id
      profile {
        avatar
        address {
          city
        }
      }
    }
  }
`;

const SINGLE_SHOP_QUERY = gql`
  query getShop($shop: String!){
    getShop(shop: $shop) {
      _id
      name
      address
      time {
        open
        close
      }
      roles {
        user {
          _id
          name
          profile {
            avatar
          }
          email
        }
        role
      }
    }
  }
`;

const SingleShop = ({ user }) => {
  const { id } = useParams();
  const { dispatch } = usePageTitle()
  const { loading, data, error } = useQuery(SINGLE_SHOP_QUERY, { variables: { shop: id } });
  const { loading: agentloading, data: agentData, error: agentError } = useQuery(LOAD_AGENTS);

  React.useEffect(() => {
    if (id && data) {
      // console.log(data)
      const { getShop } = data
      dispatch({ type: 'CHANGE_PAGE_TITLE', payload: getShop.name})
    }
  }, [id, data, dispatch])

  if (user.role !== 'shopowner') {
    return <Result status="warning" title="You dont have permission to view this page" />;
  }
  if (loading || agentloading) {
    return (
      <LoadingScreen text="Đang tải dữ liệu cửa hàng..." />
    );
  }
  if (error || agentError) {
    return (
      <Result
        status="error"
        title="Something went wrong"
      />
    );
  }
  return (
    <div>
      {/* <Title level={3}>{data.getShop.name}</Title> */}
      <Tabs defaultActiveKey="stock">
        <TabPane
          key="basic"
          tab={(
            <Tooltip title="Thông tin cơ bản về cửa hàng">
              <InfoCircleOutlined />
              {data.getShop.name}
            </Tooltip>
          )}
        >
          <ShopBasicInfo shop={data.getShop} />
        </TabPane>
        <TabPane
          tab={(
            <Tooltip title="Danh sách nhân viên của cửa hàng này">
              <UserOutlined />
              Nhân viên bán hàng
            </Tooltip>
          )}
          key="agents"
        >
          <AgentsList agents={agentData.loadMyAgents} shop={data.getShop} />
        </TabPane>
        <TabPane
          key="stock"
          tab={(
            <Tooltip title="Danh sách tồn kho, giá bán, giá nhập ....">
              <StockOutlined />
              Kho hàng
            </Tooltip>
          )}
        >
          <Stock shop={data.getShop} />
        </TabPane>
        <TabPane
          key="batches"
          tab={(
            <Tooltip title="Nhập hàng vào kho, quản lý phiếu nhập">
              <ImportOutlined />
              Nhập hàng
            </Tooltip>
          )}
        >
          <BatchesMgt shop={id} />
        </TabPane>
        <TabPane
          key="batchsale"
          tab={(
            <Tooltip title="Xuất bán, xuất đóng liều, bán giá gốc...">
              <TransactionOutlined />
              Xuất bán
            </Tooltip>
          )}
        >
          {/* <BatchSale shop={id} /> */}
          <OwnerSale shop={data.getShop._id} />
        </TabPane>
        <TabPane
          key="inventory"
          tab={(
            <Tooltip title="Kiểm kê kho hàng, cân đối nhập / xuất/ bán">
              <FileSyncOutlined />
              Kiểm kê
            </Tooltip>
          )}
        >
          <AssetMgt shop={id} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default SingleShop;
