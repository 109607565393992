import React from 'react'
import {
  Button,
  message,
  PageHeader,
  DatePicker,
  Tooltip,
  Space
} from 'antd'
import {
  gql,
  useLazyQuery,
  useMutation
} from '@apollo/client'
import moment from 'moment'
import OwnerSaleList from './OwnerSale/OwnerSaleList'
import LoadingScreen from '../../../utils/LoadingScreen'
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import BatchSaleDrw from './OwnerSale/OwerSaleDrw'

const { RangePicker } = DatePicker

const LOAD_BATCH_SALES = gql`
  query loadBatchSales($shop: String!, $search: OSSearchKey) {
    loadBatchSales(shop: $shop, search: $search) {
        _id
        total
        created
        note
        products {
          _id
          product {
            _id
            name
            unit
          }
          quantity
          sellPrice
          total
        }
    }
  }
`
const OWNER_CREATE_SALE = gql`
  mutation ownerCreateSale ($shop: String!) {
    ownerCreateSale(shop: $shop) {
      _id
      total
      created
      note
      products {
        _id
        product {
          _id
          name
        }
        quantity
        sellPrice
        total
      }
    }
  }
`
const OWNER_LOAD_SALE = gql`
  query ownerLoadSingleSale ($shop: String!, $sale: String!) {
    ownerLoadSingleSale(shop: $shop, sale: $sale) {
      sale {
        _id
        saleType
        created
        note
      }
      products {
        _id
        name
        unit
        sellPrice
        avgPrice
        quantity
      }
    }
  }
`
const OWNER_REMOVE_OS = gql`
  mutation owerRemoveSaleRecord($shop: String!, $sale: String!) {
    owerRemoveSaleRecord(shop: $shop, sale: $sale)
  }
`
const OWNER_ADD_PRD = gql`
  mutation ownerAddPrdToSale($shop: String!, $sale: String!, $data: ProductSaleInput) {
    ownerAddPrdToSale(shop: $shop, sale: $sale, data: $data) {
      _id
      name
      unit
      sellPrice
      quantity
      avgPrice
    }
  }
`
const OWNER_RM_PRD = gql`
  mutation removeFromOS ($shop: String!, $sale: String!, $product: String!) {
    removeFromOS(shop: $shop, sale: $sale, product: $product) {
       _id
      name
      unit
      sellPrice
      quantity
      avgPrice
    }
  }
`
const UPDATE_NOTE = gql`
  mutation updateOSNote($shop: String!, $sale: String!, $note: String) {
    updateOSNote(shop: $shop, sale: $sale, note: $note) {
      _id
      saleType
      created
      note
    }
  }
`
const OwnerSale = ({
  shop
}) => {
  const [OSDrawer, toggleOSDrawer] = React.useState(false)
  const [range, setRange] = React.useState({
    startDate: moment().subtract(30, 'd'),
    endDate: moment().endOf('month')
  })
  const [search, setSearch] = React.useState({
    note: '',
    product: '',
    startDate: null,
    endDate: null
  })
  const [loadB, { loading: loadingBatch, data: sales = { loadBatchSales : [] } }] = useLazyQuery(LOAD_BATCH_SALES, { fetchPolicy: 'network-only'})
  const [createBlankSale, { loading: creating }] = useMutation(OWNER_CREATE_SALE)
  const [loadSingleSale, loadSingleOSResult] = useLazyQuery(OWNER_LOAD_SALE, { fetchPolicy: 'cache-and-network'})
  const [removeOS] = useMutation(OWNER_REMOVE_OS)
  const [addPrd] = useMutation(OWNER_ADD_PRD)
  const [removePrd] = useMutation(OWNER_RM_PRD)
  const [updateN] = useMutation(UPDATE_NOTE)
  const [currentRemoving, setCurrentRemoving] = React.useState(null)

  React.useEffect(() => {
    const load = async () => {
      await loadB({
      variables: {
          shop,
          search
        }
      })
    }
    load()
  }, [loadB, search, shop])

  const openDrw = async (sale) => {
    try {
      if (!sale) {
        await createBlankSale({
          variables: {
            shop
          },
          update: async (cache, {data: { ownerCreateSale }}) => {
            const { loadBatchSales } = await cache.readQuery({
              query: LOAD_BATCH_SALES,
              variables: {
                shop,
                search
              }
            })
            // console.log(loadBatchSale)
            cache.writeQuery({
              query: LOAD_BATCH_SALES,
              variables: {
                shop,
                search
              },
              data: {
                loadBatchSales: [ownerCreateSale, ...loadBatchSales]
              }
            })
            await loadSingleSale({
              variables: {
                shop,
                sale: ownerCreateSale._id
              }
            })
          }
        })
        .then(() => {
          console.log('now, only need to toggle the drawer')
          toggleOSDrawer(true)
        })
        return
      } else {
        await loadSingleSale({
          variables: {
            shop,
            sale
          }
        })
        // .then(() => {
        // })
        toggleOSDrawer(true)
      }

    } catch (e) {
      console.log(e)
      message.error('Không thể mở bản ghi xuất bán')
    }
  }

  const removeSale = async (sale) => {
    setCurrentRemoving(sale)
    await removeOS({
      variables: {
        shop,
        sale
      },
      update: async (cache, { data: { owerRemoveSaleRecord }}) => {
        const oldData = await cache.readQuery({
          query: LOAD_BATCH_SALES,
          variables: {
            shop,
            search
          }
        })
        console.log(oldData)
        cache.writeQuery({
          query: LOAD_BATCH_SALES,
          variables: {
            shop,
            // startDate: range.start,
            // endDate: range.end
            search
          },
          data: {
            ...oldData,
            loadBatchSales: oldData.loadBatchSales.filter(s => s._id.toString() !== owerRemoveSaleRecord)
          }
        })
      }
    })
    setCurrentRemoving(false)
  }

  const addProduct = async (sale, data) => {
    const {
      product,
      quantity,
      sellPrice
    } = data
    await addPrd({
      variables: {
        shop,
        sale,
        data: {
          product,
          quantity,
          sellPrice
        }
      },
      update: (cache, { data: { ownerAddPrdToSale }}) => {
        const oldD = cache.readQuery({
          query: OWNER_LOAD_SALE,
          variables: {
            shop,
            sale
          }
        })
        cache.writeQuery({
          query: OWNER_LOAD_SALE,
          variables: {
            shop,
            sale
          },
          data: {
            ownerLoadSingleSale: {
              ...oldD,
              produtcs:ownerAddPrdToSale
            }
          }
        })
      }
    })
    .then((r) => {
      console.log(r)
    })
    return
  }

  const removeProduct = async (sale, product) => {
    await removePrd({
      variables: {
        shop,
        sale,
        product
      },
      update: (cache, { data: { removeFromOS }}) => {
        const oldD = cache.readQuery({
          query: OWNER_LOAD_SALE,
          variables: {
            shop,
            sale
          }
        })
        cache.writeQuery({
          query: OWNER_LOAD_SALE,
          variables: {
            shop,
            sale
          },
          data: {
            ownerLoadSingleSale: {
              ...oldD,
              produtcs: removeFromOS
            }
          }
        })
      }
    })
  }

  const updateNote = async (sale, note) => {
    await updateN({
      variables: {
        shop,
        sale,
        note
      },
      update: (cache, { data: { updateOSNote } }) => {
        const oldData = cache.readQuery({
          query: OWNER_LOAD_SALE,
          variables: {
            shop,
            sale
          }
        })
        cache.writeQuery({
          query: OWNER_LOAD_SALE,
          variables: {
            shop,
            sale
          },
          data: {
            ownerLoadSingleSale: {
              ...oldData,
              sale: updateOSNote
            }
          }
        })
      }
    })
  }

  const reloadOS = async () => {
    setRange({
      startDate: moment().startOf('month').subtract(30, 'd'),
      endDate: moment().endOf('month')
    })
    // console.log(shop, range)
    setSearch({
      note: '',
      product: '',
      startDate: range.startDate.format('DD/MM/YYYY'),
      endDate: range.endDate.format('DD/MM/YYYY')
    })
    // await loadB({
    //   variables: {
    //       shop,
    //       search
    //     }
    // })
  }

  const updateSearch = (key, value) => {
    const newSearch = { ...search }
    newSearch[key] = value
    setSearch(newSearch)
  }

  const updateRange = (start, end) => {
    const newSearch = { ...search }
    newSearch.startDate = start.format('DD/MM/YYYY')
    newSearch.endDate = end.format('DD/MM/YYYY')
    setSearch(newSearch)
  }

  const onDrwClose = async () => {
    await await loadB({
      variables: {
          shop,
          search
        }
      })
      toggleOSDrawer(false)
  }

  if (loadingBatch) {
    return <LoadingScreen text="loading..." />
  }

  return (
    <div>
      <PageHeader
        title={(
          <Space>
            <RangePicker onChange={
              async (r) => {
                const [start, end] = r
                // console.log(end.format('DD/MM/YYYY'))
                // await updateSearch('startDate', start.format('DD/MM/YYYY'))
                // await updateSearch('endDate', end.format('DD/MM/YYYY'))
                updateRange(start, end)
              }
            }
            />
            <Button
              onClick={() => {
                updateRange(
                  moment(),
                  moment()
                )
              }}
            >
              Hôm nay
            </Button>
            <Button
              onClick={() => {
                updateRange(moment().startOf('w'), moment().endOf('w'))
              }}>
                Tuần này
              </Button>
            <Button
              onClick={() => {
                updateRange(moment().startOf('month'),  moment().endOf('month'))
              }}>
                Tháng này
            </Button>
          </Space>
        )}
        extra={[
          <Tooltip key="_1" color="magenta" title="Thêm đơn xuất bán">
            <Button icon={<PlusOutlined />} loading={creating} key="_new" type="primary" onClick={() => openDrw(false)}>Thêm đơn xuất bán</Button>,
          </Tooltip>,
          <Tooltip key="_2" color="magenta" title="Tải lại dữ liệu">
            <Button onClick={() => reloadOS()} key="_rl" icon={<ReloadOutlined />} />
          </Tooltip>
        ]}
      />
      {sales.loadBatchSales && (
        <OwnerSaleList
          search={search}
          setSearch={updateSearch}
          removeSale={removeSale}
          sales={sales.loadBatchSales}
          currentRemoving={currentRemoving}
          openDrw={openDrw}
        />
      )}
      {loadSingleOSResult && (
        <BatchSaleDrw
          updateNote={updateNote}
          removeProduct={removeProduct}
          addProduct={addProduct}
          singleOS={loadSingleOSResult}
          visible={OSDrawer}
          shop={shop}
          onClose={onDrwClose}
        />
      )}
    </div>
  )
}
export default OwnerSale
