import React from 'react'
import {
  Table,
  Button,
  Form,
  Input,
  Select,
  Tooltip,
  // Badge
} from 'antd'
import {
  gql,
  useQuery,
  useLazyQuery,
  useMutation
} from '@apollo/client'
import ProductBatches from './ProductsBatch'
import {
  GET_CAT_QUERY
} from '../../Med/Categories'
import {
  SearchOutlined,
  FilterOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons'
import './ProductsList.css'
import {
  numberWithCommas
} from '../../../utils/Match'
import { useHotkeys } from 'react-hotkeys-hook';
import LoadingScreen from '../../../utils/LoadingScreen'
import {
  usePageTitle
} from '../../../utils/NameContext'

const { Option } = Select

const SINGLE_SHOP_PRODUCTS = gql`
  query loadShopProducts($shop: String!, $limit: Int, $currentPage: Int, $name: String, $category: [String]) {
    loadShopProducts(shop: $shop, limit: $limit, currentPage: $currentPage, name: $name, category: $category) {
      products {
        _id
        code
        name
        unit
        avgPrice
        sellPrice
        product {
          _id
          name
        }
        shop
        stock
        category {
          name
        }
      }
      total
    }
  }
`
const UPDATE_SELL_PRICE = gql`
  mutation ownerUpdateSellPrice($shopProduct: String!, $newPrice: Float!) {
    ownerUpdateSellPrice(shopProduct: $shopProduct, newPrice: $newPrice) {
      _id
      name
      unit
      avgPrice
      sellPrice
      # product
      shop
      stock
      category {
        name
      }
    }
  }
`

const ProductsList = ({
  loading,
  onAddStock,
  shop,
}) => {
  // const limit = 100
  // const [limit, setLimit] = React.useState(100)
  const { state: {settings: { productsPerPage: limit }}} = usePageTitle()
  const nameInputRef = React.useRef(null)
  const [form] = Form.useForm()
  const [currentRec, setCurrentRec] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState({
    name: '',
    category: null
  })
  const [filtersVisible, setFilterVisible] = React.useState({
    name: false,
    otherValue: false
  })
  const { data: catData = { getCategories: [] }} = useQuery(GET_CAT_QUERY)

  // const testLoading = true
  
  const [loadPrds, {
    loading: shopPrdLoading,
    data: shopPrdData = { loadShopProducts: { products: [], total: 0 }},
    // error: shopPrdError,
    fetchMore: loadMorePrds
  }] = useLazyQuery(SINGLE_SHOP_PRODUCTS, { variables: { shop: shop._id, limit, currentPage: currentPage - 1, ...searchTerm }, fetchPolicy: 'network-only'})
  const [updatePrice, { loading: updatingPrice }] = useMutation(UPDATE_SELL_PRICE)
  React.useEffect(() => {
    const load = async () => {
      await loadPrds({ variables: {
        shop: shop._id,
        limit,
        currentPage: currentPage - 1,
        ...searchTerm
      }})
    }
    load()
  }, [shop, limit, currentPage, loadPrds, searchTerm])

  const updateSearch = async (key, value) => {
    for (var k in searchTerm) {
      if (k === key) searchTerm[key] = value
    }
    setCurrentPage(1)
    setSearchTerm(searchTerm)
    // console.log(searchTerm)
    // fetch server data.. 
    // setFilterVisible({ name: false })
    await loadPrds({ variables: {
      shop: shop._id,
        limit,
        currentPage: currentPage - 1,
        ...searchTerm
    }})
  }
  let searchNameTimeout = null
  const updateName = e => {
    if (searchNameTimeout) {
      clearTimeout(searchNameTimeout)
    }
    searchNameTimeout = setTimeout(() => {
      updateSearch('name', e.target.value)
    }, 800)
  }

  const updateSellPrice = async rec => {
    const data = form.getFieldsValue()
    // console.log(data)
    // console.log(rec)
    if (data.sellPrice && rec._id) {
      await updatePrice({
        variables: {
          shop: shop._id,
          shopProduct: rec._id,
          newPrice: parseFloat(data.sellPrice)
        },
      })
      setCurrentRec(null)
      form.resetFields()
    }
  }

  const productColumns = [
    {
      key: '_name',
      title: filtersVisible.name ? <Input onChange={updateName} suffix={<CloseOutlined onClick={() => { updateSearch('name', ''); setFilterVisible({ name: false})}} />} ref={nameInputRef} /> : <Tooltip title="Bấm CTRL+F để tìm hàng theo tên">Tên (Ctrl + F)</Tooltip>,
      render: record => <span>{record.name}</span>,
      filterIcon: searchTerm.name ? <FilterOutlined onClick={() => updateSearch('name', null)} /> : <SearchOutlined />,
      filterDropdown: null 
    },
    {
      key: '_cat',
      title: 'Danh mục',
      dataIndex: ['category', 'name'],
      filterIcon: searchTerm.category ? <FilterOutlined onClick={() => updateSearch('category', null)} /> : <SearchOutlined />,
      filterDropdown:(
        <CatSearchDropdown
          name="category"
          updateSearch={updateSearch}
          categories={catData.getCategories}
          mode="multiple"
          allowClear={true}
        />
      )
    },
    {
      key: '_unit',
      title: 'Đơn vị',
      dataIndex: 'unit'
    },
    {
      key: 'stk',
      dataIndex: 'stock',
      title: 'Tồn kho'
    },
    {
      key: 'avgPrc',
      title: 'Giá BQGQ',
      // dataIndex: 'avgPrice',
      render: record => <span>{record.avgPrice ? numberWithCommas(record.avgPrice) : '0'}</span>
    },
    {
      key: 'sellPrc',
      dataIndex: 'sellPrice',
      title: <span>Giá bán <EditOutlined /></span>,
      render: (text, record) => (<Tooltip title="Click đúp để sửa">
          <strong>{text ? numberWithCommas(text) : 0}</strong>
        </Tooltip>),
      editable: true
    },
    // {
    //   key: 'actions',
    //   title: 'Add/Nhập hàng',
    //   render: record => <Button onClick={() => openExistingPrdModal({ _id: record.product, sellPrice: record.sellPrice })} size="small">Thêm</Button>
    // }
  ].map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: record => ({
          record,
          loading: updatingPrice,
          dataIndex: col.dataIndex,
          editing: currentRec === record._id,
          editable: col.editable,
          onClick: () => {
            setCurrentRec(record._id)
            form.setFieldsValue({ sellPrice: record[col.dataIndex] })
          },
          onUpdate: () => updateSellPrice(record)
        })
      }
    }
    return col
  })

  const load = async (page, pageSize) => {
    // console.log(page, pageSize)
    setCurrentPage(page)
    await loadMorePrds({
      variables: { currentPage: page - 1, limit: pageSize },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          loadShopProducts: {
            products: fetchMoreResult.loadShopProducts.products,
            total: fetchMoreResult.loadShopProducts.total
          }
        }
      }
    })
  }

  useHotkeys('ctrl+f', (e) => {
    e.preventDefault()
    // const { name } = filtersVisible
    setFilterVisible({
      name: true
    })
    nameInputRef.current.focus()
  })
  useHotkeys('ctrl+x', e => {
    e.preventDefault()
    setFilterVisible({ name: false})
  })

  if (shopPrdLoading) {
  // if (testLoading) {
    return (
      <LoadingScreen text="Đang tải dữ liệu hàng, vui lòng đợi" />
    )
  }
  return (
    <Form form={form}>
      <Table size="large" rowKey={rec => rec._id}
        dataSource={shopPrdData.loadShopProducts.products}
        columns={productColumns}
        loading={shopPrdLoading}
        expandable={{
          expandedRowRender: (record) =>{
            console.log(record.product)
            return (<ProductBatches productName={record.name} product={record.product._id} shop={shop} />)
          }
        }}
        pagination={{
          current: currentPage,
          total: shopPrdData.loadShopProducts.total,
          pageSize: limit,
          onChange: async (page, pageSize) => load(page, pageSize)
        }}
        components={{
          body: {
            cell: EditablePriceCell
          }
        }}
      />
    </Form>
  )
}

export default ProductsList

export const SearchFilter = ({
  updateSearch,
  name
}) => {
  const [form] = Form.useForm()
  const update = () => {
    const values = form.getFieldsValue()
    updateSearch(name, values[name])
  }
  return (
    <div style={{ padding: '1em' }}>
      <Form form={form} layout="vertical">
        <Form.Item name={name} label="Nhập từ khóa">
          <Input />
        </Form.Item>
      </Form>
      <Form.Item>
        <Button onClick={update}>Ok</Button>
      </Form.Item>
    </div>
  )
}

export const CatSearchDropdown = ({
  updateSearch,
  name,
  categories,
  mode,
  allowClear
}) => {
  const [form] = Form.useForm()
  const update = () => {
    
    const values = form.getFieldsValue()
    updateSearch(name, values[name])
  }
  return (
    <div style={{ padding: '1em', minWidth: '400px' }}>
      <Form form={form} layout="vertical">
        <Form.Item name={name} label="Chọn danh mục">
          <Select
            mode={mode}
            allowClear={allowClear}
          >
            {categories.map(cat => (
              <Option key={cat._id} value={cat._id}>{cat.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button onClick={update}> OK </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

const EditablePriceCell = ({
  record,
  editing,
  children,
  dataIndex,
  editable,
  onClick,
  onUpdate,
  loading,
  ...restProps
}) => {

  return (
    <td {...restProps}>
      {editable && editing ? (
        <Form.Item name={dataIndex}>
          <Input disabled={loading} style={{ width: '120px'}} suffix="VND" onPressEnter={onUpdate} />
        </Form.Item>
        )
      : (
        editable ? <span className={editable ? 'ediablePointer' : ''}  onDoubleClick={() => onClick(record._id)}>
          {children}
        </span>
        :
        children
      )
      }
    </td>
  )
}
