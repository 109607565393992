import React from 'react'
import {
  Button,
  Card,
  Input,
  Form,
  List
} from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

const UnitSettings = ({
  units,
  updateUnits
}) => {
  const [form] = Form.useForm()
  const [adding, toggleAdding] = React.useState(false)
  const [removing, toggleRemoving] = React.useState(false)

  const addUnit = async () => {
    toggleAdding(true)
    const { unit } = form.getFieldsValue()
    if (unit) {
      const newUnits = [unit].concat(units || [])
      await updateUnits(newUnits)
    }
    form.resetFields()
    toggleAdding(false)
    return
  }

  const removeUnit = async e => {
    toggleRemoving(e)
    // console.log(e)
    const newUnit = units.filter(u => u !== e)
    await updateUnits(newUnit)
    toggleRemoving(false)
  }
  return (
    <Card title="Đơn vị" bordered>
      <div>
        <Form form={form}>
          <Form.Item name="unit" label="Thêm đơn vị">
            <Input disabled={adding} onPressEnter={() => addUnit()} suffix={<Button size="small" icon={<PlusOutlined onClick={() => addUnit()} />} type="primary" loading={adding} />} />
          </Form.Item>
        </Form>
        <List
          header={<strong>Đơn vị đã có</strong>}
        >
          {units && units.map(u => (
            // <Tag closable onClose={() => removeUnit(u)}>{u}</Tag>
            <List.Item
              extra={<Button loading={u === removing} icon={<DeleteOutlined onClick={() => removeUnit(u)} />} />}
            >
              {u}
            </List.Item>
          ))}
        </List>
      </div>
    </Card>
  )
}

export default UnitSettings
