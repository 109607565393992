import React from 'react'
import {
  Modal,
  Button,
  // Table
} from 'antd'
import InvList from './InvList'
import Title from './Title'

const InventoryResult = ({
  shop,
  inv,
  visible,
  onCancel
}) => {
  // const []
  return (
    <Modal
      width="100%"
      visible={visible}
      title={<Title shop={shop} inv={inv} />}
      onCancel={onCancel}
      style={{
        height: '100px',
        // left: 0,
        top: 10,
        position: 'fixed'
      }}
      closeIcon={() => null}
      footer={(
        <Button onClick={onCancel}>Đóng</Button>
      )}
    >
      <InvList
        shop={shop}
        inv={inv}
      />
    </Modal>
  )
}
export default InventoryResult