import React from 'react';
import {
  List,
  message,
  // Descriptions,
  // Divider,
  Popconfirm,
  Button,
  Menu,
  // Typography
} from 'antd';

import {
  useMutation,
  useApolloClient,
  gql
} from '@apollo/client';
import { UserOutlined } from '@ant-design/icons';
// import { ASSIGN_AGENT } from '../../SaleAgents';
import AssignAgentModal from './AssignAgentModal';
import {
  Link
} from 'react-router-dom'

const ASSIGN_AGENT = gql`
  mutation assignAgent($agent: String!, $shop: String!) {
    assignAgentToShop(agent: $agent, shop: $shop) {
      name
      _id
    }
  }
`;

const REVOKE_AGENT = gql`
  mutation revoke($agent: String!, $shop: String!) {
    revokeAgent(agent: $agent, shop: $shop) {
      _id
      name
      address
      time {
        open
        close
      }
      roles {
        user {
          _id
          name
          profile {
            avatar
          }
          email
        }
        role
      }
    }
  }
`
const SINGLE_SHOP_QUERY = gql`
  query getShop($id: String!){
    getShop(id: $id) {
      _id
      name
      address
      time {
        open
        close
      }
      roles {
        user {
          _id
          name
          profile {
            avatar
          }
          email
        }
        role
      }
    }
  }
`;

const AgentsList = ({ shop, agents }) => {
  const client = useApolloClient();
  const [saleModal, toggleSaleModal] = React.useState(false);
  const [assignAgent, { loading, error }] = useMutation(ASSIGN_AGENT);
  const revoke = useMutation(REVOKE_AGENT)

  const revokeAgent = async (agent, shop) => {
    await revoke[0]({
      variables: {
        agent,
        shop
      }
    })
    .then(res => {
      const data = client.readQuery({query: SINGLE_SHOP_QUERY});
      client.writeQuery({
        query: SINGLE_SHOP_QUERY,
        data: {
          ...data,
          getShop: {
            ...res.data.revokAgent
          }
        }
      })
    })
    .catch(e => console.log(e));
  }

  const makeAssignment = async (agent) => {
    await assignAgent({ variables: {
      agent,
      shop: shop._id
    }})
    // console.log(agent);
    // console.log(shop);
  }
  if (error) {
    message.error('something went wrong, cannot assign agent to shop');
  }
  return  (
    <div>
      <Menu style={{ textAlign: 'right' }}>
        <Menu.Item>
            <Button onClick={() => toggleSaleModal(true)}>
              <UserOutlined />
              Thêm nhân viên
            </Button>
        </Menu.Item>
      </Menu>
      <List>
        {shop.roles.map(r => (
          <List.Item
            key={r.user.email}
            actions={r.role === 'agent' ? [
              <Popconfirm
                key="remove"
                title="Bỏ nhân viên khỏi cửa hàng"
                onConfirm={() => revokeAgent(r.user._id, shop._id)}
                onCancel={() => null}
                okText="Đồng ý"
                cancelText="Hủy"
              >
                <Button type="link">Xóa</Button>
              </Popconfirm>
            ]:null}
          >
            <List.Item.Meta
              avatar={<UserOutlined />}
              title={<Link to={`/agents/${r.user._id}`}>{r.user.name}</Link>}
              description={`Email/SDT: ${r.user.email} - Vai trò: ${r.role === 'owner' ? 'Chủ' : 'Nhân viên'}`}
            />
          </List.Item>
        ))}
      </List>
      <AssignAgentModal
        agents={agents}
        visible={saleModal}
        onOk={()=>toggleSaleModal(false)}
        onCancel={() => toggleSaleModal(false)}
        shop={shop}
        makeAssignment={makeAssignment}
        loading={loading}
      />
    </div>
  );
}
export default AgentsList
