import React from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
} from '@apollo/client'
import {
  ShoppingCartOutlined
} from '@ant-design/icons'
// import { numberWithCommas } from '../../utils/Match'

import {
  Space,
  // Result,
  Button,
  PageHeader,
  DatePicker,
  Alert,
  // Form,
} from 'antd';
import moment from 'moment'
import {
  useParams,
  useHistory
} from 'react-router-dom'
import SaleDrawer from './SaleDrawer'
// import SaleRecords from './SaleRecords'
import TodaySaleRecords from './SaleRecords/TodaySaleRecords'

const { RangePicker } = DatePicker

const AGENT_LOAD_SHOP = gql`
  query saleLoadShop($shop:String!) {
    agentLoadShop(shop: $shop) {
      name
      address
    }
  }
`
const ADD_SALE_MUT = gql`
  mutation addSale($shop: String!, $products: [ProductSaleInput], $note: String, $saleType: String) {
    addSale(shop: $shop, products: $products, note: $note, saleType: $saleType) {
      _id
    shop {
      name
    }
    products {
      _id
      product {
        name
        unit
      }
      quantity
      sellPrice
    }
    total
    note
    created
    }
  }
`
export const AGENT_LOAD_TODAY_SALE = gql`
  query loadSales($shop:String!, $startDate:String, $endDate:String) {
  agentLoadSaleNew(shop: $shop, startDate: $startDate, endDate: $endDate) {
    _id
    _id
    products {
      _id
      product {
        _id
        name
        unit
      }
      # avgPrice
      quantity
      sellPrice
    }
    total
    note
    created
  }
}
`

const Sale = () => {
  const history = useHistory()
  const { shop } = useParams()
  const [range, setRange] = React.useState([moment().startOf('day'),moment().endOf('day')])
  const [saleDrwVis, toggleSaleDrw] = React.useState(false)
  const [agentLoadShop, { loading, data, error: loadShopError }] = useLazyQuery(AGENT_LOAD_SHOP, { fetchPolicy: 'network-only'})
  const [addSale, { loading: addingSale }] = useMutation(ADD_SALE_MUT)
  React.useEffect(() => {
    const loadShop = async () => {
      agentLoadShop({ variables: { shop }})
    }
    loadShop()
  }, [agentLoadShop, shop])
  const submitSale = async data => {
    await addSale(
      {
        variables: { ...data },
        update: async (cache, result) => {
          // console.log(cache.readQuery)
          let oldData = await cache.readQuery({
            query: AGENT_LOAD_TODAY_SALE,
            variables: {
              shop,
              startDate: range[0].format('DD/MM/YYYY'),
              endDate: range[1].format('DD/MM/YYYY')
            }})
          console.log(oldData)
          cache.writeQuery({
            query: AGENT_LOAD_TODAY_SALE,
            variables: { shop },
            data: {
              ...oldData,
              agentLoadSaleNew: [
                result.data.addSale
                // , ...oldData.agentLoadSaleNew
              ]
            }
          })
        }
      });
  }
  const updateRange = dates => {
    // console.log(dates)
    // console.log(range)
    if (dates && dates[0] && dates[1]) {
      setRange(dates)
    }
  }

  if (loadShopError) {
    return (
      <Alert
        type="error"
        message="Không thể tải được dữ liệu"
        showIcon
      />
    )
  }
  return (
    <div>
      <PageHeader
        onBack={() => history.goBack()}
        title="Bán hàng"
        subTitle={(
          <Space size={30}>
            <RangePicker format="DD/MM/YYYY" onChange={updateRange} />
            <Button onClick={() => setRange([moment(), moment()])}>Hôm nay</Button>
          </Space>
        )}
        extra={(
          <Button type="primary" size="large" onClick={() => toggleSaleDrw(true)}>
            <ShoppingCartOutlined />
            Đơn hàng mới
          </Button>
        )}
      />
      {(loading) && (
        <p>loading.....</p>
      )}
      {data && data.agentLoadShop && (
        <SaleDrawer
          visible={saleDrwVis}
          onCancel={() => toggleSaleDrw(false)}
          onOk={() => toggleSaleDrw(false)}
          shop={shop}
          // products={data.agentLoadShop.products}
          addSale={submitSale}
          addingSale={addingSale}
        />
      )}
      <TodaySaleRecords
        range={range}
        shop={shop}
      />
      {/* {!saleLoading && saleData && (
        <SaleRecords
          sales={saleData.agentLoadSales}
        />
      )} */}
    </div>
  );
}

export default Sale;
