import React, { useState } from 'react';
import {
  Menu,
  Button,
  Dropdown,
  Space
} from 'antd';
import {
  useAuth
} from '../utils/Context';
import JWT from 'jsonwebtoken';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  useHistory,
  Link
} from 'react-router-dom'
const iconStyle = {
  fontSize: '24px'
}


const NavLinks = ({
  sidebar,
  toggleSidebar,
  showToggle,
}) => {
  // const { state } = usePageTitle()
  // console.log('here the state', state)
  const [current, setCurrent] = useState('home');
  const auth = useAuth();
  const history = useHistory()
  const decoded = JWT.decode(localStorage.getItem('ACCESS_TOKEN'));
  const handleMenuClick = e => setCurrent(e.value);
  console.log(auth.getUser())
  const logout = async () => {
    await auth.logOut()
    history.push('/login')
  }
  return (
    <div>
      {decoded && (
        <Menu selectedKeys={[current]} onClick={e => handleMenuClick(e)} mode="horizontal">
          {showToggle && (
            <Button
              key="_one"
              icon={ sidebar ? <MenuUnfoldOutlined style={iconStyle} /> : <MenuFoldOutlined style={iconStyle} /> }
              onClick={() => toggleSidebar(!sidebar)}
            >
            </Button>
          )}
          <div key="_Two" style={{ float: 'right', marginRight: '2em'}}>
            <Space>

            <span>
              {auth.getUser() ? auth.getUser().name : ''}
            </span>
            <Dropdown overlay={<ProfDrop logout={logout} />}>
              <Button icon={<UserOutlined />} shape="circle" />
            </Dropdown>
            </Space>
          </div>
        </Menu>
      )}
    </div>
  );
}

const ProfDrop = ({
  logout
}) => (
  <Menu mode="vertical" theme="dark" style={{ minWidth: '200px'}}>
    <Menu.Item icon={<UserOutlined />}>
      <Link to="/profile">
        Tài khoản
      </Link>
    </Menu.Item>
    <Menu.Item icon={<SettingOutlined />}>
      <Link to="/settings">
        Thiết lập
      </Link>
    </Menu.Item>
    <Menu.Item icon={<LogoutOutlined />} onClick={() => logout()}>Đăng xuất</Menu.Item>
  </Menu>
)

export default NavLinks;