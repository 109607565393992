import React from 'react'
import {
  Table,
  // Badge,
  Popconfirm,
  Spin,
  Input,
  Tooltip
} from 'antd'
import moment from 'moment'
import {
  LoadingOutlined,
  DeleteOutlined,
  CloseOutlined,
  // FilterOutlined
} from '@ant-design/icons'
import { numberWithCommas } from '../../../utils/Match'
import {
  gql,
  useMutation,
  // useQuery,
  useLazyQuery
} from '@apollo/client'
import {
  useHotkeys
} from 'react-hotkeys-hook'

const AGENT_LOAD_TODAY_SALE = gql`
  query loadSales($shop:String!, $startDate:String, $endDate:String, $search: SaleSearchInput) {
  agentLoadSaleNew(shop: $shop, startDate: $startDate, endDate: $endDate, search: $search) {
    _id
    products {
      _id
      product {
        _id
        name
        unit
      }
      # avgPrice
      quantity
      sellPrice
    }
    total
    note
    created
  }
}
`

const DELETE_SALE = gql`
  mutation removeSaleRec($shop: String!, $sale: String!) {
    removeSaleRec(shop: $shop, sale: $sale) {
      sale
    }
  }
`
const REMOVE_SINGLE_PRD = gql`
  mutation removePrd($shop: String!, $sale: String!, $product: String!) {
    removeSaleProduct(shop: $shop, sale: $sale, product: $product) {
      _id
      products {
        _id
        product {
          name
          unit
        }
        quantity
        sellPrice
      }
    }
  }
`
export const showDate = dateNum => {
  const date = moment(dateNum)
  if (date.isSame(moment(), 'day')) {
    return `Today: ${date.format('HH:mm:ss')}`
  }
  return date.format('DD/MM/YY - HH:mm')
}

const TodaySaleRecords = ({
  // data,
  // loading,
  range,
  shop
}) => {
  const prdRef = React.useRef(null)
  const noteRef = React.useRef(null)
  const [recordToRemove, setRec] = React.useState(null)
  const [search, setSearch] = React.useState({
    product: '',
    note: '',

  })
  const [filter, toggleFilter] = React.useState({
    product: false,
    note: false
  })
  const [removeRec, { loading: removing }] = useMutation(DELETE_SALE)
  const [removeSingle] = useMutation(REMOVE_SINGLE_PRD)
  const [loadSales, {loading: salesLoading, data}] = useLazyQuery(
    AGENT_LOAD_TODAY_SALE,
    {
      variables:{ 
        shop,
        startDate: range[0].format('DD/MM/YYYY'),
        endDate: range[1].format('DD/MM/YYYY')  
      },
      fetchPolicy: 'network-only'
    }
  )
  const [removeSingleKey, setRemoveSingleKey] = React.useState({
    product: null,
    sale: null
  })
  React.useEffect(() => {
    const load = async () => {
      if (range) {
        await loadSales({
          variables: {
            shop,
            startDate: range[0].format('DD/MM/YYYY'),
            endDate: range[1].format('DD/MM/YYYY'),
            search
          }
        })
      }
    }
    load()
  }, [loadSales, range, shop, search])

  const showFilter = (key, val) => {
    const newFilter = { ...filter}
    newFilter[key] = val
    toggleFilter(newFilter)
    if (!val) {
      const newSearch = { ...search }
      newSearch[key] = ''
      setSearch(newSearch)
    }
  }
  const updateSearch = (key, value) => {
    const newSearch = { ...search }
    newSearch[key] = value
    setSearch(newSearch)
  }

  const remove = async rec => {
    // console.lo
    setRec(rec._id)
    await removeRec(
      {
        variables: {
          shop,
          sale: rec._id
        },
        update: cache => {
          let data = cache.readQuery({ query: AGENT_LOAD_TODAY_SALE, variables: {
            shop,
            startDate: range[0].format('DD/MM/YYYY'),
            endDate: range[1].format('DD/MM/YYYY'),
            search
          }})
          // console.log(data)
          let newData = {
            ...data,
            agentLoadSaleNew: data.agentLoadSaleNew.filter(sale => sale._id !== rec._id)
          }
          console.log(newData)
          cache.writeQuery({ query: AGENT_LOAD_TODAY_SALE, variables: { shop }, data: newData })
        }
      }
    )
    setRec(null)
  }
  const removeS = async data => {
    setRemoveSingleKey({...data})
    await removeSingle({
      variables: { ...data, shop },
      update: (cache, response) => {
        const { data: { removeSaleProduct } } = response
        if (!removeSaleProduct._id) {
          const oldData = cache.readQuery({
            query: AGENT_LOAD_TODAY_SALE,
            variables: {
              shop,
              startDate: range[0].format('DD/MM/YYYY'),
              endDate: range[1].format('DD/MM/YYYY')
            }})
          const newData = {
            ...oldData,
            agentLoadSaleNew: oldData.agentLoadSaleNew.filter(alsn => alsn._id !== data.sale)
          }
          cache.writeQuery({ query: AGENT_LOAD_TODAY_SALE, variables: { shop }, data: newData })
        }
      }
    })
  }
  const columns = [
    {
      key: 'time',
      title: 'Thời gian',
      render: record => <span>{ showDate(parseFloat(record.created)) }</span>
    },
    {
      key: '_prd',
      title: filter.product ?
        <Input placeholder={search.product} ref={prdRef} onPressEnter={(e) => updateSearch('product', e.target.value)} suffix={<CloseOutlined onClick={() => showFilter('product', false)} />} />
        :
        <span>Sản phẩm (Ctrl+P)</span>,
      render: record => {
        const { products } = record
        // console.log(products)
        return (
          <>
            {products.map(p => (
              <span key={p._id}>
                {`${p.product.name}(${p.quantity})`}
              </span>
            ))}
          </>
        )
      }
    },
    {
      key: '_note',
      title: filter.note ? 
        <Input placeholder={search.note} ref={noteRef} suffix={<CloseOutlined onClick={() => showFilter('note', false)} />} onPressEnter={e => updateSearch('note', e.target.value)} />
        :
        <Tooltip title="Tìm kiếm theo ghi chú bằng cách nhấn Ctrl + X">
          <span>Ghi chú (Ctrl+X)</span>,
        </Tooltip>,
      dataIndex: 'note'
    },
    {
      key:'_total',
      title: 'Tổng',
      render: record => {
        let total = 0
        record.products.forEach(p => {
          total += p.quantity * p.sellPrice
        })
        return (<strong>{`${numberWithCommas(total)} VND`}</strong>)
      }
    },
    {
      key: '_action',
      title: 'Xóa',
      render: record => (
        <Popconfirm
          title="Bạn có chắc xóa không?"
          onConfirm={() => remove(record)}
        >
          {removing && record._id === recordToRemove ? <Spin indicator={<LoadingOutlined spin />} /> :<DeleteOutlined />}
        </Popconfirm>
      )
    }
  ]
  useHotkeys('ctrl+p', (e) => {
    e.preventDefault()
    showFilter('product', true)
    prdRef.current.focus()
  })
  useHotkeys('ctrl+x', e => {
    e.preventDefault()
    showFilter('note', true)
    noteRef.current.focus()
  })
  if (salesLoading) {
    return <span>loading ....</span>
  }
  
  if (data && data.agentLoadSaleNew) {
    return (
    <Table
      rowKey={(record) => record._id}
      columns={columns}
      dataSource={data.agentLoadSaleNew}
      loading={salesLoading}
      expandable={{
        expandedRowRender: record => {
          const { products } = record
          const columns = [
            {
              key: '_idx',
              title: 'STT',
              render: (_, __, index) => <span>{index + 1}</span>
            },
            {
              key: '_prd',
              title: 'Sản phẩm',
              dataIndex: ['product','name'],
              
            },
            {
              key: '_quan',
              title: 'Số lượng',
              dataIndex: ['quantity']
            },
            {
              key: '_unit',
              title: 'Đơn vị',
              dataIndex: ['product', 'unit']
            },
            {
              key: '_prc',
              title: 'Giá bán',
              // dataIndex: 'sellPrice'
              render: record => <span>{numberWithCommas(record.sellPrice)}</span>
            },
            {
              key: '_total',
              title: 'Tổng(VND)',
              render: record => numberWithCommas(record.quantity * record.sellPrice)
            },
            {
              key: '_act',
              title: 'Xóa',
              render: (rec) => (
                <Popconfirm title="Remove Product?" onConfirm={() => removeS({ sale: record._id, product: rec.product._id })}>
                  {removeSingleKey.sale === record._id && removeSingleKey.product === rec.product._id ? <Spin indicator={<LoadingOutlined spin />} /> :<CloseOutlined />}
                </Popconfirm>
              )
            }
          ]
          return (
            <Table
              pagination={false}
              dataSource={products}
              columns={columns}
            />
          )
        }
      }}
      summary={pageData => {
        let total = 0
        pageData.forEach(sr => {
          sr.products.forEach(pr => {
            total += pr.quantity * pr.sellPrice
          })
          // total += sr.total
        })
        return (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={4}>Tổng</Table.Summary.Cell>
          <Table.Summary.Cell><strong>{`${numberWithCommas(total)} VNĐ`}</strong></Table.Summary.Cell>
        </Table.Summary.Row>
        )
      }}
      pagination={false}
    />
  )
}
return null
}
export default TodaySaleRecords
