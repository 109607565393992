import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import {
  AuthProvider,
  // useAuth,
} from './utils/Context';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import AuthenticatedRoute from './utils/ProtectedRoute';
// import Nav from './Nav';
import Home from './Home';
import Login from './Login';
import About from './About';
import Register from './Register';
// import Profile from './Profile'



function App() {
  return (
    <AuthProvider>
        <Router>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          {/* <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />
          <AuthenticatedRoute path="/profile" component={Profile} /> */}
          <Route path="/about" exact component={About} />
          <AuthenticatedRoute path="/" component={Home} />
        </Switch>
      </Router>
    </AuthProvider>
    );
}

export default App;
