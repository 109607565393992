import React from 'react'
import {
  BarChart,
  Bar,
  // Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const MonthBar = ({
  byMonth,
  xKey
}) => {
  return (
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={500}
          height={300}
          data={byMonth}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="thisMonth" fill="#0088FE" />
          <Bar dataKey="lastMonth" fill="#FFBB28" />
        </BarChart>
      </ResponsiveContainer>
    )
}

export default MonthBar