import React from 'react'
import {
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Select,
  message
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import {
  gql,
  useLazyQuery
} from '@apollo/client'
import {
  useHotkeys
} from 'react-hotkeys-hook'

const OWNER_SEARCH_PRDS = gql`query ownerSearchPrds($shop:String!, $keyword:String!) {
  ownerSearchPrds(shop:$shop, keyword:$keyword) {
    _id
    product {
      _id
      name
      unit
      # manufacture
      # country
      # position
      # element
    }
    stock
    sellPrice
    avgPrice
  }
}`

const { Option } = Select

const SaleForm = ({
  add,
  shop,
  setCurrentPrd,
  currentPrd
}) => {
  const [form] = Form.useForm()
  const productInput = React.useRef(null)
  let searchTimeout = null
  const [search, { loading: searching, data: products = { ownerSearchPrds: [] } }] = useLazyQuery(OWNER_SEARCH_PRDS)
  const [sellPrice, setSellPrice] = React.useState(0)
  const [adding, toggleAdding] = React.useState(false)
  
  const onSearch = async (keyword) => {
    // console.log(keyword)
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(() => {
      search({
        variables: {
          shop,
          keyword
        }
      })
    }, 800)
  }

  const onSelect = (option) => {
    if (products.ownerSearchPrds && option) {
      products.ownerSearchPrds.forEach(p => {
        if (p.product._id.toString() === option.toString()) {
          setCurrentPrd(p)
          form.setFieldsValue({
            unit: p.product.unit
          })
          setSellPrice(p.avgPrice)
          // setCurrentUnit(p.product.unit)
        }
      })
    }
  }

  const addP = async () => {
    const {
      product,
      quantity,
    } = form.getFieldsValue()
    if (!product || quantity <= 0) {
      message.error('Không thể thêm được sản phẩm')
      return
    }
    if (!currentPrd) {
      message.error('Vui lòng chọn một sản phẩm')
      return
    }
    toggleAdding(true)
    // console.log(product)
    // console.log(quantity)
    // console.log(currentPrd)
    if (currentPrd.stock < quantity) {
      message.error('Không còn đủ số hàng tồn để xuất bán')
      return
    }
    await add({
      product,
      quantity,
      sellPrice: currentPrd.avgPrice
    })
    form.resetFields()
    productInput.current.focus()
    toggleAdding(false)
  }

  useHotkeys('ctrl+f', e => {
    e.preventDefault()
    productInput.current.focus()
  })
  return (
    <Form form={form} layout="vertical">
      <Row gutter={6}>
        <Col xs={10}>
          <Form.Item name="product" label="Sản phẩm (Ctrl+F)">
            <Select
              ref={productInput}
              showSearch
              onSearch={onSearch}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return true
              }}
              onSelect={onSelect}
              loading={searching}
            >
              {products.ownerSearchPrds.map(prd =>(<Option key={prd.product._id} value={prd.product._id}>{prd.product.name}</Option>))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Đơn vị" name="unit">
            <Input  disabled />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item name="quantity" label="Số lượng">
            <InputNumber
              max={currentPrd ? currentPrd.stock : null}
              onChange={val => {
                // console.log(val)
                form.setFieldsValue({total: val * sellPrice})
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item label="Thành tiền" name="total">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item label=" ">
            <Button
              loading={adding}
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => addP()}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default SaleForm
