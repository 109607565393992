import React from 'react';
import ShopCard from './ShopCard';
import {
  Row,
  Col,
  Result,
  PageHeader,
  Button
} from 'antd';
import {
  gql,
  useQuery
} from '@apollo/client';
import AddShopModal from './AddShopModal'
import LoadingScreen from '../../utils/LoadingScreen';

export const GET_MY_SHOPS = gql`
  query loadMyShops {
    loadMyShops {
      _id
      name
      address
      time {
        open
        close
      }
      roles {
        user {
          _id
          name
        }
        role
      }
    }
  }
`
const Header = ({ user, history, showShopModal }) => {
  if (user.role === 'shopowner') {
    return (
      <PageHeader
        ghost={false}
        // onBack={() => { history.goBack()}}
        title="Cửa hàng"
        subTitle="Quản lý cửa hàng và bán hàng"
        extra={[<Button onClick={showShopModal} key="add_shop" type="primary">Thêm cửa hàng</Button>
        ]}
      />
    );
  }
  else {
    return (
      <PageHeader
        ghost={false}
        title="Cửa hàng"
        subTitle="Click vào cửa hàng và bắt đầu bán bàng"
      />
    )
  }
}


const ShopsList = ({ user }) => {
  const [addShopModal, toggleAddShopModal] = React.useState(false);
  const showShopModal = () => toggleAddShopModal(true);
  const {loading: shopsLoading, data: shopsData, error: shopError} = useQuery(GET_MY_SHOPS);
  // console.log(user);

  if (shopsLoading) {
    return <LoadingScreen text="Đang tải dữ liệu cửa hàng" />
  }
  return (
    <div>
      {user && (
        <Header
          showShopModal={showShopModal}
          user={user}
          style={{ padding: '0' }}
        />
      )}
      <Row gutter={16} justify="center">
        {shopError && (
          <Result
            status="error"
            title="Đã có lỗi xẩy ra trong quá trình tải dữ liệu"
            />
            )}
        {shopsData && (
          shopsData.loadMyShops.map(shop => (
            <Col span={8} key={shop._id}>
              <ShopCard user={user} key={shop.name} shop={shop} />
            </Col>
          ))
          )}
      </Row>
      <AddShopModal
          visible={addShopModal}
          // onOk={(data) => addShop(data)}
          onCancel={() => toggleAddShopModal(false)}
        />
    </div>
  );
}

export default ShopsList;