import React from 'react';
import {
  Menu,
  Layout,
  Alert,
  Row,
  Col,
  // Button
} from 'antd';
import moment from 'moment'
import {
  HomeOutlined,
  ShopOutlined,
  UserOutlined,
  // AppstoreAddOutlined,
  PieChartOutlined,
  // SnippetsOutlined,
  ExperimentOutlined
} from '@ant-design/icons';
import {
  Route,
  useLocation,
  Switch,
  Link,
  Redirect
} from 'react-router-dom';
// import DefaultHome from './default';
import Nav from '../Nav';
import ShopMgt from './Shops';
import AgentMgt from './SaleAgents';
import Sale from './Sale';
import Med from './Med';
import Report from './Reports'
import Profile from '../Profile'
import Settings from './settings'
// import {
//   useAuth,
// } from '../utils/Context';
import {
  gql,
  useQuery,
} from '@apollo/client'
import LoadingScreen from '../utils/LoadingScreen';
import {
  useAuth
} from '../utils/Context'
import NameProvider from '../utils/NameContext';

// const { SubMenu } = Menu;

const {
  Content,
  Sider,
} = Layout;

export const GET_MY_PROFILE = gql`
  query getMe {
    me {
      name
      email
      role
      expDate
      profile {
        phones
        address {
          city
          dist
          detail
        }
      }
    }
  }
`;

const Home = () => {
  const {
    setUser
  } = useAuth()
  const [showSider, toggleSider] = React.useState(true);
  const { data, loading, error } = useQuery(GET_MY_PROFILE, { fetchPolicy: "network-only" });
  const location = useLocation();

  React.useEffect(() => {
    const setUprofile = () => {
      if (data && data.me) {
        setUser(data.me)
      }
    }
    setUprofile()
  }, [data, setUser])

  if (loading && !error) {
    return <LoadingScreen text="Đang tải dữ liệu" />
  }
  if (error) {
    return <Redirect to="/login" />
  }
  let showAlert = false
  let daysDiff
  if (data.me && data.me.expDate) {
    daysDiff = moment(parseFloat(data.me.expDate)).diff(moment(), 'days')
    if (daysDiff <= 30) {
      showAlert = true
    }
    // console.log('days different')
    // console.log(daysDiff)
    if (daysDiff < 0) {
      return (
        <Row justify="center" style={{ marginTop: '6em' }}>
          <Col sm={24} md={8} lg={6}>
          <Alert
            type="error"
            showIcon
            message="Thời hạn thuê bao đã hết, vui lòng liên hệ để gia hạn thuê bao!"
            />
          </Col>
        </Row>
      )
    }
  }
  // if (data && data.me) {
  //   setUser(data.me)
  // }
  return (
    <NameProvider>
      <Layout style={{ minHeight: '100vh'}}>
        {data && data.me &&  data.me.role ==='shopowner' && (
          <Sider
          collapsible
          trigger={null}
          collapsed={showSider}
          >
            <div style={{ minHeight: '3em'}} />
            <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
              <Menu.Item key="/" icon={<HomeOutlined />}><Link to="/">Trang chủ</Link></Menu.Item>
              <Menu.Item key="/shops" icon={<ShopOutlined />}>
                <Link to="/shops">
                  Cửa hàng
                </Link>
              </Menu.Item>
              {data && data.me &&  data.me.role ==='shopowner' && (
                <Menu.Item key="/agents" icon={<UserOutlined />}>
                    <Link to="/agents">
                      Nhân viên
                    </Link>
                  </Menu.Item>
              )}
              {data && data.me &&  data.me.role ==='shopowner' && (
                <Menu.Item key="/meds" icon={<ExperimentOutlined />}>
                  <Link to="/meds">
                    Sản phẩm
                  </Link>
                </Menu.Item>
                /* 
                <SubMenu key="/meds" icon={<AppstoreAddOutlined />} title="Med">
                  <Menu.Item key="/meds/categories" icon={<SnippetsOutlined />}>
                    <Link to="/meds/categories">
                      Danh mục
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/meds/products" icon={<ExperimentOutlined />}>
                    <Link to="/meds/products">
                      Sản phẩm
                    </Link>
                  </Menu.Item>
                </SubMenu>
                 */
              )}
              {data && data.me &&  data.me.role ==='shopowner' && (
                <Menu.Item key="/report" icon={<PieChartOutlined />}>
                  <Link to="/report">
                      Báo cáo
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </Sider>
        )}
        
        <Layout>
          <Layout.Header style={{ background: '#fff', padding: 0 }}>
            {data && data.me && (
              <Nav showToggle={data.me && data.me.role === 'shopowner'} sidebar={showSider} toggleSidebar={toggleSider} />
              )}
          </Layout.Header>
          <Content style={{ padding: '0 1em', background: '#fff'}}>
            {data.me && data.me.expDate && showAlert && (
              <Alert
              type="warning"
              closable
              message={`Thuê bao sẽ hết hạn trong vòng ${daysDiff + 1} ngày, vui lòng liên hệ để gia hạn thuê bao`}
              showIcon
              />
              )}
            <Switch>
              {/* <Route path="/pageone" exact component={PageOne} /> */}
              <Route path="/sale/:shop" component={Sale} />
              <Route path="/shops" component={ShopMgt} />
              <Route path="/agents" component={AgentMgt} />
              <Route path="/meds" component={Med} />
              <Route path="/profile" component={() => (<Profile me={data.me} />)} />
              <Route path="/settings" component={() => (<Settings />)} />
              {data && data.me && data.me.role === 'shopowner' ? 
                <Route path="/" component={Report} /> :
                <Redirect to="/shops" />
              }
              {/* <Route path="/" component={DefaultHome} /> */}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </NameProvider>
  );
}
export default Home;