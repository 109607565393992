import React from 'react'
import {
  Input,
  Form,
  Button
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import './basic.css'
import PasswordModal from './PasswordModal'

const BasicInfo = ({ me }) => {
  const [form] = Form.useForm()
  const [editableFields, toggleEditableFields] = React.useState({
    name: true,
    email: true
  })
  const [pwdModal, togglePwdModal] = React.useState(false)
  
  const toggleFields = (key, value) => {
    const newFields = {...editableFields}
    newFields[key] = value
    toggleEditableFields(newFields)
  }
  return (
    <div className="basic">
      <Form form={form} layout="vertical" initialValues={{...me}}>
        <Form.Item label="Tên" name="name">
          <Input readOnly={editableFields.name} suffix={<EditOutlined onClick={() => toggleFields('name', !editableFields.name)} />} />
        </Form.Item>
        <Form.Item label="Email/ SDT" name="email">
          <Input defaultValue={me.email} readOnly />
        </Form.Item>
        <p>{`Quyền Truy Cập: ${me.role === 'shopowner' ? 'Chủ cửa hàng' : 'Nhân viên'}`}</p>
        <p>
          <Button type="primary" onClick={() => togglePwdModal(true)}>Đổi mật khẩu</Button>
        </p>
      </Form>
      <PasswordModal
        visible={pwdModal}
        onCancel={() => togglePwdModal(false)}
        onOk={() => togglePwdModal(false)}
      />
    </div>
  )
} 
export default BasicInfo
