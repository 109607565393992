import React from 'react'
import {
  Modal,
  Drawer
} from 'antd'
import {
  gql,
  useMutation,
  useLazyQuery
} from '@apollo/client'
import InvPrdsList from './InvPrdsList'
import ModalFooter from './ModalFooter'
import moment from 'moment'

const LOAD_INV = gql`
  query check($shop:String!, $limit: Int, $page: Int, $filter: InvFilterInput) {
     oInvPrds(shop:$shop, limit: $limit, page: $page, filter: $filter) {
      total
      products {
        _id
        product {
          code
          name
          unit
          category {
            name
          }
        }
        oStock
        stock
        actual
        avgPrice
        sellPrice
        tPurchased
        tSold
        oSold
        isCount
      }
    }
}
` 
const APPLY_INV = gql`
  mutation apply($shop: String!, $inv: String!) {
    applyInv(shop: $shop, inv: $inv) {
      _id
      note
      created
      shop
      start
      end
      isActive
    }
  }
`
const UPDATE_SINGLE_ACT = gql`
  mutation updateSingle($shop: String!, $data: ActualType) {
    updateActual(shop: $shop, data: $data)
  }
`

const InvModal = ({
  shop,
  visible,
  onClose,
  closing,
  discardInv,
  inv
}) => {
  const limit = 50
  let changeTimeout = null
  const [page, changePage] = React.useState(1)
  const [filter, changeFilter] = React.useState({
    name: '',
    category: null
  })
  const [loadProducts, {loading: loadingInv, data: invData }] = useLazyQuery(LOAD_INV, { fetchPolicy: 'cache-and-network'})
  const [applyInv] = useMutation(APPLY_INV)
  const [updateActual] = useMutation(UPDATE_SINGLE_ACT)

  React.useEffect(() => {
    const load = async () => {
      await loadProducts({
        variables: {
          shop,
          limit,
          page: page - 1,
          filter: {
            // category: filter.category,
            name: filter.name,
            category: filter.category,
            start: moment(new Date(parseFloat(inv.start))).format('DD/MM/YYYY'),
            end: moment(new Date(parseFloat(inv.end))).format('DD/MM/YYYY')
          }
        }
      })
    }
    if (inv._id && inv.start && inv.end) {
      load()
    }
  }, [loadProducts,inv, filter.name, filter.category, limit, page, shop])

  const confirmExit = () => {
    Modal.confirm({
      title: 'Hủy bỏ kiểm kê?',
      content: 'Tất cả kết quả kiểm kê từ đầu sẽ bị xóa bỏ!',
      // onCancel: 
      onOk: () => discardInv(),
      okButtonProps: {
        danger: true
      }
    })
  }

  const confirmApp = () => {
    Modal.confirm({
      title: 'Áp dụng Kiểm kê',
      content: 'Số lượng kiểm kê sẽ được áp dụng và đóng kiểm kê. Không thể sửa được kết quả sau khi đóng. OK ?',
      onOk: () => apply(),
      okButtonProps: {
        type: 'primary'
      },
      okText: 'Áp dụng',
      cancelText: 'Hủy lệnh'
    })
  }

  const apply = async () => {
    // const inv = createInv._id
    if (!inv || !inv._id) return
    // const inv = newInv.createInv._id
    await applyInv({
      variables: {
        shop,
        inv: inv._id
      }
    })
      .then(() => {
        onClose()
      })
  }

const updateActualRec = async (data) => {
    await updateActual({
      variables: {
        shop,
        data
      },
      update: (cache, result) => {
        const oldData = cache.readQuery({
          query: LOAD_INV,
          variables: {
            shop,
            limit,
            page: page - 1,
            filter: {
              ...filter,
              start: moment(new Date(parseFloat(inv.start))).format('DD/MM/YYYY'),
              end: moment(new Date(parseFloat(inv.end))).format('DD/MM/YYYY')
            }
          }
        })
        cache.writeQuery({
          query: LOAD_INV,
          variables: {
            shop,
            limit,
            page: page - 1,
            filter: {
              ...filter,
              start: moment(new Date(parseFloat(inv.start))).format('DD/MM/YYYY'),
              end: moment(new Date(parseFloat(inv.end))).format('DD/MM/YYYY')
            }
          },
          data: {
            ...oldData,
            oInvPrds: {
              ...oldData.oInvPrds,
              products: oldData.oInvPrds.products.map(p => {
                if (p._id === result.data.updateActual) {
                  // console.log('found')
                  return {
                    ...p,
                    actual: data.actual,
                    isCount: true
                  }
                }
                return p
              })
            }
          }
        })
      }
    })
  }

  const updateFilter = (key, value) => {
    console.log(key, value)
    const newFilter = { ...filter }
    newFilter[key] = value
    if (changeTimeout) {
      clearTimeout(changeTimeout)
    }
    changeTimeout = setTimeout(() => {
      changeFilter(newFilter)
      changePage(1)
    }, 300)
  }

    return (
      <Drawer
        keyboard={false}
        closeIcon={() => null}
        title="Kiểm kê"
        visible={visible}
        width="100%"
        footer = {invData && (
          <ModalFooter
            changePage={changePage}
            currentpage={page}
            pageSize={limit}
            total={invData.oInvPrds.total}
            onClose={onClose}
            onDiscard={confirmExit}
            onApply={confirmApp}
          />
        )}
        // onCancel={confirmExit}
        cancelButtonProps={{
          loading: closing,
          danger: true
        }}
        okText="Kết thúc"
        cancelText="Hủy kiểm kê"
      >
        {invData && (
          <InvPrdsList
            loadingInv={loadingInv}
            updateActualRec={updateActualRec}
            filter={filter}
            updateFilter={updateFilter}
            inventory={inv._id}
            InvData={invData}
          />
        )}
      </Drawer>
    )
}
export default InvModal
