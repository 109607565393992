import XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function s2ab(s) { 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export function toXLSX(data, sheetName, filename) {
  const wb = XLSX.utils.book_new()
  wb.Props = {
    title: 'report',
    subject: 'Production'
  }
  wb.SheetNames.push(sheetName)
  const ws = XLSX.utils.json_to_sheet(data)
  wb.Sheets[sheetName] = ws
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary'})
  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), filename)
}

// module.exports = {
//   toXLSX,
//   s2ab,
//   numberWithCommas
// }