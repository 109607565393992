import React from 'react'
import {
  // DatePicker,
  PageHeader,
  Button
} from 'antd'
// import moment from 'moment'
import {
  gql,
  useMutation,
  useLazyQuery
} from '@apollo/client'
import InvModal from './InventoryModal'
import InventoriesList from './InventoriesList'
import InventoryResult from './InventoryResult'
import LoadingScreen from '../../../../utils/LoadingScreen'


const GET_ALL_INV = gql`
  query getAll($shop: String!, $page: Int, $limit: Int) {
    getInv(shop: $shop, page: $page, limit: $limit) {
      _id
      note
      created
      start
      end
      isActive
      tProducts
      tValue
    }
  }
`

const OPEN_INVENTORY = gql`
  mutation create($shop: String!, $note: String) {
    createInv(shop: $shop, note: $note) {
      _id
      note
      created
      shop
      start
      end
    }
  }
`
const REMOVE_INV = gql`
  mutation remove($shop: String!, $inv: String!) {
    removeInv(shop: $shop, inv: $inv)
  }
`


const AssetMgt = ({
  shop
}) => {
  const [page, setPage] = React.useState(1)
  const [currentInv, setCurrentInv] = React.useState(null)
  const limit = 20
  const [invModal, toggleInvModal] = React.useState(false)
  const [createInv, { loading: creatingInv, data: newInv = { createInv: null } }] = useMutation(OPEN_INVENTORY, { variables: { shop } })
  const [removeInv, { loading: removingInv }] = useMutation(REMOVE_INV)
  const [loadInvs, { loading: loadingInvs, data: invsData = { getInv: [] } }] = useLazyQuery(GET_ALL_INV, { fetchPolicy: 'cache-and-network'})
  React.useEffect(() => {
    const load = async () => {
      await loadInvs({
        variables: {
          shop,
          page: page - 1,
          limit
        }
      })
    }
    load()
  }, [loadInvs, page, limit, shop])

  const openInvModal = async () => {
    await createInv({
      update: (cache, result) => {
        const oldData = cache.readQuery({
          query: GET_ALL_INV,
          variables: {
            shop,
            page: page - 1,
            limit
          }
        })
        if (!oldData) return
        let found = false
        const { data: { createInv } } = result
        oldData.getInv.forEach(od => {
          if (createInv._id === od._id) found = true
        })
        if (!found) {
          cache.writeQuery({
            query: GET_ALL_INV,
            variables: {
              shop,
              page: page - 1,
              limit
            },
            data: {
              getInv: oldData.getInv.concat([createInv])
            }
          })
        }
        // console.log(oldData)
      }
    })
      .then(() => {
        toggleInvModal(true)
      })
  }
  const discardAndClose = async () => {
    if (newInv && newInv.createInv) {
      await removeInv({
        variables: {
          shop,
          inv: newInv.createInv._id
        },
        update: (cache, result) => {
          const oldData = cache.readQuery({
            query: GET_ALL_INV,
            variables: {
              shop,
              page: page - 1,
              limit
            }
          })
          // console.log(oldData)
          cache.writeQuery({
            query: GET_ALL_INV,
            variables: {
              shop,
              page: page - 1,
              limit
            },
            data: {
              getInv: oldData.getInv.filter(od => od._id.toString() !== result.data.removeInv.toString())
            }
          })
        }
      })
      toggleInvModal(false)
    }
  }
  
  const openResultModal = inv => setCurrentInv(inv)

  if (loadingInvs) {
    return (<LoadingScreen />)
  }
  return (
    <div>
      <PageHeader
        title={null}
        extra={[
          <Button loading={creatingInv} onClick={openInvModal} type="primary" key="new_Inv">Bắt đầu kiểm kê</Button>
        ]}
      />
      {!loadingInvs && invsData && (
        <InventoriesList
          shop={shop}
          openActiveInv={openInvModal}
          data={invsData}
          page={page}
          setPage={setPage}
          loading={loadingInvs}
          onClick={openResultModal}
        /> 
      )}
      {
        newInv.createInv && (
          <InvModal
            inv={newInv.createInv}
            shop={shop}
            visible={invModal}
            discardInv={discardAndClose}
            onClose={() => toggleInvModal(false)}
            closing={removingInv}
          />
        )
      }
      <InventoryResult
        visible={currentInv}
        inv={currentInv}
        shop={shop}
        onCancel={() => setCurrentInv(null)}
      />
    </div>
  )
}
export default AssetMgt