import React, { useState } from 'react';
import {
  Form,
  Input,
  // Select,
  Button,
  Typography,
  message,
} from 'antd';
import { gql, useMutation }  from '@apollo/client';
import { Link } from 'react-router-dom';

const REGISTER_MUTATION = gql`
  mutation register($email: String! $name: String !, $password: String!) {
    signup(email: $email, name: $name, password: $password) {
      _id
      name
      email
      profile {
        phones
        address {
          city
          dist
          detail
        }
      }
    }
  }
`;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
};

const { Title } = Typography;

const RegisterForm = () => {
  const [form] = Form.useForm();
  const [registerUser, { loading }] = useMutation(REGISTER_MUTATION);
  const [registerSuccess, changeToSuccess] = useState(false);
  
  const doRegister = async() => {
    try {
      await registerUser({
        variables: {
          ...form.getFieldsValue()
        }
      })
      .then(data => {
        changeToSuccess(true);
      })
    } catch (e) {
      // console.log(e.Error)
      message.error('Không thể đăng ký tài khoản/ Email SDT đã tồn tại')
    }
  }
  
  if(registerSuccess) {
    return (
      <div>
        <Title level={3}>Đăng ký tài khoản thành công</Title>
        <Link to="/login">
          <Button type="primary">Đăng nhập</Button>
        </Link>
      </div>
    );
  }
  return (
    <div>
      <Title level={3}>Đăng ký tài khoản</Title>
      <Form form={form} {...formLayout}>
        <Form.Item name="name" label="Tên người dùng">
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email/ Số Điện thoại">
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Mật khẩu">
          <Input.Password />
        </Form.Item>
        <Form.Item name="password_confirmation" label="Nhập lại mật khẩu">
          <Input.Password />
        </Form.Item>
        <Form.Item label={` `}>
          <Button onClick={() => doRegister()} loading={loading} type="primary">Đăng ký</Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default RegisterForm;