import React from 'react'
import {
  Form,
  Select,
  Button,
} from 'antd';

const { Option } = Select

const SelectCatDropdown = ({
  categories,
  updateSearch,
  name
}) => {
  const [form] = Form.useForm()
  const update = () => {
    const values = form.getFieldsValue()
    console.log(values)
    console.log(name)
    updateSearch(name, values[name])
  }
  return (
    <div style={{ padding: '1em'}}>
      <Form form={form} layout="vertical">
        <Form.Item name="category" label="Chọn một hoặc nhiều mục">
          <Select
            mode="multiple"
            allowClear
            >
            {categories.map(cat => (
              <Option value={cat._id}>{cat.name}</Option>
              ))}
          </Select>
        </Form.Item>
        <Button onClick={update}>OK</Button>
      </Form>
    </div>
  )
}
export default SelectCatDropdown
